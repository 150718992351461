import React, { useState, useEffect } from "react";
import { publicEncrypt, constants } from "crypto-browserify";
import { Buffer } from "buffer";
import Config from "../../Config/config";
import axios from "axios";
import NHA from "../../images/nha.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FacilityDetails from "../FacilityDetails";
import CreateHfrId from "../CreateHfrId";

const ABDM = ({ onButtonClick }) => {
  const [activeSection, setActiveSection] = useState("HPR");
  const [selectedOption, setSelectedOption] = useState("");
  const [HospiralId, setHospiralId] = useState("");
  const [showHfrId, setShowHfrId] = useState(false);
  const [variables, setVariables] = useState({
    hprId: "",
    hfrId: "",
  });

  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");

  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));
  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : JSON.parse(sessionStorage.getItem("token"));
  const hospitalId = sessionStorage.getItem("hospitalId") || "";
  const HOSPITAL_SETUP = sessionStorage.getItem("HOSPITAL_SETUP") || "";

  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");

  const handleNext = async () => {
    if (activeSection === "HPR") {
      setActiveSection("HFR");
      return;
    }
    const hospitalId = sessionStorage.getItem("hospitalId") || "";
    const UserID = sessionStorage.getItem("RegUserId") || "";
    const publicKey = sessionStorage.getItem("RegpublicKey") || "";
    const token = sessionStorage.getItem("Regtoken") || "";
    var padding = constants.RSA_PKCS1_PADDING;
    var data = Buffer.from(UserID.toString(), "utf-8");
    var encrypted = publicEncrypt(
      { key: publicKey, padding: padding },
      data
    ).toString("base64");
    try {
      const body = JSON.stringify({
        // hospitalId: hospitalId,
        // userId: UserID,
        // hprId: variables.hprId,
        // hfrId: variables.hfrId,
      });
      //console.log("dataforabdm", body);
      axios
        .post(Config.base_url + Config.routes.updateHFRID, body, {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        })
        .then((response) => {
          //console.log("res", response);
          if (response.status == 200) {
            toast.success("ABDM Updated Sucessfully");
          }
        })
        .catch((error) => {
          toast.error("Server Error!");
          console.error(error);
        });
    } catch (error) {
      console.error(error);
      return "Error";
    }
  };

  const toggleSection = (sectionName) => {
    setActiveSection(sectionName);
  };
  const toggleOptions = (option) => {
    setSelectedOption(option);
    // if(option ==='Yes'){
    //   setActiveSection("HPR");
    // }
  };
  const updateHPRId = () => {
    setActiveSection("HFR");
  };
  const handleInputChange = (event, field) => {
    setVariables((prevVariables) => ({
      ...prevVariables,
      [field]: event.target.value,
    }));
  };

  useEffect(() => {
    defaultHospital();
  }, []);
  const defaultHospital = async () => {
    const UserID = sessionStorage.getItem("RegUserId")
      ? JSON.parse(sessionStorage.getItem("RegUserId"))
      : sessionStorage.getItem("UserID");

    const authentication = "Basic " + btoa("mpoweruser:Cl!nA11y123!@#");
    var options = {
      method: "GET",
      url: `${Config.base_url}getDefaultHospital/${UserID}`,
      headers: {
        authorization: authentication,
        "Content-Type": "application/json",
        "x-caller": encrypted,
      },
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(
        //   "setHospitalList",
        //   response.data.recordset?.[0].HOSPITAL_ID
        // );
        setHospiralId(response.data.recordset?.[0].HOSPITAL_ID);
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  const updateHFRId = async () => {
    //console.log(hospitalId, " const hospitalId");
    const PATIENT_ID = sessionStorage.getItem("PATIENT_ID")
      ? JSON.parse(sessionStorage.getItem("PATIENT_ID"))
      : sessionStorage.getItem("PATIENT_ID");
    if (variables.hfrId !== "" && variables.hprId !== "") {
      try {
        const paylaod = {
          hfrId: variables.hfrId,
          hospitalId: HospiralId, //for now 0 when if doctor has hospoital id then value will be hispital id
          // userId: PATIENT_ID?PATIENT_ID:UserID,
          userId: UserID,
          hprId: variables.hprId,
        };

        console.log("paylaod0,", paylaod);
        const response = await axios.post(
          `${Config.base_url}v2/updateHFRId`,
          paylaod,

          {
            headers: {
              "content-type":"application/x-www-form-urlencoded; charset=UTF-8",
              authorization: `Bearer ${token}`,
              "x-caller": await encrypted,
            },
          }
        );

        if (response.status === 200) {
          //console.log("respoknnnolnlnmse", response);
          setVariables((prevVariables) => ({
            hfrId: "",
            hprId: "",
          }));
          toast.success("saved successfully");
        } else {
          toast.error(
            "Sorry !!! ",
            "We're facing a server issue. Please try after some time."
          );
        }
      } catch (error) {
        console.error(error);
        toast.error(
          "Sorry !!! We're facing a server issue. Please try after some time."
        );
      }
    } else {
      toast.error("Please Enter HPR and HFR ID'S");
    }
  };

  // const handleButtonClick = () => {
  //   setCurrentPage("createHFRId");
  // };

  const handleButtonClick = () => {
    setShowHfrId(true);
  };

  return (
    <>
      <div className="profileRightContainer">
        <div
          className="leftContainer"
          style={{
            gap: "1rem",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            className="bottomContainer"
            style={{ gap: "1rem", overflow: "scroll" }}
          >
            <div className="HprHir">
              <div
                className={`${activeSection === "HPR" ? "activeSection" : ""}`}
                onClick={() => toggleSection("HPR")}
                style={{
                  width: "50%",
                  textAlign: "center",
                  alignContent: "center",
                  border:
                    activeSection === "HPR" ? "rgba(93, 74, 135, 1)" : "none",
                  padding: "0.2rem 1.5rem",
                  cursor: "pointer",
                }}
              >
                <p style={{ cursor: "pointer" }}>HPR ID</p>
              </div>
              <div
                className={`${activeSection === "HFR" ? "activeSection" : ""}`}
                onClick={() => toggleSection("HFR")}
                style={{
                  width: "212px",
                  border:
                    activeSection === "HFR" ? "rgba(93, 74, 135, 1)" : "none",
                  padding: "0.3rem 3rem",
                  textAlign: "center",
                  alignContent: "center",
                  cursor: "pointer",
                }}
              >
                <p style={{ cursor: "pointer" }}>HFR ID</p>
              </div>
            </div>
            {activeSection === "HPR" && (
              <div className="hpr_inner">
                <div className="hpr_indiv">
                  <p className="health-service">
                    Health Professional Registry Service
                  </p>
                  <p className="Pargtext">
                    Do you have Ayushman Bharat Digital Mission Health
                    Professional Registry ID ?
                  </p>
                  {/* <input type="text" className="abdm-input-feild"/> */}
                  {selectedOption === "Yes" && (
                    <input
                      style={{
                        cursor: "pointer",
                        width: "100%",
                        background: "#eeecf5",
                        borderRadius: "9px",
                        height: "6vh",
                        padding: "0 1rem",
                        border: "none",
                      }}
                      className="personalInfoValue"
                      type="text"
                      placeholder="Enter HPR ID"
                      value={variables.hprId}
                      onChange={(e) => handleInputChange(e, "hprId")}
                    />
                  )}
                  <div className="yes-no-buttons-section">
                    <p
                      style={{
                        width: "97px",
                        height: "32px",
                        borderRadius: "25px",
                        border:
                          selectedOption === "Yes"
                            ? "1px solid #8873C9"
                            : " 1px solid #8873C9",
                        background:
                          selectedOption === "Yes" ? "#8873C9" : "#FFF",

                        color: selectedOption === "Yes" ? "white" : "#8873C9",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "500",
                        fontSize: "16px",
                      }}
                      onClick={() => toggleOptions("Yes")}
                    >
                      Yes
                    </p>
                    <p
                      style={{
                        width: "97px",
                        height: "32px",
                        borderRadius: "25px",
                        border:
                          selectedOption === "No"
                            ? "1px solid #8873C9"
                            : " 1px solid #8873C9",
                        background:
                          selectedOption === "No"
                            ? "rgba(136, 115, 201, 1)"
                            : "white",
                        color:
                          selectedOption === "No"
                            ? "white"
                            : "rgba(136, 115, 201, 1)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "500",
                        fontSize: "16px",
                      }}
                      onClick={() => toggleOptions("No")}
                    >
                      No
                    </p>
                  </div>
                  <div className="skip_div">
                    <p className="skip_p">Skip ABDM integration for now</p>
                    <div
                      className={"editButton2"}
                      style={{
                        width: "78%",
                        height: "40px",
                        textAlign: "left",
                      }}
                      onClick={updateHPRId}
                    >
                      <p style={{ color: "white" }}>Next</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {activeSection === "HFR" && (
              <div className="div_p_text">
                <div className="p_text_main">
                  <p className="health-service">
                    Health Facility Registry Service
                  </p>
                  <p className="p_text">
                    Do you have Ayushman Bharat Digital Mission Health Facility
                    Registry ID ?
                  </p>
                  <div className="p_textinner">
                    <p
                      style={{
                        width: "97px",
                        height: "32px",
                        borderRadius: "12px",
                        border:
                          selectedOption === "YesHfr"
                            ? "1px solid #8873C9"
                            : " 1px solid #8873C9",
                        background:
                          selectedOption === "YesHfr" ? "#8873C9" : "white",
                        color:
                          selectedOption === "YesHfr" ? "white" : "#8873C9",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "500",
                        fontSize: "16px",
                      }}
                      onClick={() => toggleOptions("YesHfr")}
                    >
                      Yes
                    </p>
                    <p
                      style={{
                        width: "97px",
                        height: "32px",
                        borderRadius: "12px",
                        border:
                          selectedOption === "NoHfr"
                            ? "1px solid #8873C9"
                            : " 1px solid #8873C9",
                        background:
                          selectedOption === "NoHfr"
                            ? "rgba(136, 115, 201, 1)"
                            : "white",
                        color:
                          selectedOption === "NoHfr"
                            ? "white"
                            : "rgba(136, 115, 201, 1)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "500",
                        fontSize: "16px",
                      }}
                      onClick={() => toggleOptions("NoHfr")}
                    >
                      No
                    </p>
                  </div>
                  {selectedOption === "YesHfr" && (
                    <>
                      <input
                        style={{
                          cursor: "pointer",
                          width: "100%",
                          background: "#eeecf5",
                          height: "6vh",
                          padding: "0 1rem",
                          border: "none",
                        }}
                        className="personalInfoValue"
                        type="text"
                        placeholder="Enter HFR ID"
                        value={variables.hfrId}
                        onChange={(e) => handleInputChange(e, "hfrId")}
                      />
                    </>
                  )}
                </div>
                <div className="skip_div">
                  <p className="skip_p">Skip ABDM integration for now</p>
                  <div
                    className={"editButton2"}
                    style={{ width: "28%", height: "40px", textAlign: "left" }}
                    onClick={updateHFRId}
                  >
                    <p style={{ color: "white" }}>Submit</p>
                  </div>
                </div>
              </div>
            )}
            <ToastContainer />
          </div>
        </div>
        {activeSection === "HPR" && selectedOption === "No" && (
          <div
            className="leftContainer"
            style={{ width: "50%", gap: "1rem", justifyContent: "center" }}
          >
            <div className="hfr_hpr_container">
              <div
                className="bottomContainer"
                style={{
                  gap: "1rem",
                  height: "100%",
                  overflow: "scroll",
                  justifyContent: "center",
                }}
              >
                <div style={{ gap: "3rem" }}>
                  <p style={{ fontWeight: "600", fontSize: "19px" }}>
                    Do you want to complete HPR registration ?
                  </p>
                  <p className="Benfits_abdm">
                    Benefits of Health Provider Registry
                  </p>
                  <ul className="ul_online">
                    <li>i. Online presence and discoverability.</li>
                    <li>
                      ii. Faster Registration, License Renewal, and NOC Issuance
                      with councils and other governing bodies.
                    </li>
                    <li>iii. Digital Health Records</li>
                    <li>iv. Telemedicine</li>
                  </ul>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p style={{ fontWeight: "600" }}>To create your HPR ID -</p>
                    <a className="a_clickhere">Click Here</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {activeSection === "HFR" && selectedOption === "YesHfr" && (
          <div className="leftContainer" style={{ width: "50%", gap: "1rem" }}>
            <div
              className="bottomContainer"
              style={{
                gap: "1rem",
                height: "100%",
                overflow: "scroll",
                justifyContent: "center",
              }}
            >
              <p>
                Please use below Bridge ID & URL to link Clinally mPower with
                your Health Facility:
              </p>
              <p className="bridge_ul">
                <ul>
                  <li>Bridge ID : CPHSPL_001</li>
                  <li>Callback URL : https://abhaservices.clinally.com</li>
                </ul>
              </p>
              <p className="p_stepts">
                Steps for Linkage of Software From the dashboard
              </p>
              <ul style={{ paddingLeft: "1.5rem", color: "gray" }}>
                <li>i. Login to HFR portal using HFR ID.</li>
                <li>
                  ii. Click on Link your Software from your respective facility.
                </li>
                <li>
                  iii. Enter the Bridge ID and call back URL provided to your
                </li>
              </ul>
            </div>
          </div>
        )}
        {activeSection === "HFR" && selectedOption === "NoHfr" && (
          <div
            className="leftContainer"
            style={{ width: "50%", gap: "1rem", justifyContent: "center" }}
          >
            <div
              className="bottomContainer"
              style={{
                gap: "1rem",
                height: "100%",
                overflow: "scroll",
                justifyContent: "center",
              }}
            >
              <div style={{ gap: "3rem" }}>
                <p className="hprRegistration">
                  Do you want to complete HPR registration ?
                </p>
                <p className="benofHeal">
                  Benefits of Health Facility Registry
                </p>
                <ul className="ul_Disc">
                  <li>i. Unique & Trustable Identity.</li>
                  <li>ii. Online Presence & Discoverability.</li>
                  <li>iii. Ease of doing business</li>
                  <li>iv. Unified Digital Services</li>
                </ul>

                <div className="div_clickme">
                  <p style={{ fontWeight: "600" }}>To create your HFR ID -</p>
                  <button className="clickmeButtton" onClick={onButtonClick}>
                    Click Here
                  </button>
                  {/* {showHfrId && <CreateHfrId />} */}
                  {/*{currentPage === "createHFRId" && <FacilityDetails />}*/}
                </div>
              </div>
            </div>
          </div>
        )}
        <ToastContainer />
      </div>
    </>
  );
};

export default ABDM;
