import { ca } from "date-fns/locale";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BmiCalculator = () => {
  const [calculateAligment, setCalculateAligment] = useState(false);
  const [BMI, setBMI] = useState({
    unit: "cm",
    height: "",
    weight: "",
    ft: "",
    inch: "",
    bmi: "",
    result: "",
  });

  const validateBmiCalci = () => {
    const { unit, height, weight, ft, inch } = BMI;
    if(unit === "cm" && ((height <= 0 || height > 350) || (weight <= 0 || weight > 250))) {
      return false;
    }
    if(unit === "ft" && ((ft <= 0 || ft > 10) || (inch <= 0 || inch > 11) || (weight <= 0 || weight > 250))) {
      return false;
    }
    return true;
  }

  const bmicalci = () => {
    setCalculateAligment(true);
    const { unit, height, weight, ft, inch } = BMI;
    if(!validateBmiCalci()) {
      setBMI({
        ...BMI,
        bmi: "",
        result: "",
        idealWeightLow: undefined,
        idealWeightHigh:undefined
      });
      return;
    }
    if (unit === "cm" && height && weight) {
      const heightInMeters = height / 100;
      const result = weight / (heightInMeters * heightInMeters);
      setBMI({
        ...BMI,
        bmi: result.toFixed(2),
        result: result,
        idealWeightLow: (18.5 * heightInMeters * heightInMeters).toFixed(2),
        idealWeightHigh: (24.9 * heightInMeters * heightInMeters).toFixed(2),
      });
    }
    // Updated calculation for 'ft' unit
    else if (unit === "ft" && ft && inch && weight) {
      const totalHeightInInches = parseInt(ft) * 12 + parseInt(inch);
      const heightInMeters = totalHeightInInches * 0.0254;
      const result = weight / (heightInMeters * heightInMeters);
      setBMI({
        ...BMI,
        bmi: result.toFixed(2),
        // result: getBmiResultCategory(result).category,
        result: result,
        idealWeightLow: (18.5 * heightInMeters * heightInMeters).toFixed(2),
        idealWeightHigh: (24.9 * heightInMeters * heightInMeters).toFixed(2),
      });
    } else {
      toast.error("Please enter all the fields");
    }
  };

  const getBmiResultCategory = (result) => {
    let category = "";
    let color = "";
    let fontColor = "#000";
    if (result < 18.6) {
      category = "Underweight";
      color = "#fdfa72";
    } else if (result >= 18.6 && result < 25) {
      category = "Healthy";
      color = "green";
      fontColor = "#FFF";
    } else if (result >= 25 && result < 30) {
      category = "Overweight";
      color = "orange";
    } else if (result >= 30) {
      category = "Obese";
      color = "red";
      fontColor = "#FFF";
    }
    return { category, color, fontColor };
  };

  return (
    <>
      <div className="calcRightContainer">
        <div className="HeadingBmiCalculator">
          <p className="BmiCalculatorHeading">BMI Calculator</p>
        </div>
        <div className="output-section">
        <div style={{ padding: "3%",  border: '2px solid #E3EDFF', borderRadius:"10px"}}>
          <div className="group-radiobuttons">
            <label className={`group-radiobuttons-label ${BMI.unit === "cm" ? "activeSection":""}`}>
              <input
                className="group-radiobuttons-input"
                type="radio"
                name="calculation"
                value={BMI.cm}
                checked={BMI.unit === "cm"}
                onChange={() => setBMI({ ...BMI, unit: "cm" })}
              />
              CM
            </label>
            <label className={`group-radiobuttons-label ${BMI.unit === "ft" ? "activeSection":""}`}>
              <input
                className="group-radiobuttons-input"
                type="radio"
                name="calculation"
                value={BMI.ft}
                checked={BMI.unit === "ft"}
                onChange={() => setBMI({ ...BMI, unit: "ft" })}
              />
              FT
            </label>
          </div>
          <div className="hwrow">
            {BMI.unit === "cm" && (
              <div className="buttonstyles">
                <p className="CalculatorLabel">Height*</p>
                <input
                  type="text" // Change to text to allow both numbers and dots
                  name="height"
                  maxLength="4" // Adjust length to allow for decimal point (e.g., "350.")
                  required
                  value={BMI.height}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.match(/^\d{0,3}(\.\d{0,1})?$/) && (value === '' || (Number(value) >= 0 && Number(value) <= 350))) {
                      setBMI({ ...BMI, height: value });
                    }
                  }}
                    />

                {(BMI.height < 0 || BMI.height > 350) && (
                  <p style={{ color: "red" }}>Range 0-350</p>
                )}
              </div>
            )}
            {BMI.unit === "ft" && (
              <div className="buttonstyles">
                <p className="CalculatorLabel">Feet*</p>
                <input
                  type="number"
                  name="ft"
                  value={BMI.ft}
                  onChange={(e) => setBMI({ ...BMI, ft: e.target.value })}
                />
                {(BMI.ft < 0 || BMI.ft > 10) && (
                  <p style={{ color: "red" }}>Range 0-10</p>
                )}
                <p className="CalculatorLabel">Inches*</p>
                <input
                  type="number"
                  name="inch"
                  value={BMI.inch}
                  onChange={(e) => setBMI({ ...BMI, inch: e.target.value })}
                />
                {(BMI.inch < 0 || BMI.inch > 11) && (
                  <p style={{ color: "red" }}>Range 0-11</p>
                )}
              </div>
            )}

            <div className="buttonstyles">
              <p className="CalculatorLabel">Weight*</p>
              <input
                type="number"
                name="weight"
                maxLength="3"
                required
                value={BMI.weight}
                onChange={(e) => {
                  const value = e.target.value;
                  if ((value.match(/^\d{0,3}(\.\d{0,1})?$/)) && (value === '' || (Number(value) >= 0 && Number(value) <= 250))) {
                    setBMI({ ...BMI, weight: value });
                  }
                }}
              />
              {(BMI.weight < 0 || BMI.weight > 250) && (
                <p style={{ color: "red" }}>Range 0-250</p>
              )}
              
            </div>
          </div>
          <button
              className="diabetesCalculateButtonCalculate"
              onClick={bmicalci}
            >
              CALCULATE
            </button>
         
        </div>
        <div style={{padding: "3%", width:"50%", border: '2px solid #E3EDFF', borderRadius:"10px"}}>
        <div
            className="calculateButton"
            style={{
              display: "flex",
              justifyContent:  "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
           
            <div style={{
              borderRadius: "10px",
              display: "flex",
              //backgroundColor: "#e1d7ff",
              padding: "1%",

            }} >
               
              {BMI.bmi && (
                <div className="resultButton">
                  <h1 style={{fontSize:'22px'}}>BMI:</h1>
                  <p className="CalculatorLabel">{BMI.bmi} (Kg/m²)</p>
                </div>
              )}
              
            </div>
          </div>
          <div className="idealweight">
            <p className="CalculatorLabel">
            {BMI.result !== "" && (
                // <div className="conditionresultcontainer">
                <button
                  style={{
                    backgroundColor: getBmiResultCategory(
                      parseFloat(BMI.result)
                    ).color,
                    // marginLeft: "620px",
                    width: "95px",
                    border: "none",
                    borderRadius: "10px",
                    height: "25px",
                    marginRight: "10%",
                  
                    color: getBmiResultCategory(
                      parseFloat(BMI.result)
                    ).fontColor
                  }}
                >
                  {getBmiResultCategory(parseFloat(BMI.result)).category}
                </button>
                // </div>
              )}
              
            </p>
            <p>Your ideal weight is between </p>
            <p>{BMI.idealWeightLow} and {BMI.idealWeightHigh} kgs.</p>
          </div>
        </div>
        </div>
        <ToastContainer position="top-right" />
      </div>
    </>
  );
};

export default BmiCalculator;
