import React, { useState, useEffect } from "react";
import Navbar from "../components/modules/Navbar";
import TestProfile from "../images/testVisitsProfile.svg";
import logOutLogo from "../images/logOutLogo.svg";
import VectorLine from "../images/Vector_line.svg";
import "./Profile.css";
import PersonalInfo from "../components/modules/UpdatePersonalInfo";
import certificate from "../images/certificate 1.svg";
import medicalHistoryDoctor from "../images/medical-history-doctor 2.svg";
import education from "../images/education-graduation-learning-school-study 1.svg";
import experience from "../images/education-school-study-sticker-6 1.svg";
import aboutUs from "../images/writing 1.svg";
import help from "../images/Group 61.svg";
import Hospitals from "../components/modules/UpdateHospitals";
import { useLocation, useNavigate } from "react-router-dom";
import RegProfile from "../components/Registration/RegProfile";
import RegHospitals from "../components/Registration/RegHospitals";
import RegEducation from "../components/Registration/RegEducation";
import RegExperience from "../components/Registration/RegExperience";
import RegABDM from "../components/Registration/RegABDM";
import medical from "../images/medical.svg";
import RegMedical from "../components/Registration/RegMedical";
import idProof from "../images/idProof.svg";
import RegIdentityProof from "../components/Registration/RegIdentityProof";
import ABDM from "../components/modules/ABDM";
import UpdateEducation from "../components/modules/UpdateEducation";
import UpdateExperience from "../components/modules/UpdateExperience";
import AboutUs from "../components/modules/AboutUs";
import { Link } from "react-router-dom";
import HelpPage from "../components/modules/HelpPage";
import RegProfileCompletionStatus from "../components/Registration/RegProfileCompletionStatus";
import MISReports from "../components/modules/MISReports";
import CreateHfrId from "../components/CreateHfrId";
const Profile = () => {
  const [activeSection, setActiveSection] = useState("Personal");
  const [activeSectionTab, setActiveSectionTab] = useState("Personal");
  const [profileHeading, setProfileHeading] = useState("PROFILE");
  const [showCreateHfrId, setShowCreateHfrId] = useState(false);

  const toggleSection = (sectionName) => {
    setActiveSection(sectionName);
    if (sectionName === "Personal") {
      setProfileHeading("Profile");
    } else if (sectionName === "Hospital") {
      setProfileHeading("Hospital Details");
    } else if (sectionName === "Education") {
      setProfileHeading("Education Details");
    } else if (sectionName === "ABDM") {
      setProfileHeading("AYUSHUMAN BHARAT DIGITAL MISSION");
    } else if (sectionName === "MIS Report") {
      setProfileHeading("MIS Report");
    } else {
      setProfileHeading(sectionName);
    }
  };
  const toggleSectionTab = (sectionName) => {
    setActiveSectionTab(sectionName);
    setActiveSection(sectionName);
  };
  const [ProfileStatusCompletionCount, setProfileStatusCompletionCount] =
    useState({
      ProfileDetailsCount: 0,
      AbmdDetailsCount: 0,
      HospitalsDetailsCount: 0,
      EducationDetailsCount: 0,
      ExperienceDetailsCount: 0,
    });

  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // const action = queryParams.get("action");
  const action = sessionStorage.getItem("action");
  useEffect(() => {
    if (action === "hrfid")
      //console.log(action)
      setActiveSection("ABDM");
  }, []);
  const StatusCompletionCount = (count, fieldName) => {
    // console.log(
    //   count,
    //   fieldName,
    //   "..........sdfs.fdsdfd............sfdsaffd..........safdsafs"
    // );
    setProfileStatusCompletionCount((prevFormData) => ({
      ...prevFormData,
      [fieldName]: count,
    }));
  };
  const renderPersonalSection = () => {
    if (action === "register") {
      return (
        <RegProfile
          toggleSectionTab={toggleSectionTab}
          ProfileDetailsCount={StatusCompletionCount}
        />
      );
    } else {
      return <PersonalInfo ProfileDetailsCount={StatusCompletionCount} />;
    }
  };

  const renderABDMSection = () => {
    // if (showCreateHfrId) {
    //   return <CreateHfrId />;
    // }
    if (action === "register") {
      return <RegABDM toggleSectionTab={toggleSectionTab} />;
    } else {
      return <ABDM onButtonClick={handleButtonClick} />;
    }
  };

    // const renderABDMSection = () => {
  //   if (action === "register") {
  //     return <RegABDM toggleSectionTab={toggleSectionTab} />;
  //   } else {
  //     return showCreateHfrId ? <CreateHfrId /> : <ABDM onButtonClick={handleButtonClick} />;
  //   }
  // };

  const renderHospitalSection = () => {
    if (action === "register") {
      return (
        <RegHospitals
          toggleSectionTab={toggleSectionTab}
          ProfileDetailsCount={StatusCompletionCount}
        />
      );
    } else {
      return <Hospitals toggleSection={toggleSection} />;
    }
  };

  const renderEducationSection = () => {
    if (action === "register") {
      return (
        <RegEducation
          setActiveSection={() => setActiveSection("Medical")}
          toggleSectionTab={toggleSectionTab}
          EducationDetailsCount={StatusCompletionCount}
        />
      );
    } else {
      return <UpdateEducation />;
    }
  };

  const renderExperienceSection = () => {
    if (action === "register") {
      return (
        <RegExperience
          toggleSectionTab={toggleSectionTab}
          ExperienceDetailsCount={StatusCompletionCount}
        />
      );
    } else {
      return <UpdateExperience />;
    }
  };

  const renderMedicalSection = () => {
    if (action === "register") {
      return <RegMedical toggleSectionTab={toggleSectionTab} />;
    }
  };

  const renderMISReportSection = () => {
    if (action !== "register") {
      return <MISReports toggleSectionTab={toggleSectionTab} />;
    }
  };

  const renderIdentityProofSection = () => {
    if (action === "register") {
      return <RegIdentityProof toggleSectionTab={toggleSectionTab} />;
    }
  };

  const handleButtonClick = () => {
    setShowCreateHfrId(true); 
  };

  const handleLogout = () => {
    sessionStorage.removeItem("profileData");
    navigate("/");
  };

  const disableFields = action === "register";
  return (
    <>
      {/* <Navbar /> */}
      <div className="profile-page-container">
        <div className="navbar-container">
          <h1 className="profile-heading">{profileHeading}</h1>
        </div>
        {/* <Navbar disableFields={disableFields} /> */}
        <div className="profileSection">
          {/* {action !== "register" && ( */}
          <div className="profileLeftContainer">
            <div className="bg-profileContainer">
              {action === "register" ? (
                <>
                  {/* {activeSectionTab === "Personal" && ( */}
                  <div
                    className={`sectionsName ${
                      activeSection === "Personal" ? "activeSection" : ""
                    }`}
                    onClick={() => toggleSection("Personal")}
                    style={{
                      borderLeft:
                        activeSection === "Personal"
                          ? "6px solid #52447D"
                          : "none",
                      background:
                        activeSection === "Personal" ? "#DCDCDC4D" : "none",
                    }}
                  >
                    <img src={TestProfile} alt="" />
                    <p>Personal Overview</p>
                  </div>
                  {/* )} */}
                  {/* {activeSectionTab === "Hospital" && ( */}
                  <div
                    className={`sectionsName ${
                      activeSection === "Hospital" ? "activeSection" : ""
                    }`}
                    onClick={() => toggleSection("Hospital")}
                    style={{
                      borderLeft:
                        activeSection === "Hospital"
                          ? "6px solid #52447D"
                          : "none",
                      background:
                        activeSection === "Hospital" ? "#DCDCDC4D" : "none",
                    }}
                  >
                    <img src={medicalHistoryDoctor} alt="" />
                    <p>Hospital Details</p>
                  </div>
                  {/* )} */}

                  {/* {activeSectionTab === "ABDM" && ( */}
                  <div
                    className={`sectionsName ${
                      activeSection === "ABDM" ? "activeSection" : ""
                    }`}
                    onClick={() => toggleSection("ABDM")}
                    style={{
                      borderLeft:
                        activeSection === "ABDM" ? "6px solid #52447D" : "none",
                      background:
                        activeSection === "ABDM" ? "#DCDCDC4D" : "none",
                    }}
                  >
                    <img src={certificate} alt="" />
                    <p>ABDM</p>
                  </div>
                  {/* )} */}
                  {/* {activeSectionTab === "Education" && ( */}
                  <div
                    className={`sectionsName ${
                      activeSection === "Education" ? "activeSection" : ""
                    }`}
                    onClick={() => toggleSection("Education")}
                    style={{
                      borderLeft:
                        activeSection === "Education"
                          ? "6px solid #52447D"
                          : "none",
                      background:
                        activeSection === "Education" ? "#DCDCDC4D" : "none",
                    }}
                  >
                    <img src={education} alt="" />
                    <p>Education Details</p>
                  </div>
                  {/* )} */}

                  {/* {activeSectionTab === "Medical" && ( */}
                  <div
                    className={`sectionsName ${
                      activeSection === "Medical" ? "activeSection" : ""
                    }`}
                    onClick={() => toggleSection("Medical")}
                    style={{
                      borderLeft:
                        activeSection === "Medical"
                          ? "6px solid #52447D"
                          : "none",
                      background:
                        activeSection === "Medical" ? "#DCDCDC4D" : "none",
                    }}
                  >
                    <img src={medical} alt="" />
                    <p>Medical Registration</p>
                  </div>
                  {/* )} */}
                  {/* {activeSectionTab === "Experience" && ( */}
                  <div
                    className={`sectionsName ${
                      activeSection === "Experience" ? "activeSection" : ""
                    }`}
                    onClick={() => toggleSection("Experience")}
                    style={{
                      borderLeft:
                        activeSection === "Experience"
                          ? "6px solid #52447D"
                          : "none",
                      background:
                        activeSection === "Experience" ? "#DCDCDC4D" : "none",
                    }}
                  >
                    <img src={experience} alt="" />
                    <p>Experience Details</p>
                  </div>
                  {/* )} */}
                  {/* {activeSectionTab === "Identity" && ( */}
                  <div
                    className={`sectionsName ${
                      activeSection === "Identity" ? "activeSection" : ""
                    }`}
                    onClick={() => toggleSection("Identity")}
                    style={{
                      borderLeft:
                        activeSection === "Identity"
                          ? "6px solid #52447D"
                          : "none",
                      background:
                        activeSection === "Identity" ? "#DCDCDC4D" : "none",
                    }}
                  >
                    <img src={idProof} alt="" />
                    <p>Profile Verification</p>
                  </div>
                  {/* )} */}
                </>
              ) : (
                <>
                  <div
                    className={`sectionsName ${
                      activeSection === "Personal" ? "activeSection" : ""
                    }`}
                    onClick={() => toggleSection("Personal")}
                    style={{
                      borderLeft:
                        activeSection === "Personal"
                          ? "6px solid #52447D"
                          : "none",
                      background:
                        activeSection === "Personal" ? "#DCDCDC4D" : "none",
                    }}
                  >
                    {/* <img src={TestProfile} alt="" /> */}
                    <p>Profile Overview</p>
                  </div>
                  <div
                    className={`sectionsName ${
                      activeSection === "Hospital" ? "activeSection" : ""
                    }`}
                    onClick={() => toggleSection("Hospital")}
                    style={{
                      borderLeft:
                        activeSection === "Hospital"
                          ? "6px solid #52447D"
                          : "none",
                      background:
                        activeSection === "Hospital" ? "#DCDCDC4D" : "none",
                    }}
                  >
                    {/* <img src={medicalHistoryDoctor} alt="" /> */}
                    <p>Hospital Details</p>
                  </div>
                  <div
                    className={`sectionsName ${
                      activeSection === "ABDM" ? "activeSection" : ""
                    }`}
                    onClick={() => toggleSection("ABDM")}
                    style={{
                      borderLeft:
                        activeSection === "ABDM" ? "6px solid #52447D" : "none",
                      background:
                        activeSection === "ABDM" ? "#DCDCDC4D" : "none",
                    }}
                  >
                    {/* <img src={certificate} alt="" /> */}
                    <p>ABDM</p>
                  </div>
                  <div
                    className={`sectionsName ${
                      activeSection === "Education" ? "activeSection" : ""
                    }`}
                    onClick={() => toggleSection("Education")}
                    style={{
                      borderLeft:
                        activeSection === "Education"
                          ? "6px solid #52447D"
                          : "none",
                      background:
                        activeSection === "Education" ? "#DCDCDC4D" : "none",
                    }}
                  >
                    {/* <img src={education} alt="" /> */}
                    <p>Education Details</p>
                  </div>
                  <div
                    className={`sectionsName ${
                      activeSection === "Experience" ? "activeSection" : ""
                    }`}
                    onClick={() => toggleSection("Experience")}
                    style={{
                      borderLeft:
                        activeSection === "Experience"
                          ? "6px solid #52447D"
                          : "none",
                      background:
                        activeSection === "Experience" ? "#DCDCDC4D" : "none",
                    }}
                  >
                    {/* <img src={experience} alt="" /> */}
                    <p>Experience Details</p>
                  </div>
                  <div
                    className={`sectionsName ${
                      activeSection === "MIS Report" ? "activeSection" : ""
                    }`}
                    onClick={() => toggleSection("MIS Report")}
                    style={{
                      borderLeft:
                        activeSection === "MIS Report"
                          ? "6px solid #52447D"
                          : "none",
                      background:
                        activeSection === "MIS Report" ? "#DCDCDC4D" : "none",
                    }}
                  >
                    {/* <img src={education} alt="" /> */}
                    <p>MIS Report</p>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="all-main-sections">
            {activeSection === "Personal" && renderPersonalSection()}
            {activeSection === "Education" && renderEducationSection()}
            {activeSection === "Hospital" && renderHospitalSection()}
            {activeSection === "Experience" && renderExperienceSection()}
            {activeSection === "ABDM" && renderABDMSection()}
            {activeSection === "Medical" && renderMedicalSection()}
            {activeSection === "MIS Report" && renderMISReportSection()}
            {activeSection === "Identity" && renderIdentityProofSection()}
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
