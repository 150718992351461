import React, { useEffect, useState } from "react";
import { publicEncrypt, constants } from "crypto-browserify";
import { Buffer } from "buffer";
import search from "../../images/search.svg";
import "../modules/HospitalDetails.css";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import backArrow from "../../images/Group 417.svg";
import Axios from "axios";
import Config from "../../Config/config";
// import { Modal, Box, Paper, Typography, Button } from "@mui/material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

const Hospitals = ({ toggleSectionTab ,ProfileDetailsCount}) => {
  const [hospitalList, setHospitalList] = useState([]);
  const [variables, setVariables] = useState({
    selectedOption: "",
    addHospital: false,
    visits: false, //visit page
    searchQuery: "",
    isModalOpen: false,
    addHospital: false,
    selectedState: "",
    hospitalName: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    city: "",
    pinCode: "",
    userProfileDetails: "",
    selectedHospital: null,
    message: "",
    rented: false
  });

  const [selected, setSelected] = useState([]);

  const [file, setFile] = useState(null); //to dispaly the image selected
  const [selectedfile, setSelectedFile] = useState(null);

  const UserID = sessionStorage.getItem("RegUserId");
  const publicKey = sessionStorage.getItem("RegpublicKey");
  const token = sessionStorage.getItem("Regtoken");
  var padding = constants.RSA_PKCS1_PADDING;
  var data = Buffer.from(UserID.toString(), "utf-8");
  var encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");
  const RegMobileNum = sessionStorage.getItem("RegMobileNum") || "";

  useEffect(() => {
    fetchHospitalData(0);
  }, []);

  async function fetchHospitalData(index) {
    Axios.get(`${Config.apiURL}${Config.routes.getHospitalsAPI}/${UserID}`, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
        "x-caller": encrypted,
      },
    })

      .then((response) => {
        //console.log("response", response.data.data);
        setHospitalList(response.data.data);
        setSelected(response.data.data[index]);
        console.log("setSelected(response.data.data[index]);",response.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  const filteredData = hospitalList.filter((user) => {
    const fullName = `${user.HOSPITAL_NAME}`.toLowerCase();
    const searchQueryLower = variables.searchQuery.toLowerCase();
    return fullName.includes(searchQueryLower);
  });

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const states = [
    { value: "Andhra Pradesh", label: "Andhra Pradesh" },
    { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
    { value: "Assam", label: "Assam" },
    { value: "Bihar", label: "Bihar" },
    { value: "Chhattisgarh", label: "Chhattisgarh" },
    { value: "Goa", label: "Goa" },
    { value: "Gujarat", label: "Gujarat" },
    { value: "Haryana", label: "Haryana" },
    { value: "Himachal Pradesh", label: "Himachal Pradesh" },
    { value: "Jharkhand", label: "Jharkhand" },
    { value: "Karnataka", label: "Karnataka" },
    { value: "Kerala", label: "Kerala" },
    { value: "Madhya Pradesh", label: "Madhya Pradesh" },
    { value: "Maharashtra", label: "Maharashtra" },
    { value: "Manipur", label: "Manipur" },
    { value: "Meghalaya", label: "Meghalaya" },
    { value: "Mizoram", label: "Mizoram" },
    { value: "Nagaland", label: "Nagaland" },
    { value: "Odisha", label: "Odisha" },
    { value: "Punjab", label: "Punjab" },
    { value: "Rajasthan", label: "Rajasthan" },
    { value: "Sikkim", label: "Sikkim" },
    { value: "Tamil Nadu", label: "Tamil Nadu" },
    { value: "Telangana", label: "Telangana" },
    { value: "Tripura", label: "Tripura" },
    { value: "Uttar Pradesh", label: "Uttar Pradesh" },
    { value: "Uttarakhand", label: "Uttarakhand" },
    { value: "West Bengal", label: "West Bengal" },
    {
      value: "Andaman and Nicobar Islands (UT)",
      label: "Andaman and Nicobar Islands (UT)",
    },
    { value: "Chandigarh (UT)", label: "Chandigarh (UT)" },
    {
      value: "Dadra & Nagar Haveli and Daman & Diu (UT)",
      label: "Dadra & Nagar Haveli and Daman & Diu (UT)",
    },
    { value: "Delhi (UT)", label: "Delhi (UT)" },
    { value: "Jammu and Kashmir (UT)", label: "Jammu and Kashmir (UT)" },
    { value: "Lakshadweep (UT)", label: "Lakshadweep (UT)" },
    { value: "Puducherry (UT)", label: "Puducherry (UT)" },
    { value: "Ladakh (UT)", label: "Ladakh (UT)" },
  ];

  const handleStateChange = (selectedOption) => {
    setVariables((prevVariables) => ({
      ...prevVariables,
      selectedState: selectedOption,
      isOpen: false,
    }));
  };

  const handleSearch = debounce((value) => {
    setVariables({
      ...variables,
      searchQuery: value,
    });
  }, 300);

  const handleInputChange = (e, fieldName) => {
    const inputValue = e.target.value;

    if (fieldName === "pinCode") {
        if (/^\d{0,6}$/.test(inputValue)) {
            setVariables({ ...variables, [fieldName]: inputValue });
        }
    } else if (fieldName === "hospitalName" || fieldName === "city"){
        if (/^[a-zA-Z\s]*$/.test(inputValue)) {
            setVariables({ ...variables, [fieldName]: inputValue });
        }
    } else {
          setVariables({ ...variables, [fieldName]: inputValue });
    }
};


  const onValueChange = (event) => {
    //console.log("cmg");
    setVariables((prevVariables) => ({
      ...prevVariables,
      selectedOption: event.target.value,
    }));
  };

  const handleAssociateHospitals = () => {
  
    toggleSectionTab("ABDM");
  };

  const handleNext = () => {
    if (variables.selectedOption === "visit") {
      setVariables((prevVariables) => ({
        ...prevVariables,
        visits: true,
        addHospital: false,
        rented: false
      }));
    } else if (variables.selectedOption === "own") {
      setVariables((prevVariables) => ({
        ...prevVariables, 
        visits: false,
        addHospital: true,
        rented:false
      }));
    } else if (variables.selectedOption === "rented") {
      setVariables((prevVariables) => ({
        ...prevVariables,
        rented: true,
        visits: false,
        addHospital: false,
      }));
    }
  };

  const setUpHospital = () => {
    CheckStatusCount();
    if (!variables.hospitalName || !variables.addressLine1 || !variables.city) {
      toast.error("Please enter Hospital Name, Address, and City");
      return;
    }

    var data = {
      name: variables.hospitalName,
      address1: variables.addressLine1,
      address2: variables.addressLine2,
      address3: variables.addressLine3,
      city: variables.city,
      state: variables.selectedState,
      pin: variables.pinCode,
      mode: "add",
      userid: UserID,
      mobile: RegMobileNum,
    };

    var options = {
      method: "POST",
      url: Config.apiURL + Config.routes.addHospitalAPI,
      headers: {
        authorization: `Bearer ${token}`,
        "content-type": "application/json",
        "x-caller": encrypted,
      },
      data: { data: JSON.stringify(data) },
    };

    //console.log("data", options.data);

    Axios.request(options)
      .then(function (response) {
        //console.log(response.data.message);
        if ((response.data.message = "Data is submitted.")) {
          toast.success("Hospital Added Sucessfully");
          toggleSectionTab("ABDM");
          setVariables((prevVariables) => ({
            ...prevVariables,
            addressLine1: "",
            addressLine2: "",
            addressLine3: "",
            city: "",
            selectedState: "",
            pinCode: "",
          }));
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const handleBack = () => {
    setVariables((prevVariables) => ({
      ...prevVariables,
      selectedOption: "",
      addHospital: false,
      visits: false,
      rented: false
    }));
  };

  const showAlertDialog = (msg, user) => {
    setVariables((prevVariables) => ({
      ...prevVariables,
      isModalOpen: true,
      message: msg,
      selectedHospital: user,
    }));
  };

  const handleClose = () => {
    setVariables((prevVariables) => ({
      ...prevVariables,
      isModalOpen: false,
    }));
  };

  const handleAction = () => {
    if (variables.message === "Associate") {
      addHospitalToUser();
      fetchHospitalData();
    } else {
      removeHospitalFromUser();
      fetchHospitalData();
    }
    setVariables((prevVariables) => ({
      ...prevVariables,
      isModalOpen: false,
    }));
  };

  const getAlertTitle = () => {
    if (variables.message === "Associate") {
      return "Associate with Hospital";
    } else if (variables.message === "Cancel") {
      return "Cancel Request";
    }
  };

  const getAlertContent = () => {
    if (variables.message === "Associate") {
      return "You are trying to associate with an existing hospital. Approval is required for this activity. Notification will be sent to admin for approval. Are you sure you want to continue?";
    } else if (variables.message === "Cancel") {
      return "Your request is waiting for approval by Hospital Admin. Are you sure you want to cancel the request?";
    }
  };

  async function addHospitalToUser() {
    try {
      const body = JSON.stringify({
        hospitalID: variables.selectedHospital.HOSPITAL_ID,
        patientID: UserID,
        userID: UserID,
        status: "ACCEPTED",
        mobile: RegMobileNum,
        mode: "add",
      });
      //console.log("body", body);
      Axios.post(
        `${Config.apiURL}${Config.routes.addHospitalToUserAPI}`,
        body,
        {
          headers: {
            "content-type": "application/json",
            authorization: `Bearer ${token}`,
            "x-caller": await encrypted,
          },
        }
      )
        .then((response) => {
          if (response.status === 201) {
            //console.log("respomse", response);
            return "Success";
          } else {
            return "Not Saved.";
          }
        })

        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
      return "Error";
    }
  }

  async function removeHospitalFromUser() {
    try {
      const body = JSON.stringify({
        hospitalID: variables.selectedHospital.HOSPITAL_ID,
        userID: UserID,
        status: "DELETED",
        mobile: RegMobileNum,
        mode: "delete",
      });
      // console.log(
      //   "body",
      //   body,
      //   "Bearer token-------------------",
      //   `Bearer ${token}`,
      //   "encrypted_______________",
      //   encrypted
      // );
      Axios.post(Config.apiURL + Config.routes.addHospitalToUserAPI, body, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
          "x-caller": await encrypted,
        },
      })
        .then((response) => {
          //console.log("response for cancel", response);
          if (response.status === 201) {
            fetchHospitalData();
            return "Success";
          } else {
            return "Not Saved.";
          }
        })

        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
      return "Error";
    }
  }

  const DropdownIndicator = (props) => {
    return (
      <svg
        width="40px"
        height="30px"
        viewBox="0 0 24 24"
        cursor="pointer"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"
          fill="black" // Set your desired arrow color here
        />
      </svg>
    );
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "lightblue"
        : provided.backgroundColor,
      color: "black",
      "&:hover": {
        // backgroundColor: "lightblue", // Ensures the hover background is fully visible
        color: "black",
      },
      boxSizing: "border-box", // Ensures the width includes padding and border
      padding: "8px 12px", // Adjust padding to ensure full width coverage
      width: "100%", // Ensure the option takes the full width of the dropdown
      margin: "0", // Remove any margin that might cause the cutting
    }),
    menu: (provided) => ({
      ...provided,
      width: "auto", // Auto width based on the content
      minWidth: "50%", // Ensure menu is at least as wide as the select component
    }),
  };

  const handleHospitalClick = (index) => {
    setSelected(hospitalList[index]);
  };

  const handleSkip = () => {
    toggleSectionTab("ABDM");
  };

  const CheckStatusCount = () => {
    const fields = [
      variables.selectedState,
      variables.pinCode,
      variables.city,
      variables.addressLine1,
      variables.hospitalName,
    ];
    const numberOfFields = fields.length;
    const percentageIncrement = 100 / numberOfFields;
    let newTotalCount = 0;

    fields.forEach((field) => {
      if (field === "") {
        //console.log(`The field is empty, percentage: 0`);
      } else {
        newTotalCount += percentageIncrement;
        //console.log(`The field value: ${field} is not empty,  ${newTotalCount}`);
      }
    });

    ProfileDetailsCount(newTotalCount,"HospitalsDetailsCount")
    //console.log(newTotalCount, "totalCount");
  };

  // useEffect(() => {
  //   CheckStatusCount();
  // }, [variables]);

  return (
    <>
      <div
        className="profileRightContainer"
        style={{ display: "flex", gap: "1rem" }}
      >
        <div className="leftContainer" style={{ width: "100%" }}>
          {variables.addHospital && !variables.visits && (
            <>
              {/* <div className="topContainer">
                <img src={""} alt="User" />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.5rem",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="file"
                    id="uploadImage"
                    style={{ display: "none" }}
                  />
                  <label
                    className="upload_image"
                    htmlFor="uploadImage"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "0.75rem",
                    }}
                  >
                    Upload Hospital photos
                  </label>
                  <p className="value">
                    At least 800X800 px recommended. <br /> JPG and PNG is
                    allowed
                  </p>
                </div>
              </div> */}
              {/* <div
                style={{ background: "#AEAEAE", width: "100%", height: "1px" }}
              ></div> */}
              <div className="bottomContainer">
                <div style={{ display: "flex", gap: "1rem" }}>
                  <img
                    src={backArrow}
                    style={{ cursor: "pointer" }}
                    onClick={handleBack}
                  />
                  <p className="personalInfoHeading">Hospital Basic Details</p>
                </div>

                <div
                  style={{
                    background: "#AEAEAE",
                    width: "100%",
                    height: "1px",
                  }}
                ></div>
                <>
                  <div
                    className="personalInfoContainer"
                    style={{
                      display: "flex",
                      gap: "1rem",
                      flexWrap: "wrap",
                      marginBottom: "30px",
                      padding:"1%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "45%",
                        gap: "0.75rem",
                      }}
                    >
                      <p className="personalInfoLabel">
                        Hospital Name<span style={{ color: "red" }}> *</span>
                      </p>

                      <input
                        type="text"
                        className="profile-input-text"
                        placeholder="Hospital Name"
                        value={variables.hospitalName}
                        onChange={(e) => handleInputChange(e, "hospitalName")}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "45%",
                        gap: "0.75rem",
                      }}
                    >
                      <p className="personalInfoLabel">Hospital Address</p>

                      <input
                        type="text"
                        className="profile-input-text"
                        placeholder="Enter Hospital Address"
                        value={variables.addressLine1}
                        onChange={(e) => handleInputChange(e, "addressLine1")}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "45%",
                        gap: "0.75rem",
                      }}
                    >
                      <p className="personalInfoLabel">City</p>
                      <input
                        type="text"
                        className="profile-input-text"
                        placeholder="city"
                        value={variables.city}
                        onChange={(e) => handleInputChange(e, "city")}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "45%",
                        gap: "0.75rem",
                      }}
                    >
                      <p className="personalInfoLabel">
                        Pin Code {/*<span style={{ color: "red" }}></span>*/}
                      </p>
                      <input
                        type="text"
                        className="profile-input-text"
                        placeholder="Pin Code"
                        value={variables.pinCode}
                        onChange={(e) => handleInputChange(e, "pinCode")}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "45%",
                        gap: "0.75rem",
                        borderRadius:"10px",
                      }}
                    >
                      <p className="personalInfoLabel">State</p>
                      <Select
                        id="stateDropdown"
                        value={variables.selectedState}
                        onChange={handleStateChange}
                        options={states}
                        placeholder="Select or type a state"
                        isSearchable
                        // isClearable
                        classNames={{
                          control: (state) =>
                            state.isFocused ? "height-10vh profile-input-text" : "profile-input-text",
                        }}
                        styles={customStyles}
                        components={{ DropdownIndicator }}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "1rem",
                    }}
                  >
                    <div
                      style={{
                        width: "30%",
                        height: "45px",
                        backgroundColor:"#52447d"
                        
                      }}
                      className={"editButton2"}
                      onClick={handleSkip}
                    >
                      <p
                        style={{
                          color: "#52447d",
                          fontSize: "17px",
                          fontFamily: "Verdana",
                          fontWeight: "400",
                          lineHeight: "18.38px",
                          letterSpacing: "15%",
                          color:"white",
                          
                        }}
                      >
                        SKIP
                      </p>
                    </div>
                    <div
                      className={"editButton2"}
                      onClick={setUpHospital}
                      style={{
                        width: "fit-content",
                        marginLeft: "auto",
                        padding: "0.8rem 4rem",
                        
                      }}
                    >
                      <p style={{ color: "white" }}>ADD HOSPITAL</p>
                    </div>
                  </div>
                </>
                <ToastContainer />
              </div>
            </>
          )}

          {/* top container for First and viist page */}
          {/* {!variables.addHospital && (
            <>
              <div className="topContainer">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.5rem",
                    alignItems: "center",
                  }}
                >
                  <label
                    className="headerEdu"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "0.75rem",
                    }}
                  >
                    Hospitals
                  </label>
                </div>
              </div>
              <div
                style={{ background: "#AEAEAE", width: "100%", height: "1px" }}
              ></div>
            </>
          )} */}

          {!variables.addHospital && (
            <div className="bottomContainer">
              {/* , overflow: "scroll" */}
              <div style={{ height: "50vh", zIndex: 2 }}>
                {variables.visits && (
                  <div>
                    {/* <div
                      className="headingContainer"
                      style={{
                        justifyContent: "flex-start",
                        gap: "2rem",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={backArrow}
                        style={{ cursor: "pointer", paddingTop: "28px" }}
                        onClick={handleBack}
                      />
                      <div
                        className="searchContainerHosp"
                        style={{ display: "flex", gap: "1rem" }}
                      >
                        <img src={search} alt="" />
                        <input
                          type="text"
                          placeholder="Search"
                          onKeyUp={(e) => handleSearch(e.target.value)}
                        />
                      </div>
                    </div> */}
                    <div
                      style={{
                        height: "50vh",
                        // overflow: "scroll",
                        // marginTop: "25px",
                        display: "flex",
                        flexDirection: "column",
                        // flexDirection: "row",
                        gap: "1rem",
                      }}
                    >
                      <div>
                        <div
                          className="headingContainer"
                          style={{
                            justifyContent: "flex-start",
                            gap: "2rem",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={backArrow}
                            style={{ cursor: "pointer", paddingTop: "28px" }}
                            onClick={handleBack}
                          />
                          <div
                            className="searchContainerHosp"
                            style={{ display: "flex", gap: "1rem" }}
                          >
                            <img src={search} alt="" />
                            <input
                              type="text"
                              placeholder="Search"
                              onKeyUp={(e) => handleSearch(e.target.value)}
                            />
                          </div>
                        </div>

                        <br></br>
                        <div
                          style={{
                            height: "52vh",
                            overflow: "scroll",
                            zIndex: 1,
                            // flexDirection: "column"
                          }}
                        >
                          {filteredData.map(
                            (user, index) => (
                              // user.USER_STATUS === null ? (
                              <div
                                key={index}
                                className="hospitald-containerHospitalsList"
                                onClick={() => handleHospitalClick(index)}
                              >
                                <div key={index} className="hospiotalListItem">
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "10px",
                                    }}
                                  >
                                    <div
                                      key={user.USER_ID}
                                      className="hospNameContainer"
                                    >
                                      <p className="shortNameHosp">
                                        {user.HOSPITAL_NAME
                                          ? user.HOSPITAL_NAME.substring(
                                              0,
                                              2
                                            ).toUpperCase()
                                          : ""}
                                      </p>
                                    </div>
                                    <div>
                                      <p className="hospName">{`${user.HOSPITAL_NAME}`}</p>
                                      <p className="hospCityName">{`${user.HOSPITAL_CITY}`}</p>
                                    </div>
                                  </div>
                                  {/* {user.USER_STATUS === null ? (
                                <div
                                  className="associate"
                                  onClick={() => toggleModal(user)}
                                >
                                  <p>ASSOCIATE</p>
                                </div>
                              ) : (
                                <div
                                  className="cancelRequest"
                                  onClick={() => toggle(user)}
                                >
                                  <p style={{ color: "black" }}>
                                    Cancel Request
                                  </p>
                                </div>
                              )} */}

                                  {user.USER_STATUS === null ? (
                                    <div
                                      className="associate"
                                      style={{
                                        background: "#2d7d2d",
                                        padding: "1% 4%",
                                        borderRadius: " 13px",
                                      }}
                                      onClick={() =>
                                        showAlertDialog("Associate", user)
                                      }
                                    >
                                      <p
                                        style={{
                                          color: "white",
                                          fontSize: "13px",
                                        }}
                                      >
                                        Associate
                                      </p>
                                    </div>
                                  ) : (
                                    <div
                                      className="cancelRequest"
                                      style={{
                                        background: "#e96d6d",
                                        padding: "1% 3%",
                                        borderRadius: " 13px",
                                      }}
                                      onClick={() =>
                                        showAlertDialog("Cancel", user)
                                      }
                                    >
                                      <p
                                        style={{
                                          color: "white",
                                          fontSize: "13px",
                                        }}
                                      >
                                        Cancel Request
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )
                            // ) : (
                            //   ""
                            // )
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-end"

                        }}
                      >
                        {/* {selected && (
                          <div className="hospitalDetail">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: "1rem",
                              }}
                            >
                              <img src={""} className="threeDots" alt="" />
                              <div
                                style={{
                                  paddingBottom: "5px",
                                  textAlign: "center",
                                }}
                              >
                                <h1
                                  style={{
                                    color: "#000",
                                    fontSize: "1.5rem",
                                    fontWeight: "400",
                                    letterSpacing: "-0.0075rem",
                                  }}
                                >
                                  {`${selected.HOSPITAL_NAME} `}
                                </h1>
                              </div>
                              <div className="vector"></div>
                            </div>
                            <div className="issuesDetails">
                              <div className="observation">
                                HOSPITAL HFR ADDRESS :{" "}
                                {selected.HOSPITAL_HFR_ADDRESS
                                  ? selected.HOSPITAL_HFR_ADDRESS
                                  : "No Data"}
                              </div>
                              <div className="observation">
                                ADDRESS :{" "}
                                {selected.HOSPITAL_ADDRESS1
                                  ? selected.HOSPITAL_ADDRESS1
                                  : "No Data"}
                              </div>
                              <div className="observation">
                                CITY :{" "}
                                {selected.HOSPITAL_CITY
                                  ? selected.HOSPITAL_CITY
                                  : "No Data"}
                              </div>
                            </div>
                          </div>
                        )} */}

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: "-2%",
                          }}
                        >
                          <button
                            className="addAppointmentsBtn"
                            style={{
                              Background: "#52447d !important",
                              // boxShadow: "0px 3px 16px 1px #52447D75",
                              // marginLeft: "auto",
                              marginRight: "0px",
                              zIndex:"2",
                            }}
                            onClick={handleAssociateHospitals}
                          >
                            NEXT
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  // </div>
                )}
                {variables.rented && (
                  <div>
                    {/* <div
                      className="headingContainer"
                      style={{
                        justifyContent: "flex-start",
                        gap: "2rem",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={backArrow}
                        style={{ cursor: "pointer", paddingTop: "28px" }}
                        onClick={handleBack}
                      />
                      <div
                        className="searchContainerHosp"
                        style={{ display: "flex", gap: "1rem" }}
                      >
                        <img src={search} alt="" />
                        <input
                          type="text"
                          placeholder="Search"
                          onKeyUp={(e) => handleSearch(e.target.value)}
                        />
                      </div>
                    </div> */}
                    <div
                      style={{
                        height: "50vh",
                        // overflow: "scroll",
                        // marginTop: "25px",
                        display: "flex",
                        flexDirection: "column",
                        // flexDirection: "row",
                        gap: "1rem",
                      }}
                    >
                      <div>
                        <div
                          className="headingContainer"
                          style={{
                            justifyContent: "flex-start",
                            gap: "2rem",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={backArrow}
                            style={{ cursor: "pointer", paddingTop: "28px" }}
                            onClick={handleBack}
                          />
                          <div
                            className="searchContainerHosp"
                            style={{ display: "flex", gap: "1rem" }}
                          >
                            <img src={search} alt="" />
                            <input
                              type="text"
                              placeholder="Search"
                              onKeyUp={(e) => handleSearch(e.target.value)}
                            />
                          </div>
                        </div>

                        <br></br>
                        <div
                          style={{
                            height: "52vh",
                            overflow: "scroll",
                            zIndex: 1,
                            // flexDirection: "column"
                          }}
                        >
                          {filteredData.map(
                            (user, index) => (
                              // user.USER_STATUS === null ? (
                              <div
                                key={index}
                                className="hospitald-containerHospitalsList"
                                onClick={() => handleHospitalClick(index)}
                              >
                                <div key={index} className="hospiotalListItem">
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "10px",
                                    }}
                                  >
                                    <div
                                      key={user.USER_ID}
                                      className="hospNameContainer"
                                    >
                                      <p className="shortNameHosp">
                                        {user.HOSPITAL_NAME
                                          ? user.HOSPITAL_NAME.substring(
                                              0,
                                              2
                                            ).toUpperCase()
                                          : ""}
                                      </p>
                                    </div>
                                    <div>
                                      <p className="hospName">{`${user.HOSPITAL_NAME}`}</p>
                                      <p className="hospCityName">{`${user.HOSPITAL_CITY}`}</p>
                                    </div>
                                  </div>
                                  {/* {user.USER_STATUS === null ? (
                                <div
                                  className="associate"
                                  onClick={() => toggleModal(user)}
                                >
                                  <p>ASSOCIATE</p>
                                </div>
                              ) : (
                                <div
                                  className="cancelRequest"
                                  onClick={() => toggle(user)}
                                >
                                  <p style={{ color: "black" }}>
                                    Cancel Request
                                  </p>
                                </div>
                              )} */}

                                  {user.USER_STATUS === null ? (
                                    <div
                                      className="associate"
                                      style={{
                                        background: "#2d7d2d",
                                        padding: "1% 4%",
                                        borderRadius: " 13px",
                                      }}
                                      onClick={() =>
                                        showAlertDialog("Associate", user)
                                      }
                                    >
                                      <p
                                        style={{
                                          color: "white",
                                          fontSize: "13px",
                                        }}
                                      >
                                        Associate
                                      </p>
                                    </div>
                                  ) : (
                                    <div
                                      className="cancelRequest"
                                      style={{
                                        background: "#e96d6d",
                                        padding: "1% 3%",
                                        borderRadius: " 13px",
                                      }}
                                      onClick={() =>
                                        showAlertDialog("Cancel", user)
                                      }
                                    >
                                      <p
                                        style={{
                                          color: "white",
                                          fontSize: "13px",
                                        }}
                                      >
                                        Cancel Request
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )
                            // ) : (
                            //   ""
                            // )
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-end"

                        }}
                      >
                        {/* {selected && (
                          <div className="hospitalDetail">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: "1rem",
                              }}
                            >
                              <img src={""} className="threeDots" alt="" />
                              <div
                                style={{
                                  paddingBottom: "5px",
                                  textAlign: "center",
                                }}
                              >
                                <h1
                                  style={{
                                    color: "#000",
                                    fontSize: "1.5rem",
                                    fontWeight: "400",
                                    letterSpacing: "-0.0075rem",
                                  }}
                                >
                                  {`${selected.HOSPITAL_NAME} `}
                                </h1>
                              </div>
                              <div className="vector"></div>
                            </div>
                            <div className="issuesDetails">
                              <div className="observation">
                                HOSPITAL HFR ADDRESS :{" "}
                                {selected.HOSPITAL_HFR_ADDRESS
                                  ? selected.HOSPITAL_HFR_ADDRESS
                                  : "No Data"}
                              </div>
                              <div className="observation">
                                ADDRESS :{" "}
                                {selected.HOSPITAL_ADDRESS1
                                  ? selected.HOSPITAL_ADDRESS1
                                  : "No Data"}
                              </div>
                              <div className="observation">
                                CITY :{" "}
                                {selected.HOSPITAL_CITY
                                  ? selected.HOSPITAL_CITY
                                  : "No Data"}
                              </div>
                            </div>
                          </div>
                        )} */}

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: "-2%",
                          }}
                        >
                          <button
                            className="addAppointmentsBtn"
                            style={{
                              Background: "#52447d !important",
                              // boxShadow: "0px 3px 16px 1px #52447D75",
                              // marginLeft: "auto",
                              marginRight: "0px",
                              zIndex:"2",
                            }}
                            onClick={handleAssociateHospitals}
                          >
                            NEXT
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  // </div>
                )}


                {!variables.visits && !variables.addHospital && !variables.rented && (
                  <>
                    <div className="headingContainer">
                      <p className="personalInfoHeading">
                        Please select any one of the following
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        padding: "1rem 2rem",
                        gap: "1rem",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.95rem",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.5rem",
                            // border: "1px solid  #52447D",
                            padding: "1rem 3rem",
                            borderRadius: "6px",
                            background: "#eeecf5",
                          }}
                        >
                          <input
                            type="radio"
                            value="visit"
                            checked={variables.selectedOption === "visit"}
                            onChange={onValueChange}
                            style={{ cursor: "pointer" }}
                          />
                          <label>I visit an establishment</label>
                        </div>
                        <p className="personalInfoLabel">
                          Please select this option if the visiting hospital
                          already has an mPower profile
                        </p>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.5rem",
                            // border: "1px solid  #52447D",
                            padding: "1rem 3rem",
                            borderRadius: "6px",
                            background: "#eeecf5",
                          }}
                        >
                          <input
                            type="radio"
                            name="gender"
                            value="own"
                            checked={variables.selectedOption === "own"}
                            onChange={onValueChange}
                            style={{ cursor: "pointer" }}
                          />
                          <label>I own a Hospital</label>
                        </div>
                        <p className="personalInfoLabel">
                          Note : You can add multiple establishments one by one
                        </p>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.5rem",
                            // border: "1px solid  #52447D",
                            padding: "1rem 3rem",
                            borderRadius: "6px",
                            background: "#eeecf5",
                          }}
                        >
                          <input
                            type="radio"
                            value="rented"
                            checked={variables.selectedOption === "rented"}
                            onChange={onValueChange}
                            style={{ cursor: "pointer" }}
                          />
                          <label>Rented Hospital</label>
                        </div>
                        <p className="personalInfoLabel">
                          Please select this option if the visiting hospital
                          is rented hospital
                        </p>
                      </div>
                      <button
                        className="addAppointmentsBtn"
                        style={{
                          Background: "#52447d !important",
                          // boxShadow: "0px 3px 16px 1px #52447D75",
                          marginLeft: "auto",
                          marginTop: "1.75rem",
                        }}
                        onClick={handleNext}
                      >
                        NEXT
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
          
        </div>

        <Dialog open={variables.isModalOpen} onClose={handleClose}>
          <DialogTitle>{getAlertTitle()}</DialogTitle>

          <DialogContent>
            <p>{getAlertContent()}</p>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleAction} color="primary">
              {variables.message === "Associate"
                ? "Continue"
                : "Cancel Request"}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};
export default Hospitals;
