import React, { useEffect, useState } from "react";
import axios, { Axios } from "axios";
import "./Register.css";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { publicEncrypt, constants } from "crypto-browserify";
import { Buffer } from "buffer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProfileCamera from "../../images/camera-minimalistic 1.svg";
import Config from "../../Config/config";
import ProfileProgress from "../modules/ProfileProgress/ProfileProgress";
import { config } from "process";
import {CircularProgress} from "@mui/material";

//profile after registering
const RegProfile = ({ toggleSectionTab,ProfileDetailsCount }) => {
  const RegMobileNum = sessionStorage.getItem("RegMobileNum");
  const UserID = JSON.parse(sessionStorage.getItem("RegUserId"));
  const regProfileData =
    JSON.parse(localStorage.getItem("regProfileData")) ?? "";
  const publicKey = sessionStorage.getItem("RegpublicKey");
  const token = sessionStorage.getItem("Regtoken");
  var padding = constants.RSA_PKCS1_PADDING;
  var data = Buffer.from(UserID.toString(), "utf-8");
  var encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");

  const [file, setFile] = useState(null); //to dispaly the image selected
  const [filein, setFilein] = useState(true);
  const [value, setValue] = useState(new Date());
  const [next, setNext] = useState(false);
  const [selectedfile, setSelectedFile] = useState(null);
  const [photo, setPhoto] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const [aadhaarNumber, setAadhaarNumber] = useState('');
  const [aadhaarTransactionId, setAadhaarTransactionId] = useState('');
  const [aadhaarOtp, setAadhaarOtp] = useState('');
  const [showAadhaarOtpPopup, setShowAadhaarOtpPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [disableAadhaarButton, setDisableAadhaarButton] = useState(false);

  const [formData, setFormData] = useState({
    FIRST_NAME: "",
    LAST_NAME: "",
    MOBILE: RegMobileNum,
    DATE_OF_BIRTH: "",
    AGE: "",
    EMAIL: "",
    ADDRESS1: "",
    GENDER: "",
    TITLE: "Dr",
    CREATED_BY: UserID
  });


  const handleAadhaarInputChange = (event) => {
    const value = event.target.value;
    if (/^\d{0,12}$/.test(value)) {
      setAadhaarNumber(value);
      setDisableAadhaarButton(false);
    }
  };

  const handleAadhaarVerifyBUtton = async () => {
    setIsLoading(true);
    try {
      const paylaod = {
        "aadhaar":aadhaarNumber
      };
      const response = await axios.post(
        `${Config.abhaBaseUrl}hprGenerateAadhaarOtp`,
        paylaod,
        {
          headers: {
            "content-type": "application/json",
            authorization: `Bearer ${token}`,
            "x-caller": await encrypted,
          },
        }
      );
      console.log("verify aadhar verify response", response);
      if (response.status === 200) {
        console.log(response);
        setIsLoading(false);
        setShowAadhaarOtpPopup(true);
        setAadhaarTransactionId(response.data.txnId);
        
      }
    } catch (error) {
      console.error(error);
      if(error.response?.status === 400){
        setIsLoading(false);
        toast.error(error.response?.data?.details[0]?.message);
      } else {
        toast.error("Please try again");
      }
    }
  };

  const handleOtpSubmit = async () => {
    if (aadhaarOtp.length === 6) {

      try{
      const response = await axios.post(
        `${Config.abhaBaseUrl}hprVerifyAadhaarOtp`,
        {
          "otp": aadhaarOtp,
          "txnId": aadhaarTransactionId
        },
        {
          headers: {
            "content-type": "application/json",
            authorization: `Bearer ${token}`,
            "x-caller": await encrypted,
          },
        }
      );
      console.log("verify aadhar verify response", response);
      if (response.status === 200) {
        toast.success('Aadhaar Details Submitted Successfully');
        console.log(response);
        setShowAadhaarOtpPopup(false);
        setDisableAadhaarButton(true);
        setAadhaarTransactionId("");
        const responseData = response.data || {};
        populateAaddharResponse(responseData?.data);
        setAadhaarOtp('');
      }
    } catch(error){
      console.log("error verify otp", error);
      if(error.response?.status === 400){
        toast.error(error.response?.data?.details[0]?.message);
      } else {
        toast.error("Please try again");
      }
    }
    } else {
      toast.error('Please enter a valid 6-digit OTP');
    }
  };

  const populateAaddharResponse = (responseData) => {
    responseData =  responseData || {};
    const nameParts = responseData?.name?.split(/\s+/);
    const firstName = nameParts[0];
    let lastName = "";
    for (let i = 1; i < nameParts.length; i++) {
      lastName = lastName+" "+nameParts[i];
    }
    const gender = responseData.gender
                    ? responseData.gender === "M" ? {label:"Male", value:"Male"}
                        : responseData.gender === "F" ? {label:"Female", value:"Female"}
                        : ""
                    :"";
    const address = (responseData.address || responseData.locality || "") + " " + (responseData.district || "")+ " " +(responseData.poincode || "")
    

    setFormData({
      ...formData,
        FIRST_NAME: firstName || "",
        LAST_NAME: lastName || "",
        DATE_OF_BIRTH: responseData.birthdate || "",
        AGE: calculateAge(responseData.birthdate || ""),
        EMAIL: responseData.email || "",
        ADDRESS1: address || "",
        GENDER: gender,
        
        // TITLE: "Dr",
        // CREATED_BY: UserID
      });
  }

  const CheckStatusCount = () => {
    const numberOfFields = Object.keys(formData).length + 1;
    const percentageIncrement = 100 / numberOfFields;
    let newTotalCount = 0;

    Object.keys(formData).forEach((key) => {
      if (formData[key] === "") {
        // console.log(
        //   `The field ${key} is empty, percentage: ${percentageIncrement}`
        // );
      } else {
        newTotalCount += percentageIncrement;
        // console.log(
        //   `The field ${key} is not empty, value: ${formData[key]} ${newTotalCount}`
        // );
      }
    });
    if (photo?.length > 0 || file?.length > 0) {
      newTotalCount += percentageIncrement;
    }
    ProfileDetailsCount(newTotalCount,"ProfileDetailsCount");
   
    // setTotalCount(newTotalCount);
    //console.log(newTotalCount, "totalCount");
  };

  const [error, setError] = useState("");
  const [ageError, setAgeError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          url: Config.base_url + Config.routes.getProfileAPI + `${UserID}`,
          headers: {
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        };
        const response = await axios.request(options);
        //console.log("res", response.data.recordsets[0][0]);

        setPhoto(response.data.recordsets[0][0].USER_PHOTO);
        sessionStorage.setItem(
          "profile",
          JSON.stringify(response.data.recordsets[0][0])
        );
        const originalDate = new Date(
          response.data.recordsets[0][0].DATE_OF_BIRTH
        );

        if (response.data.recordsets[0][0].USER_PHOTO) {
          setFilein(!filein);
        }

        const formattedDate = originalDate
          .toISOString()
          .slice(0, 10)
          .split("-")
          .reverse()
          .join("/");
        //console.log("formdate", formattedDate);
        setFormData({
          FIRST_NAME: response.data.recordsets[0][0].FIRST_NAME || "",
          LAST_NAME: response.data.recordsets[0][0].LAST_NAME || "",
          MOBILE: response.data.recordsets[0][0].MOBILE || "",
          DATE_OF_BIRTH: formattedDate || new Date(),
          AGE: response.data.recordsets[0][0].age || "",
          EMAIL: response.data.recordsets[0][0].EMAIL || "",
          ADDRESS1: response.data.recordsets[0][0].ADDRESS1 || "",
          GENDER: response.data.recordsets[0][0].GENDER || "",
          TITLE: response.data.recordsets[0][0].TITLE || "Dr",
          CREATED_BY: response.data.recordsets[0][0].CREATED_BY || UserID,
        });
        setValue(originalDate);
        //console.log("date", originalDate);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
 
  }, []);
  useEffect(() => {
    if (Object.keys(formData).length > 0) {
      CheckStatusCount();
    }
  }, [formData]);
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        setFile(reader.result); // Set the base64 data URL to the state variable
      };
      reader.readAsDataURL(selectedFile);
    }
    setSelectedFile(event.target.files[0]);
  };

  const calculateAge = (birthDate) => {
    const birthDateObj = dayjs(birthDate);
    const today = dayjs();
    let age = today.year() - birthDateObj.year();
    if (!(today.isSame(birthDateObj.add(age, 'year')) || today.isAfter(birthDateObj.add(age, 'year')))) {
        age--;
    }
    return age;
  };

  const calculateDateOfBirth = (age) => {
    const today = dayjs();
    const birthdate = today.subtract(age, "year");
    const formattedDate = birthdate.format("YYYY-MM-DD");
    return formattedDate;
  }

  const handleDateChange = (dateString) => {
    setValue(dateString);
    const originalDate = new Date(dateString);
    const age = calculateAge(originalDate);
    const formattedDate = originalDate.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
    setFormData({
      ...formData,
      DATE_OF_BIRTH: formattedDate,
      AGE: age
    });
  };

  const handleInputChange = (e, ctrlName) => {
    const { value } = e.target;
    
    if (ctrlName === "MOBILE") {
      if(/^\d{0,10}$/.test(value)) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [ctrlName]: value,
        }));
      }
    } else if (ctrlName === "FIRST_NAME" || ctrlName === "LAST_NAME") {
      // Regex to allow only letters and spaces
      if (/^[A-Za-z\s]*$/.test(value)) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [ctrlName]: value,
        }));
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [ctrlName]: value,
      }));
    }
  };
  

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const handleEmailChange = (e) => {
    if (!isValidEmail(e.target.value)) {
      setError("Invalid Email");
    } else {
      setError("");
    }
    setFormData({ ...formData, EMAIL: e.target.value });
  };

  const handleAgeChange = (e) => {
    const { value } = e.target;
    const ageValue = parseInt(value, 10);

    if (value.trim() === "") {
      setAgeError("");
      setFormData({ ...formData, AGE: "" }); // Clear the age field in the form data
    } else if (
      isNaN(ageValue) ||
      value.length > 2 ||
      ageValue < 1 ||
      ageValue > 99
    ) {
      setAgeError("Invalid Age");
    } else {
      setAgeError("");
      const dob = calculateDateOfBirth(ageValue);
      setFormData({ ...formData, AGE: ageValue, DATE_OF_BIRTH: dob});
    }
  };

  const handleNext = async () => {
    //console.log("picture", selectedfile);
    if(!formData.FIRST_NAME || !formData.DATE_OF_BIRTH || !formData.AGE) {
      toast.warning("Please fill the mandatory details");
      return;
    }
    let formData1 = new FormData();
    formData1.append("picture", selectedfile);
    formData1.append("data", JSON.stringify(formData));
    var options = {
      method: "POST",
      url: `${Config.base_url}USER_PROFILE`,
      headers: {
        authorization: `Bearer ${token}`,
        "content-type": "multipart/form-data",
        "x-caller": encrypted,
      },
      data: formData1,
    };

    axios
      .request(options)
      .then(function (response) {
        //console.log(response.data);
        localStorage.setItem(
          "regProfileData",
          JSON.stringify(response.data.data)
        );
        CheckStatusCount();

        toggleSectionTab("Hospital");
        toast.success("Details have been saved successfully.");
      })
      .catch(function (error) {
        console.error(error);
      });
    setNext(!next);
  };

  return (
    <>
      <div className="profileRightContainer" style={{ display: "flex"}}>
        <div className="leftContainer" style={{ width: "70%" }}>
          <div className="regp-topContainer">
            {file ? (
              <div>
                <img
                  // photo file
                  src={file}
                  alt="Preview"
                  style={{ maxWidth: "200px", maxHeight: "200px" }}
                />
              </div>
            ) : (
              <img
                src={`${Config.apiURL}${photo}`}
                alt=""
                crossOrigin="true"
                onError={(e) => { e.target.style.display = 'none'; }}
                style={{ maxWidth: "200px", maxHeight: "200px" }}
              />
            )}

            {filein ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                  alignItems: "center",
                }}
              >
                <input
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  multiple={false}
                  id="uploadImage"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <label
                  className="upload_image"
                  htmlFor="uploadImage"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "0.75rem",
                  }}
                >
                  <img src={ProfileCamera} alt="User" />
                  Upload new photo
                </label>
                <p className="value">
                  At least 800X800 px recommended. <br /> JPG and PNG is allowed
                </p>
              </div>
            ) : null}
          </div>
          <div
            style={{ background: "#AEAEAE", width: "100%", height: "1px" }}
          ></div>
          <div className="bottomContainer">
            <div className="headingContainer">
              <p className="personalInfoHeading">Personal Info</p>
            </div>

            <div
              style={{ background: "#AEAEAE", width: "100%", height: "1px" }}
            ></div>
            <>
              <div
                className="personalInfoContainer"
                style={{
                  display: "flex",
                  gap: "2rem",
                  flexWrap: "wrap",
                }}
              >
              {/* <div style = {{ width: "100%", display:"flex", gap:"2rem" }} >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "35.33%",
                  }}
                >
                  <p className="personalInfoLabel">
                    Aadhaar No<span style={{ color: "red" }}>*</span>
                  </p>

                  <input
                    type="text"
                    style={{
                      width: "100%",
                    }}
                    className="profile-input-text"
                    value={aadhaarNumber}
                    onChange={handleAadhaarInputChange}
                    maxLength={12}
                    placeholder="Enter Aadhaar number"
                    
                  />
                </div>
                

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "20%",
                  }}
                >
                
                <button
                  style={{
                      width: "100%",
                      background:"#52447D",
                      color:"white",
                      marginTop:"20px",
                      fontSize:"16px",
                      fontWeight:"500"
                    }}
                    className="profile-input-text"
                    onClick={handleAadhaarVerifyBUtton}
                    disabled={disableAadhaarButton}
                    //disabled={isLoading}
                  >

                   {isLoading ? <div > <CircularProgress size={24} color="inherit" /> </div> : 'Verify'}
                </button>
                </div>
                
                { showAadhaarOtpPopup && (
                  <div className="otp-popup" style={{fontSize:"16px", fontWeight:"500", padding:"3px", marginLeft:"2px"}}>
                    <p>Enter OTP </p>
                    <input
                    style={{height: '50px',
                      borderRadius: '10px',
                      border: '1px solid #F7F0FF', marginRight:'10px', paddingLeft:'10px'}}
                      type="text"
                      value={aadhaarOtp}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d*$/.test(value)) {
                          setAadhaarOtp(value);
                        }
                      }}
                      maxLength={6}
                      placeholder="Enter OTP"
                    />
                  <button onClick={handleOtpSubmit} 
                  
                  style={{backgroundColor: "rgb(82, 68, 125)", color:"white", borderRadius:"10px", height:'52px', width:'100px', fontSize:'16px', fontWeight:'500'}}>Submit</button>
                </div>
              )}
            </div> */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "35.33%",
                  }}
                >
                  <p className="personalInfoLabel">
                    First Name<span style={{ color: "red" }}>*</span>
                  </p>

                  <input
                    type="text"
                    style={{
                      width: "100%",
                      //   background: "#E3EBFF",
                      // border: "1px solid background: #52447D",
                      // height: "29px",
                    }}
                    className="profile-input-text"
                    // ref={inputRef}
                    value={formData.FIRST_NAME}
                    onChange={(e) => handleInputChange(e, "FIRST_NAME")}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "35.33%",
                  }}
                >
                  <p className="personalInfoLabel">Last Name </p>

                  <input
                    type="text"
                    style={{
                      width: "100%",
                      // border: "1px solid background: #52447D",
                      // height: "29px",
                    }}
                    className="profile-input-text"
                    placeholder=""
                    value={formData.LAST_NAME}
                    onChange={(e) => handleInputChange(e, "LAST_NAME")}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "10.33%",
                  }}
                >
                  <select
                    value={formData.TITLE}
                    onChange={(e) => handleInputChange(e, "TITLE")}
                    className="profile-input-text"
                    style={{
                      width: "100%",
                      // border: "1px solid #9D9D9D",
                      // height: "29px",
                      // borderRadius: "7px",
                      // border: "none",
                      // background: "#FFF",
                      marginTop: "22px",
                    }}
                  >
                    <option value="Dr">Dr</option>
                    <option value="Mr">Mr</option>
                    <option value="Ms">Ms</option>
                    <option value="Mrs">Mrs</option>
                  </select>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "35.33%",
                  }}
                >
                  <p className="personalInfoLabel">Mobile Number</p>
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      // border: "1px solid background: #52447D",
                      // height: "29px",
                    }}
                    // className="personalInfoValue"
                    className="profile-input-text"
                    placeholder=""
                    // value={formData.MOBILE}
                    value={formData.MOBILE}
                    onChange={(e) => handleInputChange(e, "MOBILE")}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "35.33%",
                  }}
                >
                  <p className="personalInfoLabel">
                    Date of Birth<span style={{ color: "red" }}>*</span>
                  </p>
                  <div class="profile-input-date">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className="profile-input-text"
                        //defaultValue={dayjs(value)}
                        // value={formData.DATE_OF_BIRTH
                        //   ? dayjs(formData.DATE_OF_BIRTH)
                        //   : null}
                        onChange={(newValue) => handleDateChange(newValue.$d)}
                        sx={{
                          width:"100% !important",
                          // border: "1px solid #dfdfdf",
                          borderRadius:"10px"
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "10.33%",
                  }}
                >
                  <p className="personalInfoLabel">
                    Age<span style={{ color: "red" }}>*</span>
                  </p>
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      // border: "1px solid background: #52447D",
                      // height: "29px",
                    }}
                    className="profile-input-text"
                    placeholder=""
                    value={formData.AGE}
                    onChange={(e) => handleAgeChange(e)}
                  />
                  {ageError && <p style={{ color: "red" }}>{ageError}</p>}
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "35.33%",
                  }}
                >
                  <p className="personalInfoLabel">E-mail</p>
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      // border: "1px solid background: #52447D",
                      // height: "29px",
                    }}
                    className="profile-input-text"
                    placeholder=""
                    value={formData.EMAIL}
                    onChange={(e) => handleEmailChange(e)}
                  />
                  {error && <p style={{ color: "red" }}>{error}</p>}
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "35.33%",
                  }}
                >
                  <p className="personalInfoLabel">Gender</p>
                  <select
                    className="profile-input-text"
                    value={formData.GENDER}
                    onChange={(e) => handleInputChange(e, "GENDER")}
                    style={{
                      width: "100%",
                      // border: "none",
                      // height: "29px",
                    }}
                  >
                    <option value="" disabled>
                      Select Gender
                    </option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "35.33%",
                  }}
                >
                  <p className="personalInfoLabel">Address </p>
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      // border: "1px solid background: #52447D",
                      // height: "29px",
                    }}
                    className="profile-input-text"
                    placeholder=""
                    value={formData.ADDRESS1}
                    onChange={(e) => handleInputChange(e, "ADDRESS1")}
                  />
                </div>
              </div>
              <div
                className={"editButton2"}
                onClick={handleNext}
                style={{
                  width: "fit-content",
                  marginLeft: "auto",
                  padding: "0.8rem 4rem",
                }}
              >
                <p style={{ color: "white" }}>Save</p>
              </div>
            </>
            <ToastContainer />
          </div>
        </div>
        {/* <ProfileProgress  /> */}
      </div>
    </>
  );
};
export default RegProfile;
