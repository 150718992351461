import React, { useEffect, useState } from "react";
import QuestionsAnswers from "../QuestionsAnswers";
import Select from "react-select";
import Config from "../../../Config/config";
import axios from 'axios';
import { publicEncrypt, constants } from "crypto-browserify";
import { Buffer } from "buffer";
import { CircularProgress } from "@mui/material";

const PersonalHistoryTab = ({ activeSection, PersonalHistory, toggleSection3, toggleSection1 }) => {
  // console.log("PersonalHistory-----", PersonalHistory);

  const PATIENT_ID = JSON.parse(sessionStorage.getItem("PATIENT_ID"));
  // console.log("patieeeeeeeeeee", PATIENT_ID)

  const selectedPatientHistory = sessionStorage.getItem("selectedPatientHistory")!=="undefined" ? JSON.parse(sessionStorage.getItem("selectedPatientHistory")) : []
  // console.log("selectedPatientHistory----------", selectedPatientHistory)

  const VISITDATA = {
    "patientId": selectedPatientHistory.PATIENT_ID,
    "mobile": selectedPatientHistory.MOBILE,
    "nurseId": selectedPatientHistory.NURSE_USER_ID,
    "doctorId": selectedPatientHistory.DOCTOR_USER_ID,
    "visitId": selectedPatientHistory.VISIT_ID,
    "visitDate": formatVisitDate(selectedPatientHistory.VISIT_DATE),
    "medicalHistory": {
      "isDiabetes": null,
      "isHypertension": null,
      "isCKD": null,
      "isLiverFailure": null,
      "isCOPD": null,
      "isAsthma": null,
      "isHeartDisease": null,
      "isStroke": null,
      "isPVD": null,
      "isHeartAttack": null,
      "isFamilyHypertension": null,
      "isFamilyDiabetes": null,
      "isFamilyCardioVascular": null,
      "isFamilyOtherDisease": null,
      "cheifComplaint": [],
      "otherChiefComplaint": null
    },
    "drugHistory": {
      "isHypertensiveDrugs": null,
      "isDiabeticDrugs": null,
      "isLipidDrugs": null,
      "isAsprin": null,
      "isInsulin": null,
      "currentHTDrugs": [],
      "currentDBDrugs": [],
      "currentInsulin": [],
      "currentAsprin": [],
      "currentLipid": []
    },
    "personalHistory": {
      "isPhysicalActivity": null,
      "isSmoking": null,
      "isDrinking": null,
      "drinkingFrequency": null,
      "isFamilyHistoryOfMI": null,
      "isPolyuria": null,
      "isPolydypsia": null,
      "isWeightloss": null,
      "isOnDialysis": null
    },
    "examinationHistory": {
      "sbp": null,
      "dbp": null,
      "heartRate": null,
      "sbp2": null,
      "dbp2": null,
      "sbp3": null,
      "dbp3": null,
      "averageSBP": null,
      "averageDBP": null,
      "fbs": null,
      "ppbs": null,
      "rbs": null,
      "hba1c": null,
      "waist": null,
      "height": null,
      "weight": null,
      "bmi": null,
      "ldl": null,
      "creatinine": null,
      "cholestrol": null,
      "isEdema": null,
      "isBreathlessness": null,
      "isChestPain": null,
      "isDeficit": null,
      "isAlteredConciousness": null,
      "isFundus": null,
      "isGeneralizedSeizures": null,
      "gfr": null
    },
    "htDiagnosis": {
      "diagnosis": null,
      "control": null,
      "recommendation": null,
      "notes": null,
      "followup": null,
      "doctorAcceptance": null,
      "doctorNotes": null,
      "doctorId": null,
      "systemDecision": null
    },
    "dbDiagnosis": {
      "diagnosis": null,
      "control": null,
      "recommendation": null,
      "notes": null,
      "followup": null,
      "doctorAcceptance": null,
      "doctorNotes": null,
      "doctorId": null,
      "systemDecision": null
    },
    "copdDiagnosis": [],
    "tbDiagnosis": [],
    "copdHistory": {
      "isCopd": null,
      "isProgressiveDyspnoea": null,
      "isChronicCough": null,
      "isChronicSputum": null,
      "isChestPain": null,
      "isFever": null,
      "isWeightLoss": null,
      "isOrthopnoea": null,
      "isParoxysmalNocturnal": null,
      "isHaemoptysis": null,
      "isSpirometeryTest": null,
      "spirometeryValue": null,
      "isSmoking": null,
      "isBiomassFuel": null,
      "isPollution": null,
      "mmrcGrade": null,
      "isDifficultInBreathing": null,
      "isExacerbation": null,
      "isAdmitHospitalWithExacerbation": null,
      "severeBreathingDifficulty": null,
      "exacerbationSymptoms": [],
      "severeExacerbationSymptoms": [],
      "drugOptions": []
    },

    "tbHistory": {
      "confirmedTBType": [],
      "presumptiveTBType": [],
      "suspectedTBSymptoms": [],
      "suspectedComorbidities": [],
      "suspectedOtherTBDrugs": [],
      "tbPreviousDrugList": [],
      "tbCurrentDrugList": [],
      "tbAdditionalDrugList": []
    },
    "osteoArthritisHistory": {
      "oaAComorbidList": [],
      "oaPharmaList": [],
      "oaLifestyleList": [],
      "oaNonPharmaList": []
    },
    "orderTest": {
      "clinicalPathology": [],
      "radiology": [],
      "bioChemistry": [],
      "stoolAnalysis": [],
      "serology": [],
      "urineAnalysis": [],
      "cardiology": []
    }
  };

  const [personalHistory, setPersonalHistory] = useState(VISITDATA.personalHistory)
  //   {
  //   isPhysicalActivity: "",
  //   isSmoking: "",
  //   isDrinking: "",
  //   drinkingFrequency: "",
  //   isFamillyHistoryOfMI: "",
  //   isPolyuria: "",
  //   isPolydypsia: "",
  //   isWeightloss: "",
  //   isOnDialysis: "",
  // });

  const [filteredRecord, setFilteredRecord] = useState();
  const [visitData, setVisitData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const VISIT_ID = sessionStorage.getItem("VISIT_ID")

  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");

  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));

  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : JSON.parse(sessionStorage.getItem("token"));

  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");


  function formatVisitDate(visitDate) {
    // Parse the initial visit date string into a Date object
    const initialDate = new Date(visitDate);

    // Extract the date and time components
    const year = initialDate.getFullYear();
    const month = String(initialDate.getMonth() + 1).padStart(2, '0');
    const day = String(initialDate.getDate()).padStart(2, '0');
    const hours = String(initialDate.getHours()).padStart(2, '0');
    const minutes = String(initialDate.getMinutes()).padStart(2, '0');
    const seconds = String(initialDate.getSeconds()).padStart(2, '0');
    const milliseconds = String(initialDate.getMilliseconds()).padStart(5, '0');

    // Construct the formatted visit date string
    const formattedVisitDateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
    //console.log("======----formated", formattedVisitDateString)

    return formattedVisitDateString;
  }


  // useEffect(() => {
  //   try {
  //     const data = JSON.parse(PersonalHistory);

  //     setPersonalHistory({
  //       ...personalHistory,
  //       isPhysicalActivity: data.isPhysicalActivity,
  //       isSmoking: data.isSmoking,
  //       isDrinking: data.isDrinking,
  //       drinkingFrequency: data.drinkingFrequency,
  //       isFamillyHistoryOfMI: data.isFamillyHistoryOfMI,
  //       isPolyuria: data.isPolyuria,
  //       isPolydypsia: data.isPolydypsia,
  //       isWeightloss: data.isWeightloss,
  //       isOnDialysis: data.isOnDialysis,
  //     });
  //   } catch (err) {
  //     console.log(err, "err");
  //   }
  // }, []);

  useEffect(() => {
    const fetchDataAndSetDrugHistory = async () => {
      setIsLoading(true);
      try {
        let data = JSON.stringify({
          userId: UserID,
          id: PATIENT_ID,
        });

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${Config.apiURL}${Config.routes.getPatientVisitsAPI}`,
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "x-caller": encrypted,
          },
          data: data,
        };

        const response = await axios.request(config);
        console.log("Response from ..:", response, response.data.data);

        const sessionVisitID = parseInt(VISIT_ID, 10);

        const filteredRecords = response.data.data.filter(record => {
          return record.VISIT_ID === sessionVisitID;
        });
        setFilteredRecord(filteredRecords)
        //console.log("Filtered Records:", filteredRecords, typeof VISIT_ID);

        // if (response.data.data === null || response.data.data.length === 0 || response.data.data[0].VISIT_DATA === null) {
        //   setVisitData(VISITDATA);
        // }
        if (filteredRecords[0].VISIT_DATA === null) {
          //console.log("=in useeffect if=")
          setVisitData(VISITDATA);
        }

        else {
          // let res = await response.data.data[0].VISIT_DATA
          let res = await filteredRecords[0].VISIT_DATA
          //console.log("---res----", res)

          let PersonalHistoryData = res ? JSON.parse(res).personalHistory : null

          let parsedRes = !!res ? JSON.parse(res) : null
          setVisitData(parsedRes)

          if (PersonalHistoryData !== null) {

            const parsedPersonalData = JSON.parse(PersonalHistoryData)

            setPersonalHistory({
              ...personalHistory,
              isPhysicalActivity: parsedPersonalData.isPhysicalActivity,
              isSmoking: parsedPersonalData.isSmoking,
              isDrinking: parsedPersonalData.isDrinking,
              drinkingFrequency: parsedPersonalData.drinkingFrequency,
              isFamillyHistoryOfMI: parsedPersonalData.isFamillyHistoryOfMI,
              isPolyuria: parsedPersonalData.isPolyuria,
              isPolydypsia: parsedPersonalData.isPolydypsia,
              isWeightloss: parsedPersonalData.isWeightloss,
              isOnDialysis: parsedPersonalData.isOnDialysis,
            });
          }
        }
      } catch (err) {
        setIsLoading(false);
        console.log(err, "err");
      }
      setIsLoading(false);

    };

    fetchDataAndSetDrugHistory();
  }, [VISIT_ID]);


  const handleOptionSelection = (option, questionState) => {
    setPersonalHistory((prevPersonalHistory) => ({
      ...prevPersonalHistory,
      [questionState]: option,
    }));
  };

  const options = [
    { label: "Daily or almost daily", value: "Daily or almost daily" },
    { label: "Weekly", value: "Weekly" },
    { label: "Monthly", value: "Monthly" },
    { label: "Less than monthly", value: "Less than monthly" },
    { label: "Never", value: "Never" },
  ];

  const DropdownIndicator = (props) => {
    return (
      <svg
        width="40px"
        height="30px"
        viewBox="0 0 24 24"
        cursor="pointer"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"
          fill="black" // Set your desired arrow color here
        />
      </svg>
    );
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: "rgba(82, 68, 125, 1)", // Set your desired border color here
      borderRadius: "10px",
      borderWidth: "2px", // Set` your desired border thickness here
      // width: "350px", // Set your desired width here, e.g., "200px"
      height: "50px",
      boxShadow: "none",
      "&:hover": {
        borderColor: "black", // option hover color here
        color: "rgba(82, 68, 125, 1)", // option text color for hover
      },
      backgroundColor: "#eeecf5",
      border: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      //  color: "black", // Set your desired arrow color here
      color: "transparent",
    }),
    //   indicatorsContainer: (provided) => ({
    //     ...provided,
    //     width: "50px", // Set your desired arrow width here
    //     height: "50px", // Set your desired arrow height here
    //   }),
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "grey", // option hover color here
        color: "black", // option text color for hover
      },
      backgroundColor: state.isSelected
        ? "blue" // Set your desired selected option color here
        : "white",
    }),
    menu: (provided) => ({
      ...provided,
      maxHeight: "22f0px", // Set your desired max height here
      overflowY: "scroll", // Add scroll overflow for y-axis
    }),
  };

  const handleOptionsChange = (selectedOption) => {
    //console.log("++++++++++", selectedOption.value)
    setPersonalHistory((prevVariables) => ({
      ...prevVariables,
      drinkingFrequency: selectedOption.value,
    }));
  };

  function replacer(key, value) {
    try {
      if (key.trim() == '')
        return value;

      if (key.trim() === "drugHistory") {
        let modifiedJsonString = value.replace(/"(\d+\.\d+)"/g, "$1"); //(/\\"(\d+\.\d+)\\"/g, "$1");
        //console.log("===drug=====", modifiedJsonString)
        return modifiedJsonString;
      }

      if (key.trim() === "examinationHistory") {
        //console.log("===111111111110=====", value);
        let modifiedJsonString = value.replace(/"(\d+(\.\d+)?)"/g, "$1");
        //console.log("===examiantion=====", modifiedJsonString);
        return modifiedJsonString;
      }
      if (value && value !== null && typeof value === "object") {
        // console.log("value inside if", value)
        return value.replace(/"/g, '\\"'); // Replace " with \"  }   return value; }
      }
      return value;
    }
    catch (err) {
      // console.log("======key===", key, "===========value==", typeof value, "=======", value)
      // console.log(err);
      if (Array.isArray(value) && value.length === 0)
        return value;
      else
        if (key.trim() === "tbHistory" || key.trim() === "osteoArthritisHistory") {
          let jsonString = JSON.stringify(value).replace(/"/g, '\\"');;
          // console.log(jsonString, '1#####');
          jsonString = `\"${jsonString}\"`;
          // console.log(jsonString, '2#####');
          return jsonString;
        }
        else
          return JSON.stringify(value);
    }
  }

  const saveRecord = async () => {
    try {
      console.log("vist========", visitData)
      if (visitData) {
        visitData.personalHistory = JSON.stringify(personalHistory);

        let VisitData = JSON.stringify(visitData, replacer)

        //console.log("stringifiedVisitData---", VisitData)

        sessionStorage.setItem("visitData", VisitData)
        // setIsLoading(true);

        const response = await axios.post(`${Config.base_url}PATIENT_VISIT_DATA`, {
          'USER_ID': UserID,
          'VISIT_ID': visitData?.visitId?.toString(),
          'VISIT_DATA': VisitData,
          'STATUS': 2,
          'PRESCRIPTION_DATA': null,
          'CREATED_BY': visitData?.nurseId?.toString(),
        }, {
          headers: {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'x-caller': encrypted,
          }
        });
        //console.log("coming to repsoe", response)
        if (response.status === 201) {
          // setIsLoading(false);     
          return true;
        } else {
          console.error('Error: Data not saved');
          return false;
        }
      }
      else {
        //console.log("cmg to else")
        return false;
      }
    } catch (error) {
      console.error('Error:', error);
      return false;
    }
  };
  
  const handleNextClick = async () => {
    if (filteredRecord.length > 0 && filteredRecord[0].STATUS === 6) {
      console.log("deleted record");
      toggleSection3();
    } else {
      try {
        const recordSaved = await saveRecord();
        if (recordSaved) {
          toggleSection3();
        } else {
          //console.log('Error saving record');
        }
      } catch (error) {
        console.error('Error saving record:', error);
      }
    }
  };



  return isLoading ? (
    <div className="spinningLoader">
      <CircularProgress size={48} color="inherit" />
    </div>
  ) : (
    <>

      <div
        style={{
          display: activeSection === "Personal" ? "block" : "none",
          backgroundColor: "white",
          // borderRadius: "1.25rem",
        }}
      >
        <p
          className="initalAssessmentSubHeading"
        >
          Personal Habits
        </p>
        <div
          style={{
            padding: "0.7rem 2rem",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <QuestionsAnswers
            question="Does the patient engage in regular physical activity?"
            backendData={personalHistory && personalHistory.isPhysicalActivity}
            questionState="isPhysicalActivity"
            setPersonalHistory={setPersonalHistory}
            onClickButton={(option, questionState) =>
              handleOptionSelection(option, questionState)
            }
            historyData={personalHistory["isPhysicalActivity"]}
            isRequired={false}
          />
          <QuestionsAnswers
            question="Does the patient smoke or chew tobacco?"
            questionState="isSmoking"
            setPersonalHistory={setPersonalHistory}
            backendData={personalHistory && personalHistory.isSmoking}
            onClickButton={(option, questionState) =>
              handleOptionSelection(option, questionState)
            }
            historyData={personalHistory["isSmoking"]}
            isRequired={false}
          />
          <QuestionsAnswers
            question="Does the patient drink alcohol?"
            questionState="isDrinking"
            setPersonalHistory={setPersonalHistory}
            backendData={personalHistory && personalHistory.isDrinking}
            onClickButton={(option, questionState) =>
              handleOptionSelection(option, questionState)
            }
            historyData={personalHistory["isDrinking"]}
            isRequired={false}
          />
          {personalHistory.isDrinking === 0 ? (
            <>
              <p>
                How often does the patient have a drink containing alcohol?{" "}
              </p>
              <Select
                id="stateDropdown"
                // value={personalHistory.drinkingFrequency}
                value={options.find(option => option.value === personalHistory.drinkingFrequency)}
                onChange={handleOptionsChange}
                options={options}
                placeholder=""
                isSearchable
                styles={customStyles}
                components={{ DropdownIndicator }}
              // isClearable
              />
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      <div
        style={{
          display: activeSection === "Personal" ? "block" : "none",
          backgroundColor: "white",
          // borderRadius: "1.25rem",
        }}
      >
        <p
          className="initalAssessmentSubHeading"
        >
          Please check if the patient has any of these symptoms
        </p>
        <div
          style={{
            padding: "0.7rem 2rem",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            // gap: "1rem",
          }}
        >
          <QuestionsAnswers
            question="Polyuria?"
            questionState="isPolyuria"
            setPersonalHistory={setPersonalHistory}
            backendData={personalHistory && personalHistory.isPolyuria}
            onClickButton={(option, questionState) =>
              handleOptionSelection(option, questionState)
            }
            historyData={personalHistory["isPolyuria"]}
            isRequired={false}
          />
          <QuestionsAnswers
            question="Poludypsia?"
            questionState="isPolydypsia"
            setPersonalHistory={setPersonalHistory}
            backendData={personalHistory && personalHistory.isPolydypsia}
            onClickButton={(option, questionState) =>
              handleOptionSelection(option, questionState)
            }
            historyData={personalHistory["isPolydypsia"]}
            isRequired={false}
          />
          <QuestionsAnswers
            question="Weight Loss?"
            questionState="isWeightloss"
            setPersonalHistory={setPersonalHistory}
            backendData={personalHistory && personalHistory.isWeightloss}
            onClickButton={(option, questionState) =>
              handleOptionSelection(option, questionState)
            }
            historyData={personalHistory["isWeightloss"]}
            isRequired={false}
          />
          <QuestionsAnswers
            question="On Dialysis?"
            questionState="isOnDialysis"
            setPersonalHistory={setPersonalHistory}
            backendData={personalHistory && personalHistory.isOnDialysis}
            onClickButton={(option, questionState) =>
              handleOptionSelection(option, questionState)
            }
            historyData={personalHistory["isOnDialysis"]}
            isRequired={false}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          // justifyContent: "space-between",
          marginTop:"10px"
        }}
      >
        <div
          style={{
            display: activeSection === "Personal" ? "Flex" : "none",
            justifyContent: "flex-start",
             padding: "0 3%"
            // width: "100%",
          }}
          // onClick={saveRecord}
         
        >
          <button
            style={{
              background: "#5d4a87",
              color: "white",
              border: "none",
              padding: "0.8rem 2rem ",
              borderRadius: "28px",
              height: "40px",
            }}
               onClick={toggleSection1}
          >
            Back
          </button>
        </div>
        <div
        // onClick={handleNextClick}
          style={{
            display: activeSection === "Personal" ? "Flex" : "none",
            justifyContent: "flex-end",
            width: "100%",
             padding: "0 3%"
          }}
          // onClick={saveRecord}
         
        >
          <button
          
            style={{
              background: "#5d4a87",
              color: "white",
              border: "none",
              padding: "0.8rem 2rem ",
              borderRadius: "28px",
              height: "40px",
            }}
               onClick={handleNextClick}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default PersonalHistoryTab;
