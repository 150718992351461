import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./TreatmentPlan.css";
import Diabetes from "./Diabetes";
import Others from "./Others";
import LifestyleAdvice from "./LifestyleAdvice";
import BackArrow from "../../../images/Vector 34.svg";
import { Buffer } from "buffer";
import { publicEncrypt, constants } from "crypto-browserify";
import Config from "../../../Config/config";
import axios from "axios";
import Hypertension from "./Hypertension";
import Snackbar from "@mui/material/Snackbar";
import Expand from "../../../images/Group 38.png";
import DeDown from "../../../images/Group 39.png";
import LeftArrow from "../../../images/leftArrow.svg";

import { LinearProgress, TextField } from "@mui/material";

const DiabetiesdrugComponent = ({
  groupeddbDrugs,
  DrugMessageDB,
  selectedButtonValue,
  resultlengthHTDB,
  selectedPrescriptionDrugDetails,
  selectedPrescriptionDrugDetailsvalus,
}) => {
  const [selectedStrengthIndexes, setSelectedStrengthIndexes] = useState({});
  const [selectedFrequencyIndexes, setSelectedFrequencyIndexes] = useState({});
  const [selectedInstructionIndexes, setSelectedInstructionIndexes] = useState(
    {}
  );
  const [expandedCards, setExpandedCards] = useState({});
  const [weekCount, setWeekCount] = useState({});
  const [dailyCount, setDailyCount] = useState({});
  const [quantityValues, setQuantityValues] = useState({});
  const [selectedValues, setSelectedValues] = useState([]);
  const [addedDrugsInClass, setAddedDrugsInClass] = useState({});
  const [isDrugAddedInClass, setIsDrugAddedInClass] = useState({});
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVisible, setSnackbarVisible] = useState("");
  const [addedDrugs, setAddedDrugs] = useState({});
  const [selectedButtonAIndexes, setSelectedButtonAIndexes] = useState({});
  const [selectedButtonBIndexes, setSelectedButtonBIndexes] = useState({});
  const [selectedButtonCIndexes, setSelectedButtonCIndexes] = useState({});
  const [selectedFrequencyAIndexes, setSelectedFrequencyAIndexes] = useState(
    {}
  );
  const [selectedFrequencyBIndexes, setSelectedFrequencyBIndexes] = useState(
    {}
  );
  const [selectedFrequencyCIndexes, setSelectedFrequencyCIndexes] = useState(
    {}
  );
  const [selectedInstructionAIndexes, setSelectedInstructionAIndexes] =
    useState({});
  const [selectedInstructionBIndexes, setSelectedInstructionBIndexes] =
    useState({});
  const [selectedInstructionCIndexes, setSelectedInstructionCIndexes] =
    useState({});

  useEffect(() => {
    const initialExpandedState = {};
    Object.keys(groupeddbDrugs).forEach((className) => {
      initialExpandedState[className] = {};
      groupeddbDrugs[className].forEach((drugInfo) => {
        initialExpandedState[className][drugInfo.drug.drugName] = false;
        // Set initial weekCount and dailyCount for each drug
        setWeekCount((prevCount) => ({
          ...prevCount,
          [drugInfo.drug.drugName]: 0,
        }));
        setDailyCount((prevCount) => ({
          ...prevCount,
          [drugInfo.drug.drugName]: 0,
        }));
      });
    });
    setExpandedCards(initialExpandedState);
  }, [groupeddbDrugs]);
  useEffect(() => {
    const initializeCounts = () => {
      const initialWeekCount = {};
      const initialDailyCount = {};
      const initialQuantityValues = {};

      Object.keys(groupeddbDrugs).forEach((className) => {
        groupeddbDrugs[className].forEach((drugInfo) => {
          const drugName = drugInfo.drug.drugName;
          initialWeekCount[drugName] = 0;
          initialDailyCount[drugName] = 0;
          initialQuantityValues[drugName] = 0;
        });
      });

      setWeekCount(initialWeekCount);
      setDailyCount(initialDailyCount);
      setQuantityValues(initialQuantityValues);
    };

    initializeCounts();
  }, [groupeddbDrugs]);
  useEffect(() => {
    Object.keys(quantityValues).forEach((drugName) => {
      quantityCalculation(drugName, selectedFrequencyIndexes[drugName]);
    });
  }, [selectedFrequencyIndexes, weekCount, dailyCount, quantityValues]);

  const toggleCard = (className, drugName) => {
    const updatedExpandedCards = { ...expandedCards };

    // Initialize the card if it's undefined
    if (!updatedExpandedCards[className]) {
      updatedExpandedCards[className] = {};
    }

    // Toggle the current card's drugName
    updatedExpandedCards[className] = {
      ...updatedExpandedCards[className],
      [drugName]: !updatedExpandedCards[className][drugName],
    };

    setExpandedCards(updatedExpandedCards);
  };
  const handleStrengthSelection = (index, drugName, selectedButtonValue) => {
    let selectedStrengthIndexesToUpdate = {};
    switch (selectedButtonValue) {
      case "A":
        selectedStrengthIndexesToUpdate = {
          ...selectedFrequencyAIndexes,
          [drugName]: index,
        };
        setSelectedButtonAIndexes(selectedStrengthIndexesToUpdate);
        break;
      case "B":
        selectedStrengthIndexesToUpdate = {
          ...selectedFrequencyAIndexes,
          [drugName]: index,
        };
        setSelectedButtonBIndexes(selectedStrengthIndexesToUpdate);
        break;
      case "C":
        selectedStrengthIndexesToUpdate = {
          ...selectedFrequencyAIndexes,
          [drugName]: index,
        };
        setSelectedButtonCIndexes(selectedStrengthIndexesToUpdate);
        break;
      default:
        break;
    }
    setSelectedStrengthIndexes((prevState) => ({
      ...prevState,
      ...selectedStrengthIndexesToUpdate,
    }));
  };
  const handleFrequencySelection = (index, drugName, selectedButtonValue) => {
    let selectedFrequencyIndexesToUpdate = {};
    switch (selectedButtonValue) {
      case "A":
        selectedFrequencyIndexesToUpdate = {
          ...selectedFrequencyAIndexes,
          [drugName]: index,
        };
        setSelectedFrequencyAIndexes(selectedFrequencyIndexesToUpdate);
        break;
      case "B":
        selectedFrequencyIndexesToUpdate = {
          ...selectedFrequencyBIndexes,
          [drugName]: index,
        };
        setSelectedFrequencyBIndexes(selectedFrequencyIndexesToUpdate);
        break;
      case "C":
        selectedFrequencyIndexesToUpdate = {
          ...selectedFrequencyCIndexes,
          [drugName]: index,
        };
        setSelectedFrequencyCIndexes(selectedFrequencyIndexesToUpdate);
        break;
      default:
        break;
    }
    setSelectedFrequencyIndexes((prevState) => ({
      ...prevState,
      ...selectedFrequencyIndexesToUpdate,
    }));

    // Call quantity calculation for the updated drug name
    quantityCalculation(drugName, index);

    // Log the selected frequency value
    //console.log(`Selected frequency for ${drugName}: ${index}`);
  };
  const handleInstructionSelection = (index, drugName, selectedButtonValue) => {
    let selectedInstructionIndexesToUpdate = {};
    switch (selectedButtonValue) {
      case "A":
        selectedInstructionIndexesToUpdate = {
          ...selectedInstructionAIndexes,
          [drugName]: index,
        };
        setSelectedInstructionAIndexes(selectedInstructionIndexesToUpdate);
        break;
      case "B":
        selectedInstructionIndexesToUpdate = {
          ...selectedInstructionBIndexes,
          [drugName]: index,
        };
        setSelectedInstructionBIndexes(selectedInstructionIndexesToUpdate);
        break;
      case "C":
        selectedInstructionIndexesToUpdate = {
          ...selectedInstructionCIndexes,
          [drugName]: index,
        };
        setSelectedInstructionCIndexes(selectedInstructionIndexesToUpdate);
        break;
      default:
        break;
    }
    setSelectedInstructionIndexes((prevState) => ({
      ...prevState,
      ...selectedInstructionIndexesToUpdate,
    }));
  };
  const quantityCalculation = (drugName, selectedFrequencyIndex) => {
    if (
      selectedFrequencyIndex !== undefined &&
      weekCount[drugName] !== undefined &&
      dailyCount[drugName] !== undefined
    ) {
      let quantity = 0;
      const frequency = selectedFrequencyIndex + 1;

      if (
        frequency !== null &&
        weekCount[drugName] !== 0 &&
        dailyCount[drugName] !== null
      ) {
        quantity = frequency * (7 * weekCount[drugName]) + dailyCount[drugName];
      } else if (
        frequency !== null &&
        weekCount[drugName] === 0 &&
        dailyCount[drugName] !== null
      ) {
        quantity = frequency * dailyCount[drugName];
      }

      // Update quantityValues only if the new quantity is different
      if (quantityValues[drugName] !== quantity) {
        setQuantityValues((prevValues) => ({
          ...prevValues,
          [drugName]: quantity,
        }));
      }
    }
  };
  const incrementCount = (drugName) => {
    if (weekCount[drugName] < 52) {
      setWeekCount((prevCount) => ({
        ...prevCount,
        [drugName]: prevCount[drugName] + 1,
      }));
    }
    quantityCalculation(drugName);
  };

  const decrementCount = (drugName) => {
    if (weekCount[drugName] > 0) {
      setWeekCount((prevCount) => ({
        ...prevCount,
        [drugName]: prevCount[drugName] - 1,
      }));
    }
    quantityCalculation(drugName);
  };

  const incrementDailyCount = (drugName) => {
    if (dailyCount[drugName] < 6) {
      setDailyCount((prevCount) => ({
        ...prevCount,
        [drugName]: prevCount[drugName] + 1,
      }));
    }
    quantityCalculation(drugName);
  };

  const decrementDailyCount = (drugName) => {
    if (dailyCount[drugName] > 0) {
      setDailyCount((prevCount) => ({
        ...prevCount,
        [drugName]: prevCount[drugName] - 1,
      }));
    }
    quantityCalculation(drugName);
  };
  const [isAnyDrugAdded, setIsAnyDrugAdded] = useState(false);

  const handleAddClick = (drugName, className) => {
    //console.log(drugName, className, "drugName, className");
    const classData = groupeddbDrugs[className];

    if (classData) {
      const drugInfo = classData.find(
        (item) => item.drug.drugName === drugName
      );

      if (drugInfo && drugInfo.drug) {
        const selectedDrug = {
          className,
          drugName,
          strength:
            drugInfo.drug.strengthButtons[selectedStrengthIndexes[drugName]],
          frequency:
            drugInfo.drug.frequencyButtons[selectedFrequencyIndexes[drugName]],
          instruction:
            drugInfo.drug.specialInstructionsButtons[
              selectedInstructionIndexes[drugName]
            ],
          quantity: quantityValues[drugName],
          selectedButtonValue: selectedButtonValue,
        };

        setSelectedValues((prevSelectedValues) => {
          const updatedSelectedValues = [...prevSelectedValues, selectedDrug];

          selectedPrescriptionDrugDetails(updatedSelectedValues);
          return updatedSelectedValues;
        });
        // console.log("updatedSelectedValues==",updatedSelectedValues)
        // Set the updated selectedValues into session storage
        // sessionStorage.setItem(
        //   "selectedPrescriptionDrugDetails",
        //   JSON.stringify(updatedSelectedValues)
        // );
        setAddedDrugsInClass((prevState) => ({
          ...prevState,
          [className]: drugName,
        }));

        // setAddedDrugs((prevAddedDrugs) => ({
        //   ...prevAddedDrugs,
        //   [drugName]: true,
        // }));

        setIsAnyDrugAdded(true);
      }
    }
  };

  const isClassAdded = (className) => {
    return Object.keys(addedDrugsInClass).some((key) => key === className);
  };

  useEffect(() => {
    //console.log("useEffect triggered");
    if (selectedPrescriptionDrugDetailsvalus) {
      Object.keys(selectedPrescriptionDrugDetailsvalus).forEach(
        (drugitemKey, index) => {
          const drugitem = selectedPrescriptionDrugDetailsvalus[drugitemKey];
          setAddedDrugsInClass((prevState) => ({
            ...prevState,
            [drugitem.className]: drugitem.drugName,
          }));
          toggleCard(drugitem.className, drugitem.drugName);
        }
      );
    }
  }, []);
  const manualAddingDrug = (drug) => {
    console.log("drug", drug);
  };
  //console.log("selectedvalues", selectedValues);
  return resultlengthHTDB === 0 ? (
    <div>
      <Diabetes onFormSubmit={manualAddingDrug} />
    </div>
  ) : (
    <div>
      <div className="drugmes">Treatment Recommendation</div>
      <div className="drugheadermessage">
        <h4 style={{ padding: "1%", color: "rgba(9, 74, 115, 1)" }}>
          Prescribe from these drug classes:
        </h4>
        <div style={{ padding: "1%", color: "rgba(134, 134, 134, 1)" }}>
          {DrugMessageDB}
        </div>
      </div>

      {Object.keys(groupeddbDrugs).map((className) => (
        <div key={className}>
          <h2
            style={{
              backgroundColor: "#8873C9",
              color: "black",
              padding: "1%",
            }}
          >
            DrugClass:{className}
          </h2>
          <div style={{ flexWrap: "wrap" }}>
            {groupeddbDrugs[className].map((drugInfo, index) => (
              <div
                className="dropdown"
                key={index}
                style={{
                  margin: "10px",
                  padding: "10px",
                  // border: "1px solid #ccc",
                  borderRadius: "5px",
                  // width: "300px",
                }}
              >
                <h4
                  style={{
                    cursor: "pointer",
                    backgroundColor: selectedValues.some(
                      (value) =>
                        value.drugName === drugInfo.drug.drugName &&
                        value.className === className
                    )
                      ? "rgb(235, 227, 255)"
                      : "#EBE3FF",
                    padding: "2%",
                    borderRadius: "10px",
                  }}
                  onClick={() => toggleCard(className, drugInfo.drug.drugName)}
                >
                  {drugInfo.drug.drugName} {className}
                  <img
                    src={
                      expandedCards[className] &&
                      expandedCards[className][drugInfo.drug.drugName]
                        ? Expand
                        : DeDown
                    }
                    alt={
                      expandedCards[className] &&
                      expandedCards[className][drugInfo.drug.drugName]
                        ? "Collapse"
                        : "Expand"
                    }
                    style={{
                      marginLeft: "10px",
                      width: "15px",
                    }}
                  />
                </h4>

                {expandedCards[className] &&
                  expandedCards[className][drugInfo.drug.drugName] && (
                    <>
                      <div
                        className="drugdetailsdefault"
                        style={{
                          // backgroundColor: "#D9D9D9",
                          // backgroundColor: "rgb(217, 217, 217)",
                          backgroundColor: "rgba(249, 251, 255, 1)",
                          borderRadius: "17px",
                          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.4)",
                        }}
                      >
                        <p
                          style={{
                            paddingLeft: "2%",
                            paddingTop: "2%",
                            color: "rgba(9, 74, 115, 1)",
                            fontWeight: "600",
                          }}
                        >
                          Strength
                        </p>
                        <div
                          className="strengthbutons"
                          style={{ display: "flex", flexWrap: "wrap" }}
                        >
                          {drugInfo.drug.strengthButtons.map((button, idx) => (
                            <div
                              className="sbuttons"
                              key={idx}
                              onClick={() =>
                                handleStrengthSelection(
                                  idx,
                                  drugInfo.drug.drugName,
                                  selectedButtonValue
                                )
                              }
                              style={{
                                display: "flex",
                                cursor: "pointer",
                                width: "10%",
                                justifyContent: "center",
                                margin: "0 5px",
                                backgroundColor:
                                  selectedButtonValue === "A" &&
                                  selectedButtonAIndexes[
                                    drugInfo.drug.drugName
                                  ] === idx
                                    ? "rgba(82, 68, 125, 1)"
                                    : selectedButtonValue === "B" &&
                                      selectedButtonBIndexes[
                                        drugInfo.drug.drugName
                                      ] === idx
                                    ? "rgba(82, 68, 125, 1)"
                                    : selectedButtonValue === "C" &&
                                      selectedButtonCIndexes[
                                        drugInfo.drug.drugName
                                      ] === idx
                                    ? "rgba(82, 68, 125, 1)"
                                    : "rgba(243, 234, 255, 1)", // Default background color
                                padding: "0.5%",
                                borderRadius: "25px",
                                color:
                                  (selectedButtonValue === "A" &&
                                    selectedButtonAIndexes[
                                      drugInfo.drug.drugName
                                    ] === idx) ||
                                  (selectedButtonValue === "B" &&
                                    selectedButtonBIndexes[
                                      drugInfo.drug.drugName
                                    ] === idx) ||
                                  (selectedButtonValue === "C" &&
                                    selectedButtonCIndexes[
                                      drugInfo.drug.drugName
                                    ] === idx)
                                    ? "white" // Color for selected options
                                    : "rgba(82, 68, 125, 1)", // Color for unselected options
                              }}
                            >
                              {button}
                            </div>
                          ))}
                        </div>
                        <p
                          style={{
                            paddingLeft: "2%",
                            paddingTop: "1%",
                            paddingBottom: "1%",
                            color: "rgba(9, 74, 115, 1)",
                            fontWeight: "600",
                          }}
                        >
                          Frequency
                        </p>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            marginLeft: "2%",
                          }}
                        >
                          {drugInfo.drug.frequencyButtons.map((button, idx) => (
                            <div
                              className="fbuttons"
                              key={idx}
                              onClick={() =>
                                handleFrequencySelection(
                                  idx,
                                  drugInfo.drug.drugName,
                                  selectedButtonValue
                                )
                              }
                              style={{
                                display: "flex",
                                cursor: "pointer",
                                width: "10%",
                                justifyContent: "center",
                                margin: "0 5px",
                                backgroundColor:
                                  (selectedButtonValue === "A" &&
                                    selectedFrequencyAIndexes[
                                      drugInfo.drug.drugName
                                    ] === idx) ||
                                  (selectedButtonValue === "B" &&
                                    selectedFrequencyBIndexes[
                                      drugInfo.drug.drugName
                                    ] === idx) ||
                                  (selectedButtonValue === "C" &&
                                    selectedFrequencyCIndexes[
                                      drugInfo.drug.drugName
                                    ] === idx)
                                    ? "rgb(82, 68, 125)"
                                    : "rgba(243, 234, 255, 1)", // Default background color
                                color:
                                  (selectedButtonValue === "A" &&
                                    selectedFrequencyAIndexes[
                                      drugInfo.drug.drugName
                                    ] === idx) ||
                                  (selectedButtonValue === "B" &&
                                    selectedFrequencyBIndexes[
                                      drugInfo.drug.drugName
                                    ] === idx) ||
                                  (selectedButtonValue === "C" &&
                                    selectedFrequencyCIndexes[
                                      drugInfo.drug.drugName
                                    ] === idx)
                                    ? "white" // Color for selected options
                                    : "rgba(82, 68, 125, 1)", // Color for unselected options
                                padding: "5px",
                                borderRadius: "25px",
                              }}
                            >
                              {button}
                            </div>
                          ))}{" "}
                        </div>
                        <div>
                          <div>
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                marginTop: "1rem",
                                marginLeft: "1rem",
                                alignItems: "center",
                              }}
                            >
                              <span
                                style={{
                                  marginRight: "0.5rem",
                                  fontWeight: "600",
                                  color: "rgba(9, 74, 115, 1)",
                                }}
                              >
                                for
                              </span>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  backgroundColor: "rgba(82, 68, 125, 1)",
                                  width: "7vw",
                                  height: "3vh",
                                  justifyContent: "space-between",
                                  borderRadius: "20px",
                                  padding: "2%",
                                }}
                              >
                                <button
                                  className="minusButton"
                                  onClick={() =>
                                    decrementCount(drugInfo.drug.drugName)
                                  }
                                >
                                  -
                                </button>
                                <span style={{ color: "white" }}>
                                  {weekCount[drugInfo.drug.drugName] || 0}
                                </span>
                                <button
                                  className="plusButton"
                                  onClick={() =>
                                    incrementCount(drugInfo.drug.drugName)
                                  }
                                >
                                  +
                                </button>
                              </div>
                              <span
                                style={{
                                  marginRight: "0.5rem",
                                  marginLeft: "0.5rem",
                                  fontWeight: "600",
                                  color:"rgb(9, 74, 115)"
                                }}
                              >
                                weeks
                              </span>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  backgroundColor: "rgba(82, 68, 125, 1)",
                                  width: "7vw",
                                  height: "3vh",
                                  justifyContent: "space-between",
                                  borderRadius: "20px",
                                  padding: "2%",
                                }}
                              >
                                <button
                                  className="minusButton"
                                  onClick={() =>
                                    decrementDailyCount(drugInfo.drug.drugName)
                                  }
                                >
                                  -
                                </button>
                                <span style={{ color: "white" }}>
                                  {dailyCount[drugInfo.drug.drugName] || 0}
                                </span>
                                <button
                                  className="plusButton"
                                  onClick={() =>
                                    incrementDailyCount(drugInfo.drug.drugName)
                                  }
                                >
                                  +
                                </button>
                              </div>
                              <span
                                style={{
                                  marginLeft: "1rem",
                                  fontWeight: "600",
                                  color: "rgba(9, 74, 115, 1)",
                                }}
                              >
                                Days
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            marginTop: "3%",
                            marginBottom: "2%",
                          }}
                        >
                          {drugInfo.drug.specialInstructionsButtons.map(
                            (button, idx) => (
                              <div
                                key={idx}
                                onClick={() =>
                                  handleInstructionSelection(
                                    idx,
                                    drugInfo.drug.drugName,
                                    selectedButtonValue
                                  )
                                }
                                style={{
                                  display: "flex",
                                  cursor: "pointer",
                                  width: "15%",
                                  justifyContent: "center",
                                  margin: "0 0px",
                                  marginLeft:"2%",
                                  backgroundColor:
                                    (selectedButtonValue === "A" &&
                                      selectedFrequencyAIndexes[
                                        drugInfo.drug.drugName
                                      ] === idx) ||
                                    (selectedButtonValue === "B" &&
                                      selectedFrequencyBIndexes[
                                        drugInfo.drug.drugName
                                      ] === idx) ||
                                    (selectedButtonValue === "C" &&
                                      selectedFrequencyCIndexes[
                                        drugInfo.drug.drugName
                                      ] === idx)
                                      ? "rgb(82, 68, 125)"
                                      : "rgba(243, 234, 255, 1)", // Default background color
                                  color:
                                    (selectedButtonValue === "A" &&
                                      selectedFrequencyAIndexes[
                                        drugInfo.drug.drugName
                                      ] === idx) ||
                                    (selectedButtonValue === "B" &&
                                      selectedFrequencyBIndexes[
                                        drugInfo.drug.drugName
                                      ] === idx) ||
                                    (selectedButtonValue === "C" &&
                                      selectedFrequencyCIndexes[
                                        drugInfo.drug.drugName
                                      ] === idx)
                                      ? "white" // Color for selected options
                                      : "rgba(82, 68, 125, 1)", // Color for unselected options
                                  padding: "5px",
                                  borderRadius: "25px",
                                }}
                              >
                                {button}
                              </div>
                            )
                          )}
                        </div>
                        <div
                          style={{
                            backgroundColor: "#bfb4e1",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "1rem", // Added padding for spacing
                            borderRadius: "17px", // Added border radius
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Added shadow
                          }}
                        >
                          <div>
                            <span>
                              Quantity: {quantityValues[drugInfo.drug.drugName]}
                            </span>
                          </div>
                          <div>
                            <button
                              className={
                                isClassAdded(className) ||
                                (selectedValues.length > 0 &&
                                  selectedButtonValue !==
                                    (selectedValues &&
                                      selectedValues[0]?.selectedButtonValue))
                                  ? "tickbutton"
                                  : "addbutton"
                              }
                              onClick={() =>
                                handleAddClick(
                                  drugInfo.drug.drugName,
                                  className
                                )
                              }
                              disabled={
                                isClassAdded(className) ||
                                (selectedValues.length > 0 &&
                                  selectedButtonValue !==
                                    (selectedValues &&
                                      selectedValues[0]?.selectedButtonValue))
                              }
                            >
                              {isClassAdded(className) ||
                              (selectedValues.length > 0 &&
                                selectedButtonValue !==
                                  (selectedValues &&
                                    selectedValues[0]?.selectedButtonValue))
                                ? "✔️"
                                : "ADD"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
              </div>
            ))}
          </div>
        </div>
      ))}

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackbarVisible}
        autoHideDuration={6000}
        // onClose={() => setSnackbarVisible(false)}
        message={snackbarMessage}
      />
    </div>
  );
};

const GroupedDrugsComponent = ({
  groupedDrugs,
  DrugMessage,
  selectedButtonValue,
  resultlengthHTDB,
  drug,
  selectedPrescriptionDrugDetails,
  selectedPrescriptionDrugDetailsvalus,
}) => {
  const [selectedStrengthIndexes, setSelectedStrengthIndexes] = useState({});
  const [selectedFrequencyIndexes, setSelectedFrequencyIndexes] = useState({});
  const [selectedInstructionIndexes, setSelectedInstructionIndexes] = useState(
    {}
  );
  const [expandedCards, setExpandedCards] = useState({});
  const [weekCount, setWeekCount] = useState({});
  const [dailyCount, setDailyCount] = useState({});
  const [quantityValues, setQuantityValues] = useState({});
  const [selectedValues, setSelectedValues] = useState(
    selectedPrescriptionDrugDetailsvalus
  );

  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVisible, setSnackbarVisible] = useState("");

  const [selectedButtonAIndexes, setSelectedButtonAIndexes] = useState({});
  const [selectedButtonBIndexes, setSelectedButtonBIndexes] = useState({});
  const [selectedButtonCIndexes, setSelectedButtonCIndexes] = useState({});
  const [selectedFrequencyAIndexes, setSelectedFrequencyAIndexes] = useState(
    {}
  );
  const [selectedFrequencyBIndexes, setSelectedFrequencyBIndexes] = useState(
    {}
  );
  const [selectedFrequencyCIndexes, setSelectedFrequencyCIndexes] = useState(
    {}
  );
  const [selectedInstructionAIndexes, setSelectedInstructionAIndexes] =
    useState({});
  const [selectedInstructionBIndexes, setSelectedInstructionBIndexes] =
    useState({});
  const [selectedInstructionCIndexes, setSelectedInstructionCIndexes] =
    useState({});
  const [addedDrugsInClass, setAddedDrugsInClass] = useState({});

  useEffect(() => {
    const initialExpandedState = {};
    Object.keys(groupedDrugs).forEach((className) => {
      initialExpandedState[className] = {};
      groupedDrugs[className].forEach((drugInfo) => {
        initialExpandedState[className][drugInfo.drug.drugName] = false;
        // Set initial weekCount and dailyCount for each drug
        setWeekCount((prevCount) => ({
          ...prevCount,
          [drugInfo.drug.drugName]: 0,
        }));
        setDailyCount((prevCount) => ({
          ...prevCount,
          [drugInfo.drug.drugName]: 0,
        }));
      });
    });
    setExpandedCards(initialExpandedState);
  }, [groupedDrugs]);
  useEffect(() => {
    const initializeCounts = () => {
      const initialWeekCount = {};
      const initialDailyCount = {};
      const initialQuantityValues = {};

      Object.keys(groupedDrugs).forEach((className) => {
        groupedDrugs[className].forEach((drugInfo) => {
          const drugName = drugInfo.drug.drugName;
          initialWeekCount[drugName] = 0;
          initialDailyCount[drugName] = 0;
          initialQuantityValues[drugName] = 0;
        });
      });

      setWeekCount(initialWeekCount);
      setDailyCount(initialDailyCount);
      setQuantityValues(initialQuantityValues);
    };

    initializeCounts();
  }, [groupedDrugs]);
  useEffect(() => {
    Object.keys(quantityValues).forEach((drugName) => {
      quantityCalculation(drugName, selectedFrequencyIndexes[drugName]);
    });
  }, [selectedFrequencyIndexes, weekCount, dailyCount, quantityValues]);

  const toggleCard = (className, drugName) => {
    const updatedExpandedCards = { ...expandedCards };

    // Initialize the card if it's undefined
    if (!updatedExpandedCards[className]) {
      updatedExpandedCards[className] = {};
    }

    // Toggle the current card's drugName
    updatedExpandedCards[className] = {
      ...updatedExpandedCards[className],
      [drugName]: !updatedExpandedCards[className][drugName],
    };

    setExpandedCards(updatedExpandedCards);
  };

  const handleStrengthSelection = (index, drugName, selectedButtonValue) => {
    let selectedStrengthIndexesToUpdate = {};
    switch (selectedButtonValue) {
      case "A":
        selectedStrengthIndexesToUpdate = {
          ...selectedFrequencyAIndexes,
          [drugName]: index,
        };
        setSelectedButtonAIndexes(selectedStrengthIndexesToUpdate);
        break;
      case "B":
        selectedStrengthIndexesToUpdate = {
          ...selectedFrequencyAIndexes,
          [drugName]: index,
        };
        setSelectedButtonBIndexes(selectedStrengthIndexesToUpdate);
        break;
      case "C":
        selectedStrengthIndexesToUpdate = {
          ...selectedFrequencyAIndexes,
          [drugName]: index,
        };
        setSelectedButtonCIndexes(selectedStrengthIndexesToUpdate);
        break;
      default:
        break;
    }
    setSelectedStrengthIndexes((prevState) => ({
      ...prevState,
      ...selectedStrengthIndexesToUpdate,
    }));
  };
  const handleFrequencySelection = (index, drugName, selectedButtonValue) => {
    let selectedFrequencyIndexesToUpdate = {};
    switch (selectedButtonValue) {
      case "A":
        selectedFrequencyIndexesToUpdate = {
          ...selectedFrequencyAIndexes,
          [drugName]: index,
        };
        setSelectedFrequencyAIndexes(selectedFrequencyIndexesToUpdate);
        break;
      case "B":
        selectedFrequencyIndexesToUpdate = {
          ...selectedFrequencyBIndexes,
          [drugName]: index,
        };
        setSelectedFrequencyBIndexes(selectedFrequencyIndexesToUpdate);
        break;
      case "C":
        selectedFrequencyIndexesToUpdate = {
          ...selectedFrequencyCIndexes,
          [drugName]: index,
        };
        setSelectedFrequencyCIndexes(selectedFrequencyIndexesToUpdate);
        break;
      default:
        break;
    }
    setSelectedFrequencyIndexes((prevState) => ({
      ...prevState,
      ...selectedFrequencyIndexesToUpdate,
    }));

    // Call quantity calculation for the updated drug name
    quantityCalculation(drugName, index);

    // Log the selected frequency value
    //console.log(`Selected frequency for ${drugName}: ${index}`);
  };
  const handleInstructionSelection = (index, drugName, selectedButtonValue) => {
    let selectedInstructionIndexesToUpdate = {};
    switch (selectedButtonValue) {
      case "A":
        selectedInstructionIndexesToUpdate = {
          ...selectedInstructionAIndexes,
          [drugName]: index,
        };
        setSelectedInstructionAIndexes(selectedInstructionIndexesToUpdate);
        break;
      case "B":
        selectedInstructionIndexesToUpdate = {
          ...selectedInstructionBIndexes,
          [drugName]: index,
        };
        setSelectedInstructionBIndexes(selectedInstructionIndexesToUpdate);
        break;
      case "C":
        selectedInstructionIndexesToUpdate = {
          ...selectedInstructionCIndexes,
          [drugName]: index,
        };
        setSelectedInstructionCIndexes(selectedInstructionIndexesToUpdate);
        break;
      default:
        break;
    }
    setSelectedInstructionIndexes((prevState) => ({
      ...prevState,
      ...selectedInstructionIndexesToUpdate,
    }));
  };
  const quantityCalculation = (drugName, selectedFrequencyIndex) => {
    if (
      selectedFrequencyIndex !== undefined &&
      weekCount[drugName] !== undefined &&
      dailyCount[drugName] !== undefined
    ) {
      let quantity = 0;
      const frequency = selectedFrequencyIndex + 1;

      if (
        frequency !== null &&
        weekCount[drugName] !== 0 &&
        dailyCount[drugName] !== null
      ) {
        quantity = frequency * (7 * weekCount[drugName]) + dailyCount[drugName];
      } else if (
        frequency !== null &&
        weekCount[drugName] === 0 &&
        dailyCount[drugName] !== null
      ) {
        quantity = frequency * dailyCount[drugName];
      }

      // Update quantityValues only if the new quantity is different
      if (quantityValues[drugName] !== quantity) {
        setQuantityValues((prevValues) => ({
          ...prevValues,
          [drugName]: quantity,
        }));
      }
    }
  };
  const incrementCount = (drugName) => {
    if (weekCount[drugName] < 52) {
      setWeekCount((prevCount) => ({
        ...prevCount,
        [drugName]: prevCount[drugName] + 1,
      }));
    }
    quantityCalculation(drugName);
  };

  const decrementCount = (drugName) => {
    if (weekCount[drugName] > 0) {
      setWeekCount((prevCount) => ({
        ...prevCount,
        [drugName]: prevCount[drugName] - 1,
      }));
    }
    quantityCalculation(drugName);
  };

  const incrementDailyCount = (drugName) => {
    if (dailyCount[drugName] < 6) {
      setDailyCount((prevCount) => ({
        ...prevCount,
        [drugName]: prevCount[drugName] + 1,
      }));
    }
    quantityCalculation(drugName);
  };

  const decrementDailyCount = (drugName) => {
    if (dailyCount[drugName] > 0) {
      setDailyCount((prevCount) => ({
        ...prevCount,
        [drugName]: prevCount[drugName] - 1,
      }));
    }
    quantityCalculation(drugName);
  };
  // const handleAddClick = (drugName, className) => {
  //   const classData = groupedDrugs[className];

  //   if (classData) {
  //     const drugInfo = classData.find(
  //       (item) => item.drug.drugName === drugName
  //     );

  //     if (drugInfo && drugInfo.drug) {
  //       const selectedDrug = {
  //         drugName,
  //         strength:
  //           drugInfo.drug.strengthButtons[selectedStrengthIndexes[drugName]],
  //         frequency:
  //           drugInfo.drug.frequencyButtons[selectedFrequencyIndexes[drugName]],
  //         instruction:
  //           drugInfo.drug.specialInstructionsButtons[
  //             selectedInstructionIndexes[drugName]
  //           ],
  //         quantity: quantityValues[drugName],
  //         selectedButtonValue: selectedButtonValue,
  //       };

  //       setSelectedValues((prevSelectedValues) => [
  //         ...prevSelectedValues,
  //         selectedDrug,
  //       ]);

  //       setAddedDrugsInClass((prevState) => ({
  //         ...prevState,
  //         [className]: drugName,
  //       }));

  //       setAddedDrugs((prevAddedDrugs) => ({
  //         ...prevAddedDrugs,
  //         [drugName]: true,
  //       }));

  //       setIsAnyDrugAdded(true);

  //     }

  //     console.log(selectedValues,"selectedPrescriptionDrugDetails")
  //     sessionStorage.setItem("selectedPrescriptionDrugDetails",JSON.stringify(selectedValues))
  //   }
  // };
  // const isClassAdded = (drugName) => {
  //   return addedDrugs[drugName];
  // };
  const manualAddingDrug = (drug) => {
    //console.log("drug", drug);
  };
  const dropdowndrugs = (drug) => {
    //console.log("dropdowndrugs", drug);
  };
  const [isAnyDrugAdded, setIsAnyDrugAdded] = useState(false);

  const handleAddClick = (drugName, className) => {
    //console.log(drugName, className, "drugName, className");

    const classData = groupedDrugs[className];

    if (classData) {
      const drugInfo = classData.find(
        (item) => item.drug.drugName === drugName
      );

      if (drugInfo && drugInfo.drug) {
        const selectedDrug = {
          className,
          drugName,
          strength:
            drugInfo.drug.strengthButtons[selectedStrengthIndexes[drugName]],
          frequency:
            drugInfo.drug.frequencyButtons[selectedFrequencyIndexes[drugName]],
          instruction:
            drugInfo.drug.specialInstructionsButtons[
              selectedInstructionIndexes[drugName]
            ],
          quantity: quantityValues[drugName],
          selectedButtonValue: selectedButtonValue,
        };

        setSelectedValues((prevSelectedValues) => {
          const updatedSelectedValues = [...prevSelectedValues, selectedDrug];

          selectedPrescriptionDrugDetails(updatedSelectedValues);
          return updatedSelectedValues;
        });
        // console.log("updatedSelectedValues==",updatedSelectedValues)
        // Set the updated selectedValues into session storage
        // sessionStorage.setItem(
        //   "selectedPrescriptionDrugDetails",
        //   JSON.stringify(updatedSelectedValues)
        // );
        setAddedDrugsInClass((prevState) => ({
          ...prevState,
          [className]: drugName,
        }));

        // setAddedDrugs((prevAddedDrugs) => ({
        //   ...prevAddedDrugs,
        //   [drugName]: true,
        // }));

        setIsAnyDrugAdded(true);
      }
    }
  };

  const isClassAdded = (className) => {
    //console.log(className, addedDrugsInClass, "addedDrugsInClass...display");
    return Object.keys(addedDrugsInClass).some((key) => key === className);
  };

  useEffect(() => {
    const htResult = JSON.parse(sessionStorage.getItem("htResult"));
    const drugOptions = htResult?.drugOptions ?? null;
    //console.log(drugOptions, "drugOptions");

    const suggestedDrugs = drugOptions?.[0]?.suggestedDrugs ?? null;
    //console.log(suggestedDrugs, "suggestedDrugs");

    // Select the drug from suggested drugs

    //risk in adding Drug automatic seleted
    const initaldrug = [suggestedDrugs?.["7"]?.[0] ?? {}];
    //console.log(suggestedDrugs?.["7"]?.[0], initaldrug, "drug");
    var existingList = sessionStorage.getItem("addingDrugList");
    var drugList = existingList ? JSON.parse(existingList) : [];
    // console.log(
    //   initaldrug,
    //   initaldrug !== undefined,
    //   initaldrug.length > 0,
    //   initaldrug?.[0]?.className !== undefined,
    //   "drug status",
    //   initaldrug &&
    //     initaldrug !== undefined &&
    //     initaldrug.length > 0 &&
    //     initaldrug?.[0]?.className !== undefined
    // );
    if (
      initaldrug &&
      initaldrug !== undefined &&
      initaldrug.length > 0 &&
      initaldrug?.[0]?.diseaseType !== undefined
    ) {
      //console.log(drugList, "drugList.....m.........A", initaldrug.length);
      setSelectedValues(initaldrug);
      initaldrug.forEach((drug) => {
        // .match(/\d+/)[0]
        if (drug.currentDose) {
          const index = drug.currentDose / 5 - 1;
          handleStrengthSelection(index, drug.name, "A");
          //deflute
          handleInstructionSelection(1, drug.name, "A");
          handleFrequencySelection(0, drug.name, "A");
        }

        // handleAddClick(drug.drugName, drug.className);
      });
    } else if (drugList && drugList !== undefined && drugList.length > 0) {
      //console.log(drugList, "drugList..............");
      // setSelectedValues(drugList);

      // handleAddClick(drugList[0].drugName,drugList[0].className)
      drugList.forEach((drug) => {
        // .match(/\d+/)[0]
        if (drug.strength) {
          const index = drug.strength / 5 - 1;
          handleStrengthSelection(
            index,
            drug.drugName,
            drug.selectedButtonValue
          );
        }
        if (drug.frequency) {
          const idx =
            drug.frequency === "Once" ? 0 : drug.frequency === "Twice" ? 1 : 2;
          handleInstructionSelection(
            idx,
            drug.drugName,
            drug.selectedButtonValue
          );
        }
        if (drug.instruction) {
          const idx = drug.instruction === "After food" ? 0 : 1;
          handleFrequencySelection(
            idx,
            drug.drugName,
            drug.selectedButtonValue
          );
        }

        handleAddClick(drug.drugName, drug.className);
      });
    }

    // isClassAdded(initaldrug.className);
  }, []);
  useEffect(() => {
    //console.log("useEffect triggered");
    if (selectedPrescriptionDrugDetailsvalus) {
      Object.keys(selectedPrescriptionDrugDetailsvalus).forEach(
        (drugitemKey, index) => {
          const drugitem = selectedPrescriptionDrugDetailsvalus[drugitemKey];
          setAddedDrugsInClass((prevState) => ({
            ...prevState,
            [drugitem.className]: drugitem.drugName,
          }));
          toggleCard(drugitem.className, drugitem.drugName);
        }
      );
    }
  }, []);
  const [progress, setProgress] = useState(0);

  // Function to handle changes in the input field
  const handleChange = (e) => {
    const value = parseInt(e.target.value); // Parse input value to integer
    setProgress(value); // Update progress state
  };
  return resultlengthHTDB === 0 ? (
    <div>
      <Hypertension
        onFormSubmit={manualAddingDrug}
        hypertensiondrugs={dropdowndrugs}
        dropdowndrugs={dropdowndrugs}
      />
    </div>
  ) : (
    <div>
      <div className="drugmes">Treatment Recommendation</div>
      <div className="drugheadermessage">
        <h4 style={{ padding: "1%", color: "rgba(9, 74, 115, 1)" }}>
          Prescribe from these drug classes:
        </h4>
        <div style={{ padding: "1%", color: "rgba(134, 134, 134, 1)" }}>
          {DrugMessage}
        </div>
      </div>

      {Object.keys(groupedDrugs).map((className) => (
        <div key={className}>
          <h2
            style={{
              backgroundColor: "rgba(227, 237, 255, 1)",
              color: "black",
              padding: "1%",
            }}
          >
            DrugClass:{className}
          </h2>
          <div style={{ flexWrap: "wrap" }}>
            {groupedDrugs[className].map((drugInfo, index) => (
              <div
                className="dropdown"
                key={index}
                style={{
                  margin: "10px",
                  /* padding: 10px; */
                  overflow: "hidden",
                  borderRadius: "12px",
                  border: "1px solid #F7F0FF",
                }}
              >
                <h3
                  style={{
                    cursor: "pointer",
                    backgroundColor: selectedValues.some(
                      (value) =>
                        value.drugName === drugInfo.drug.drugName &&
                        value.className === className
                    )
                      ? "rgba(136, 115, 201, 1)"
                      : "#EBE3FF",
                    padding: "2%",
                    color: "rgba(82, 68, 125, 1)",
                    // borderRadius: "10px",
                  }}
                  onClick={() => {
                    toggleCard(className, drugInfo.drug.drugName);
                  }}
                >
                  {drugInfo.drug.drugName} {className}
                  <img
                    src={
                      expandedCards[className] &&
                      expandedCards[className][drugInfo.drug.drugName]
                        ? Expand
                        : DeDown
                    }
                    alt={
                      expandedCards[className] &&
                      expandedCards[className][drugInfo.drug.drugName]
                        ? "Collapse"
                        : "Expand"
                    }
                    style={{
                      marginLeft: "10px",
                      width: "15px",
                    }}
                  />
                </h3>

                {expandedCards[className] &&
                  expandedCards[className][drugInfo.drug.drugName] && (
                    <>
                      <div className="drugdetailsdefault">
                        <p
                          style={{
                            paddingLeft: "2%",
                            paddingTop: "2%",
                            color: "rgba(9, 74, 115, 1)",
                            fontWeight: "600",
                          }}
                        >
                          Strength
                        </p>
                        <div
                          className="strengthbutons"
                          style={{ display: "flex", flexWrap: "wrap" }}
                        >
                          {drugInfo.drug.strengthButtons.map((button, idx) => (
                            <div
                              className="sbuttons"
                              key={idx}
                              onClick={() =>
                                handleStrengthSelection(
                                  idx,
                                  drugInfo.drug.drugName,
                                  selectedButtonValue
                                )
                              }
                              style={{
                                display: "flex",
                                cursor: "pointer",
                                width: "10%",
                                justifyContent: "center",
                                margin: "0 5px",
                                backgroundColor:
                                  selectedButtonValue === "A" &&
                                  selectedButtonAIndexes[
                                    drugInfo.drug.drugName
                                  ] === idx
                                    ? "rgba(82, 68, 125, 1)"
                                    : selectedButtonValue === "B" &&
                                      selectedButtonBIndexes[
                                        drugInfo.drug.drugName
                                      ] === idx
                                    ? "rgba(82, 68, 125, 1)"
                                    : selectedButtonValue === "C" &&
                                      selectedButtonCIndexes[
                                        drugInfo.drug.drugName
                                      ] === idx
                                    ? "rgba(82, 68, 125, 1)"
                                    : "rgba(243, 234, 255, 1)", // Default background color
                                padding: "0.5%",
                                borderRadius: "25px",
                                color:
                                  (selectedButtonValue === "A" &&
                                    selectedButtonAIndexes[
                                      drugInfo.drug.drugName
                                    ] === idx) ||
                                  (selectedButtonValue === "B" &&
                                    selectedButtonBIndexes[
                                      drugInfo.drug.drugName
                                    ] === idx) ||
                                  (selectedButtonValue === "C" &&
                                    selectedButtonCIndexes[
                                      drugInfo.drug.drugName
                                    ] === idx)
                                    ? "white" // Color for selected options
                                    : "rgba(82, 68, 125, 1)", // Color for unselected options
                              }}
                            >
                              {button}
                            </div>
                          ))}
                        </div>
                        <p
                          style={{
                            paddingLeft: "2%",
                            paddingTop: "1%",
                            paddingBottom: "1%",
                            color: "rgba(9, 74, 115, 1)",
                            fontWeight: "600",
                          }}
                        >
                          Frequency
                        </p>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            marginLeft: "2%",
                          }}
                        >
                          {drugInfo.drug.frequencyButtons.map((button, idx) => (
                            <div
                              className="fbuttons"
                              key={idx}
                              onClick={() =>
                                handleFrequencySelection(
                                  idx,
                                  drugInfo.drug.drugName,
                                  selectedButtonValue
                                )
                              }
                              style={{
                                display: "flex",
                                cursor: "pointer",
                                width: "10%",
                                justifyContent: "center",
                                margin: "0 5px",
                                backgroundColor:
                                  (selectedButtonValue === "A" &&
                                    selectedFrequencyAIndexes[
                                      drugInfo.drug.drugName
                                    ] === idx) ||
                                  (selectedButtonValue === "B" &&
                                    selectedFrequencyBIndexes[
                                      drugInfo.drug.drugName
                                    ] === idx) ||
                                  (selectedButtonValue === "C" &&
                                    selectedFrequencyCIndexes[
                                      drugInfo.drug.drugName
                                    ] === idx)
                                    ? "rgb(82, 68, 125)"
                                    : "rgba(243, 234, 255, 1)", // Default background color
                                color:
                                  (selectedButtonValue === "A" &&
                                    selectedFrequencyAIndexes[
                                      drugInfo.drug.drugName
                                    ] === idx) ||
                                  (selectedButtonValue === "B" &&
                                    selectedFrequencyBIndexes[
                                      drugInfo.drug.drugName
                                    ] === idx) ||
                                  (selectedButtonValue === "C" &&
                                    selectedFrequencyCIndexes[
                                      drugInfo.drug.drugName
                                    ] === idx)
                                    ? "white" // Color for selected options
                                    : "rgba(82, 68, 125, 1)", // Color for unselected options
                                padding: "5px",
                                borderRadius: "25px",
                              }}
                            >
                              {button}
                            </div>
                          ))}{" "}
                        </div>
                        <div>
                          <div>
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                marginTop: "1rem",
                                marginLeft: "1rem",
                                alignItems: "center",
                              }}
                            >
                              <span
                                style={{
                                  marginRight: "0.5rem",
                                  fontWeight: "600",
                                  color: "rgba(9, 74, 115, 1)",
                                }}
                              >
                                for
                              </span>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  backgroundColor: "rgba(82, 68, 125, 1)",
                                  width: "7vw",
                                  height: "3vh",
                                  justifyContent: "space-between",
                                  borderRadius: "20px",
                                  padding: "2%",
                                  borderRadius: "20px",
                                }}
                              >
                                <button
                                  className="minusButton"
                                  onClick={() =>
                                    decrementCount(drugInfo.drug.drugName)
                                  }
                                >
                                  -
                                </button>
                                <span style={{ color: "white" }}>
                                  {weekCount[drugInfo.drug.drugName] || 0}
                                </span>
                                <button
                                  className="plusButton"
                                  onClick={() =>
                                    incrementCount(drugInfo.drug.drugName)
                                  }
                                >
                                  +
                                </button>
                              </div>
                              <span
                                style={{
                                  marginRight: "0.5rem",
                                  marginLeft: "0.5rem",
                                  color: "rgba(9, 74, 115, 1)",
                                  fontWeight: "600",
                                }}
                              >
                                weeks
                              </span>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  backgroundColor: "rgba(82, 68, 125, 1)",
                                  width: "7vw",
                                  height: "3vh",
                                  justifyContent: "space-between",
                                  borderRadius: "20px",
                                  padding: "2%",
                                }}
                              >
                                <button
                                  className="minusButton"
                                  onClick={() =>
                                    decrementDailyCount(drugInfo.drug.drugName)
                                  }
                                >
                                  -
                                </button>
                                <span style={{ color: "white" }}>
                                  {dailyCount[drugInfo.drug.drugName] || 0}
                                </span>
                                <button
                                  className="plusButton"
                                  onClick={() =>
                                    incrementDailyCount(drugInfo.drug.drugName)
                                  }
                                >
                                  +
                                </button>
                              </div>
                              <span
                                style={{
                                  marginLeft: "1rem",
                                  fontWeight: "600",
                                  color: "rgba(9, 74, 115, 1)",
                                }}
                              >
                                Days
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            marginTop: "3%",
                            marginBottom: "2%",
                          }}
                        >
                          {drugInfo.drug.specialInstructionsButtons.map(
                            (button, idx) => (
                              <div
                                key={idx}
                                onClick={() => {
                                  handleInstructionSelection(
                                    idx,
                                    drugInfo.drug.drugName,
                                    selectedButtonValue
                                  );
                                }}
                                style={{
                                  display: "flex",
                                  cursor: "pointer",
                                  width: "15%",
                                  justifyContent: "center",
                                  margin: "0 0px",
                                  marginLeft:"2%",
                                  backgroundColor:
                                    (selectedButtonValue === "A" &&
                                      selectedFrequencyAIndexes[
                                        drugInfo.drug.drugName
                                      ] === idx) ||
                                    (selectedButtonValue === "B" &&
                                      selectedFrequencyBIndexes[
                                        drugInfo.drug.drugName
                                      ] === idx) ||
                                    (selectedButtonValue === "C" &&
                                      selectedFrequencyCIndexes[
                                        drugInfo.drug.drugName
                                      ] === idx)
                                      ? "rgb(82, 68, 125)"
                                      : "rgba(243, 234, 255, 1)", // Default background color
                                  color:
                                    (selectedButtonValue === "A" &&
                                      selectedFrequencyAIndexes[
                                        drugInfo.drug.drugName
                                      ] === idx) ||
                                    (selectedButtonValue === "B" &&
                                      selectedFrequencyBIndexes[
                                        drugInfo.drug.drugName
                                      ] === idx) ||
                                    (selectedButtonValue === "C" &&
                                      selectedFrequencyCIndexes[
                                        drugInfo.drug.drugName
                                      ] === idx)
                                      ? "white" // Color for selected options
                                      : "rgba(82, 68, 125, 1)", // Color for unselected options
                                  padding: "5px",
                                  borderRadius: "25px",
                                }}
                              >
                                {button}
                              </div>
                            )
                          )}
                        </div>
                        <div
                          style={{
                            backgroundColor: "#bfb4e1",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "0.2rem 0.7rem", // Added padding for spacing
                            // borderRadius: "17px", // Added border radius
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Added shadow
                          }}
                        >
                          <div>
                            <span>
                              Quantity: {quantityValues[drugInfo.drug.drugName]}
                            </span>
                          </div>
                          <div>
                            {/* {console.log(
                              "---",

                              selectedValues.length > 0 &&
                                selectedButtonValue !==
                                  (selectedValues &&
                                    selectedValues[0]?.selectedButtonValue)
                            )} */}
                            {/* {console.log(
                              "---",

                              selectedValues &&
                                selectedValues[0]?.selectedButtonValue,
                              selectedButtonValue
                            )} */}
                            {/* <button
                              className={
                                (((selectedButtonValue == (selectedValues && selectedValues[0]?.selectedButtonValue) ))  ) ? "tickbutton" : "addbutton"
                              }
                              
                              onClick={() =>  handleAddClick(
                                  drugInfo.drug.drugName,
                                  className
                                )}
                              disabled={((selectedButtonValue == (selectedValues && selectedValues[0]?.selectedButtonValue))  )}
                            >
                              {(((selectedButtonValue == (selectedValues && selectedValues[0]?.selectedButtonValue))) ||(selectedValues.length !==0) )? "✔️" : "ADD"}
                            </button> */}

                            {/* <button
                              className={
                                selectedValues.length ||
                                isClassAdded(
                                  className,
                                  selectedValues.length !== 0
                                )
                                  ? "tickbutton"
                                  : "addbutton"
                              }
                              onClick={() =>
                                handleAddClick(
                                  drugInfo.drug.drugName,
                                  className
                                )
                              }
                              disabled={
                                selectedValues.length ||
                                isClassAdded(
                                  className,
                                  selectedValues.length !== 0
                                )
                              }
                            >
                              {
                                (selectedValues > 0 &&
                                  selectedButtonValue !==
                                    (selectedValues &&
                                      selectedValues[0]?.selectedButtonValue) &&
                                  isClassAdded(!className)) ||
                                (selectedButtonValue ===
                                  (selectedValues &&
                                    selectedValues[0]?.selectedButtonValue) &&
                                  isClassAdded(className) &&
                                  selectedButtonValue ===
                                    (selectedValues &&
                                      selectedValues[0]?.selectedButtonValue))
                                  ? "✔️"
                                  : "ADD" // If all conditions are true, return "✔️", otherwise return "ADD"
                              }
                            </button> */}
                            {/* {console.log(
                              ".....///",
                              selectedValues > 0 &&
                                selectedButtonValue !==
                                  (selectedValues &&
                                    selectedValues[0]?.selectedButtonValue)
                            )} */}
                            <button
                              className={
                                isClassAdded(className) ||
                                (selectedValues.length > 0 &&
                                  selectedButtonValue !==
                                    (selectedValues &&
                                      selectedValues[0]?.selectedButtonValue))
                                  ? "tickbutton"
                                  : "addbutton"
                              }
                              onClick={() =>
                                handleAddClick(
                                  drugInfo.drug.drugName,
                                  className
                                )
                              }
                              disabled={
                                isClassAdded(className) ||
                                (selectedValues.length > 0 &&
                                  selectedButtonValue !==
                                    (selectedValues &&
                                      selectedValues[0]?.selectedButtonValue))
                              }
                            >
                              {isClassAdded(className) ||
                              (selectedValues.length > 0 &&
                                selectedButtonValue !==
                                  (selectedValues &&
                                    selectedValues[0]?.selectedButtonValue))
                                ? "✔️"
                                : "ADD"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
              </div>
            ))}
          </div>
        </div>
      ))}
      {/* <div className="progress-container">
        <TextField
          type="number"
          label="Progress"
          value={progress}
          onChange={handleChange}
          inputProps={{ min: 0, max: 100 }}
        />
        <LinearProgress variant="determinate" value={progress} />
      </div> */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackbarVisible}
        autoHideDuration={6000}
        onClose={() => setSnackbarVisible(false)}
        message={snackbarMessage}
      />
    </div>
  );
};

const Menu = ({ selectContent, manualseleteddrugvalue }) => {
  const htResult = JSON.parse(sessionStorage.getItem("htResult") || "{}");

  const dbResult = JSON.parse(sessionStorage.getItem("dbResult") || "{}");

  const lengthREsultHT = htResult?.drugOptions?.length;
  const lengthREsultDB = dbResult?.drugOptions?.length;
  const [resultlengthHTDB, setresultlengthHTDB] = useState(0);
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(0);
  const [selectedButtonValue, setSelectedButtonValue] = useState("A");
  const [isHypertensionSelected, setIsHypertensionSelected] = useState(
    selectContent === "Hypertension"
  );
  useEffect(() => {
    setIsHypertensionSelected(selectContent === "Hypertension");
  }, [selectContent]);
  const DrugMessage =
    selectContent === "Hypertension"
      ? htResult?.drugOptions?.[selectedGroupIndex]?.drugRecommendation || ""
      : "";
  const DrugMessageDB =
    selectContent === "Diabetes"
      ? dbResult?.drugOptions?.[selectedGroupIndex]?.drugRecommendation || ""
      : "";
  const [drugItems, setDrugItems] = useState(null);
  const [drugClassList, setDrugClassList] = useState([]);
  const [drugList, setDrugList] = useState([]);
  const [dbdrugList, setdbdruglist] = useState([]);
  const [selectedPrescriptionDrugList, setselectedPrescriptionDrugList] =
    useState([]);
  const [drugClassMessage, setDrugClassMessage] = useState("");
  const insulinStrengthButtons = [
    "0 units",
    "1 unit",
    "2 units",
    "3 units",
    "4 units",
    "5 units",
    "6 units",
    "7 units",
    "8 units",
    "9 units",
    "10 units",
    "11 units",
    "12 units",
    "13 units",
    "14 units",
    "15 units",
    "16 units",
    "17 units",
    "18 units",
    "19 units",
    "20 units",
    "21 units",
    "22 units",
    "23 units",
    "24 units",
    "25 units",
    "26 units",
    "27 units",
    "28 units",
    "29 units",
    "30 units",
    "31 units",
    "32 units",
    "33 units",
    "34 units",
    "35 units",
    "36 units",
    "37 units",
    "38 units",
    "39 units",
    "40 units",
    "41 units",
    "42 units",
    "43 units",
    "44 units",
    "45 units",
    "46 units",
    "47 units",
    "48 units",
    "49 units",
    "50 units",
    "51 units",
    "52 units",
    "53 units",
    "54 units",
    "55 units",
    "56 units",
    "57 units",
    "58 units",
    "59 units",
    "60 units",
    "61 units",
    "62 units",
    "63 units",
    "64 units",
    "65 units",
    "66 units",
    "67 units",
    "68 units",
    "69 units",
    "70 units",
    "71 units",
    "72 units",
    "73 units",
    "74 units",
    "75 units",
    "76 units",
    "77 units",
    "78 units",
    "79 units",
    "80 units",
    "81 units",
    "82 units",
    "83 units",
    "84 units",
    "85 units",
    "86 units",
    "87 units",
    "88 units",
    "89 units",
    "90 units",
    "91 units",
    "92 units",
    "93 units",
    "94 units",
    "95 units",
    "96 units",
    "97 units",
    "98 units",
    "99 units",
    "100 units",
  ];
  const [
    selectedPrescriptionDrugDetailspassvalus,
    setselectedPrescriptionDrugDetailspassvalus,
  ] = useState([]);
  const htoption = htResult?.drugOptions;
  //console.log("htoptionhtoptionhtoption", htoption);
  const dboption = dbResult?.drugOptions;
  //console.log("dboptiondboptiondboption", dboption);

  useEffect(() => {
    storeresultlength();
  }, [htResult, dbResult, selectContent]);
  useEffect(() => {
    setresultlengthHTDB(0);
  }, [selectContent]);
  useEffect(() => {
    _getDrugList();
  }, [selectedGroupIndex, selectedButtonValue]);
  useEffect(() => {
    storeresultlength();
  }, [selectContent]);
  useEffect(() => {
    setSelectedGroupIndex(0);
  }, [selectContent]);
  useEffect(() => {
    getdbDrugList();
  }, [selectedGroupIndex, selectedButtonValue]);
  const storeresultlength = () => {
    let lengthREsult;
    if (selectContent === "Hypertension") {
      lengthREsult = htResult?.drugOptions?.length || 0;
    } else if (selectContent === "Diabetes") {
      lengthREsult = dbResult?.drugOptions?.length || 0;
    } else {
      lengthREsult = 0;
    }
    setresultlengthHTDB(lengthREsult);
  };

  const getGroupItems = (resultlengthHTDB) => {
    let menuItems = [];
    switch (resultlengthHTDB) {
      case 0:
        menuItems = ["ADD"];
        break;
      case 1:
        menuItems = ["A", "ADD"];
        break;
      case 2:
        menuItems = ["A", "B", "ADD"];
        break;
      case 3:
        menuItems = ["A", "B", "C", "ADD"];
        break;
      default:
        break;
    }
    return menuItems;
  };

  const menuItems = getGroupItems(resultlengthHTDB);

  function _getDrugList() {
    try {
      const convertedItems = [];
      if (!htoption || !htoption[selectedGroupIndex]) {
        return;
      }
      const drugs = htoption[selectedGroupIndex];
      if (!drugs || !drugs.suggestedDrugs) {
        return;
      }
      const suggestedDrugs = drugs.suggestedDrugs;
      Object.values(suggestedDrugs).forEach((drugList, index) => {
        drugList.forEach((drug) => {
          if (!drugClassList.includes(drug.className)) {
            setDrugClassList((prevList) => [...prevList, drug.className]);
          }
          convertedItems.push({
            className: drug.className,
            drugName: drug.name,
            strengthButtons:
              drug.className === "PM Morning" ||
              drug.className === "PM Evening" ||
              drug.className === "NPH"
                ? insulinStrengthButtons
                : [
                    (drug.doseUnit || 0) / 2 + " " + (drug.unit || ""),
                    (drug.doseUnit || 0) + " " + (drug.unit || ""),
                    (drug.doseUnit || 0) * 1.5 + " " + (drug.unit || ""),
                    (drug.doseUnit || 0) * 2 + " " + (drug.unit || ""),
                  ],
            frequencyButtons: ["Once", "Twice", "Thrice", "Four"],
            specialInstructionsButtons: ["Before food", "After food"],
            recommendedDose: drug.recommendedDose,
            doseCeiling: drug.doseCeiling,
            unit: drug.unit,
            drugProperties: drug,
            currentDose: drug.currentDose,
          });
        });
      });

      setDrugList(convertedItems);
      setDrugClassMessage(drugs.drugRecommendation || "");
    } catch (error) {
      console.error("Error in _getDrugList:", error);
    }
  }

  function getdbDrugList() {
    try {
      const convertedItems = [];
      //console.log("dropdownValueeeeeeeee", selectedGroupIndex);
      //console.log("dboption:", dboption);
      if (!dboption || !dboption[selectedGroupIndex]) {
        return;
      }

      const dbdrugs = dboption[selectedGroupIndex];
      if (!dbdrugs || !dbdrugs.suggestedDrugs) {
        return;
      }

      const suggestedDrugs = dbdrugs.suggestedDrugs;

      Object.values(suggestedDrugs).forEach((drugList, index) => {
        drugList.forEach((drug) => {
          if (!drugClassList.includes(drug.className)) {
            setDrugClassList((prevList) => [...prevList, drug.className]);
          }
          convertedItems.push({
            className: drug.className,
            drugName: drug.name,
            strengthButtons:
              drug.className === "PM Morning" ||
              drug.className === "PM Evening" ||
              drug.className === "NPH"
                ? insulinStrengthButtons
                : [
                    (drug.doseUnit || 0) / 2 + " " + (drug.unit || ""),
                    (drug.doseUnit || 0) + " " + (drug.unit || ""),
                    (drug.doseUnit || 0) * 1.5 + " " + (drug.unit || ""),
                    (drug.doseUnit || 0) * 2 + " " + (drug.unit || ""),
                  ],
            frequencyButtons: ["Once", "Twice", "Thrice", "Four"],
            specialInstructionsButtons: ["Before food", "After food"],
            recommendedDose: drug.recommendedDose,
            doseCeiling: drug.doseCeiling,
            unit: drug.unit,
            drugProperties: drug,
            currentDose: drug.currentDose,
          });
        });
      });

      setdbdruglist(convertedItems);
      setDrugClassMessage(dbdrugs.drugRecommendation || "");
    } catch (error) {
      console.error("Error in _getDrugList:", error);
    }
  }

  const groupDrugsBydbClassname = (drugList) => {
    const groupeddbDrugs = {};
    let currentIndex = 0;
    let currentClassName = null;

    drugList.forEach((drug, index) => {
      if (currentClassName !== drug.className) {
        currentIndex = 0;
        currentClassName = drug.className;
      }

      if (!groupeddbDrugs[currentClassName]) {
        groupeddbDrugs[currentClassName] = [];
      }

      groupeddbDrugs[currentClassName].push({ drug, index: currentIndex++ });
    });

    return groupeddbDrugs;
  };

  const groupDrugsByClassname = (drugList) => {
    const groupedDrugs = {};
    let currentIndex = 0;
    let currentClassName = null;

    drugList.forEach((drug, index) => {
      if (currentClassName !== drug.className) {
        currentIndex = 0;
        currentClassName = drug.className;
      }

      if (!groupedDrugs[currentClassName]) {
        groupedDrugs[currentClassName] = [];
      }

      groupedDrugs[currentClassName].push({ drug, index: currentIndex++ });
    });

    return groupedDrugs;
  };

  const groupedDrugs = groupDrugsByClassname(drugList);
  const groupeddbDrugs = groupDrugsBydbClassname(dbdrugList);
  const [addButtonClicked, setAddButtonClicked] = useState(false);

  const handleMenuItemClick = (index) => {
    // selectedPrescriptionDrugDetailsvalus(selectedPrescriptionDrugDetailspassvalus)
    const selectedItem = menuItems[index];
    setSelectedButtonValue(selectedItem);
    if (index === resultlengthHTDB) {
      setAddButtonClicked(true);
      setSelectedGroupIndex(index);
      setIsHypertensionSelected(selectContent === "Hypertension");
    } else {
      setSelectedGroupIndex(index);
      setAddButtonClicked(false);
    }
  };

  //console.log("", selectedButtonValue);
  //console.log("seeeeeeee", selectedGroupIndex);

  const manualAddingDrug = (selectedDrug) => {
    //console.log("Selected Drug:", selectedDrug);

    // Check for duplicates
    const isDuplicate = selectedPrescriptionDrugList?.some(
      (drug) =>
        drug.drugName === selectedDrug.drugName &&
        drug.strength === selectedDrug.strength
    );

    //console.log("Is Duplicate:", isDuplicate);

    if (!isDuplicate) {
      let updatedDrugDetails;
      if (Array.isArray(selectedPrescriptionDrugList[0])) {
        // If selectedPrescriptionDrugList is an array of arrays
        updatedDrugDetails = [...selectedPrescriptionDrugList, [selectedDrug]];
      } else {
        // If selectedPrescriptionDrugList is a flat array
        updatedDrugDetails = [...selectedPrescriptionDrugList, selectedDrug];
      }

      //console.log("Updated Drug Details:", updatedDrugDetails);
      // Update the state with the updated drug details list
      setselectedPrescriptionDrugList(updatedDrugDetails);
      // Update sessionStorage with the updated drug details list
      sessionStorage.setItem(
        "selectedPrescriptionDrugDetails",
        JSON.stringify(updatedDrugDetails)
      );
      // console.log(
      //   "Session Storage:",
      //   sessionStorage.getItem("selectedPrescriptionDrugDetails")
      // );
    }
  };

  const dropdowndrugs = (selectedDrug) => {
    // Assuming selectedDrug is an array, you can directly set it to the state
    setselectedPrescriptionDrugList(selectedDrug);
  };

  const selectedPrescriptionDrugDetails = (selectedDrug, selectedIndex) => {
    setselectedPrescriptionDrugDetailspassvalus(selectedDrug);
    //console.log(selectedDrug, "selectedDrugselectedDrugselectedDrug");
    const isDuplicate = selectedPrescriptionDrugList.some((drugGroup) => {
      return drugGroup.some(
        (drug) =>
          drug.drugName === selectedDrug.drugName &&
          drug.strength === selectedDrug.strength
      );
    });

    if (!isDuplicate) {
      const updatedDrugDetails = [selectedDrug];
      setselectedPrescriptionDrugList(updatedDrugDetails);
      //console.log("updatedDrugDetails", updatedDrugDetails);

      //durgadd session
      var existingList = sessionStorage.getItem("addingDrugList");
      var drugList = existingList ? JSON.parse(existingList) : [];
      var uniqueIdentifier = updatedDrugDetails?.[0]?.[0].className; // Replace with the appropriate property
      var isDuplicatedata = drugList.some(
        (item) => item.className === uniqueIdentifier
      ); // Modify 'id' accordingly

      if (!isDuplicatedata) {
        drugList.push(updatedDrugDetails?.[0]?.[0]);

        sessionStorage.setItem("addingDrugList", JSON.stringify(drugList));
        //console.log("Item added to the list:", updatedDrugDetails?.[0]?.[0]);
      } else {
        //console.log("Duplicate item not added:", updatedDrugDetails?.[0]?.[0]);
      }
      //console.log("Item total to the list:", drugList);
      //
    }
  };

  return (
    <div>
      {(isHypertensionSelected || selectContent === "Diabetes") &&
        selectContent !== "Others" &&
        selectContent !== "LifestyleAdvice" && (
          <div className="resultbuttons">
            {menuItems.map((item, index) => (
              <div key={index} className="ABCbuttons">
                <button
                  className={`abcbuutons ${
                    selectedGroupIndex === index ? "active" : ""
                  }`}
                  onClick={() => handleMenuItemClick(index)}
                >
                  {item}
                </button>
              </div>
            ))}
          </div>
        )}
      {addButtonClicked &&
        ((isHypertensionSelected && (
          <Hypertension
            onFormSubmit={manualAddingDrug}
            dropdowndrugs={dropdowndrugs}
          />
        )) ||
          (selectContent === "Diabetes" && (
            <Diabetes selectContent={selectContent} />
          )))}

      {isHypertensionSelected && !addButtonClicked && (
        <GroupedDrugsComponent
          groupedDrugs={groupedDrugs}
          DrugMessage={DrugMessage}
          selectedButtonValue={selectedButtonValue}
          resultlengthHTDB={resultlengthHTDB}
          // manualseleteddrug={manualseleteddrugvalue}
          selectedPrescriptionDrugDetails={selectedPrescriptionDrugDetails}
          selectedPrescriptionDrugDetailsvalus={
            selectedPrescriptionDrugDetailspassvalus
          }
        />
      )}
      {selectContent === "Diabetes" && !addButtonClicked && (
        <DiabetiesdrugComponent
          groupeddbDrugs={groupeddbDrugs}
          DrugMessageDB={DrugMessageDB}
          selectedButtonValue={selectedButtonValue}
          resultlengthHTDB={resultlengthHTDB}
          selectedPrescriptionDrugDetails={selectedPrescriptionDrugDetails}
          selectedPrescriptionDrugDetailsvalus={
            selectedPrescriptionDrugDetailspassvalus
          }
        />
      )}
    </div>
  );
};
const TabsContainer = () => {
  const [activeSection, setActiveSection] = useState();
  const [selectContent, setSelectedContent] = useState("Others");
  const [visitHistory, setVisitHistory] = useState(null);
  const navigation = useNavigate();
  const showContent = (contentId) => {
    setSelectedContent(contentId);
    setActiveSection(contentId);
  };
  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");
  const PATIENT = sessionStorage.getItem("SelectedPatient")
    ? JSON.parse(sessionStorage.getItem("SelectedPatient"))
    : sessionStorage.getItem("SelectedPatient");
  const PATIENT_ID = PATIENT.PATIENT_ID;
  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));
  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : JSON.parse(sessionStorage.getItem("token"));
  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");

  useEffect(() => {
    const fetchData = async () => {
      const UserID = JSON.parse(sessionStorage.getItem("UserID"));
      const PATIENT_ID = JSON.parse(sessionStorage.getItem("PATIENT_ID"));
      let data = JSON.stringify({
        userId: UserID,
        id: PATIENT_ID,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${Config.apiURL}${Config.routes.getPatientVisitsAPI}?userId=${UserID}&id=${PATIENT_ID}`,
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "x-caller": encrypted,
        },
        data: data,
      };

      try {
        const response = await axios.request(config);
        setVisitHistory(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (gethtDisabledButtons() && getdbDisabledButtons()) {
      setActiveSection("Others");
      setSelectedContent("Others");
    } else if (!gethtDisabledButtons() && !getdbDisabledButtons()) {
      setActiveSection("Hypertension");
      setSelectedContent("Hypertension");
    } else if (gethtDisabledButtons()) {
      setActiveSection("Diabetes");
      setSelectedContent("Diabetes");
    } else if (getdbDisabledButtons()) {
      setActiveSection("Hypertension");
      setSelectedContent("Hypertension");
    } else {
      setSelectedContent("Others");
    }
  }, [visitHistory]);

  const gethtDisabledButtons = () => {
    if (visitHistory?.[0] && visitHistory[0].VISIT_DATA) {
      const htDiagnosisData = JSON.parse(visitHistory[0].VISIT_DATA);
      const htDiagnosis = JSON.parse(htDiagnosisData?.htDiagnosis);
      if (
        htDiagnosis &&
        ((htDiagnosis.diagnosis === 0 && htDiagnosis.doctorAcceptance === 0) ||
          (htDiagnosis.diagnosis === 1 && htDiagnosis.doctorAcceptance === 1))
      ) {
        return true;
      }
    }
    return false;
  };
  const getdbDisabledButtons = () => {
    if (visitHistory?.[0] && visitHistory[0].VISIT_DATA) {
      const htDiagnosisData = JSON.parse(visitHistory[0].VISIT_DATA);
      const dbDiagnosis = JSON.parse(htDiagnosisData?.dbDiagnosis);
      if (
        dbDiagnosis &&
        ((dbDiagnosis.diagnosis === 0 && dbDiagnosis.doctorAcceptance === 0) ||
          (dbDiagnosis.diagnosis === 1 && dbDiagnosis.doctorAcceptance === 1))
      ) {
        return true;
      }
    }
    return false;
  };

  const handleNaviagteToShowPrescription = (visit) => {
    const patientId = sessionStorage.getItem("PATIENT_ID");
    if (patientId !== null) {
      navigation("/patients/treatmentplan/Prescription");
    }
  };
  const othersaddingdrug = (drug) => {
    //console.log("drugData ............", drug);
  };

  return (
    <div className="tabs-container">
      <div className="tabs">
        <button
          className={`toggle-buttonTab ${
            activeSection === "Hypertension" ? "activeTab" : ""
          } ${gethtDisabledButtons() ? "disabledButton" : ""}`}
          onClick={() => showContent("Hypertension")}
          disabled={gethtDisabledButtons()}
        >
          Hypertension
        </button>
        <button
          className={`toggle-buttonTab ${
            activeSection === "Diabetes" ? "activeTab" : ""
          } ${getdbDisabledButtons() ? "disabledButton" : ""}`}
          onClick={() => showContent("Diabetes")}
          disabled={getdbDisabledButtons()}
        >
          Diabetes
        </button>
        <button
          className={`toggle-buttonTab ${
            activeSection === "Others" ? "activeTab" : ""
          }`}
          onClick={() => showContent("Others")}
        >
          Others
        </button>
        <button
          className={`toggle-buttonTab ${
            activeSection === "LifestyleAdvice" ? "activeTab" : ""
          }`}
          onClick={() => showContent("LifestyleAdvice")}
        >
          Lifestyle Advice
        </button>
      </div>
      <div className="prescriptionbutton">
        {/*select an option */}
        <button
          className="Prescription"
          type="submit"
          onClick={handleNaviagteToShowPrescription}
        >
          Prescription
        </button>
      </div>
      <div style={{ paddingLeft: "2%" }}>
        <Menu selectContent={selectContent} />
      </div>
      <div>
        {selectContent === "Others" && (
          <Others onFormSubmit={othersaddingdrug} />
        )}
        {selectContent === "LifestyleAdvice" && <LifestyleAdvice />}
      </div>
    </div>
  );
};

const TreatmentPlan = () => {
  const navigation = useNavigate();
  const handletoBack = () => {
    sessionStorage.removeItem("addingDrugList");
    navigation("/patients/diagnosis");
  };

  return (
    <div className="treatmentplanmain">
      <div className="treatmentplan">
        <div className="theader">
          <div>
            <img
              style={{ width: "10px", height: "15px" }}
              src={LeftArrow}
              alt="Logo"
              onClick={handletoBack}
            />
          </div>
          <h1 className="treatment-heading">TREATMENT PLAN</h1>
        </div>

        <TabsContainer />
      </div>
    </div>
  );
};

export default TreatmentPlan;
