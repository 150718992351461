import React, { useState, useEffect } from "react";
import backgroundCircle from "../../../images/backgroundCircle.png";
import Navbar from "../Navbar";
import { publicEncrypt, constants } from "crypto-browserify";
import "./ABHA.css";
import { Buffer } from "buffer";
import Config from "../../../Config/config";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import NHA from "../../../images/nha.svg";
import {
  Snackbar,
  Button,
  CircularProgress,
  Alert,
  colors,
} from "@mui/material";
import OtpInput from "react-otp-input";
import AbhaMobileValidation from "./AbhaMobileValidation";
import AbhaAddressSetUpPage from "./Abha_Address_SetUp";
import JSEncrypt from "jsencrypt";

const ABHAIntegration = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const skipRoute = queryParams.get("action");

  const [selectedOption, setSelectedOption] = useState("alreadyRegistered");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [mobileOtpButton, setMobileOtpButton] = useState(false); //MOBILE OTP BUTTON AFTER INTEGRATE
  const [aadhaarOtpButton, setAadharOtpButton] = useState(false); //AADHAAR OTP BUTTON AFTER INTEGRATE
  const [activeSection, setActiveSection] = useState("verifyotp");

  const [variables, setVariables] = useState({
    abhaNo: "",
    aadhaarNumber: "",
    showAlert: false,
    alertTitle: "",
    alertMessage: "",
    type: "",
    formType: 0,
    otp: "",
    phrMobile: "",
  });
  const [visible, setVisible] = useState({
    mobileOtp: false,
    aadhaarOtp: false,
    aadharNoOtp: false, // for register screen
    loading: null,
    MobileValidationScreen: false,
    mobileOtpValidationForMobileScreen: false,
  }); // variables to dispaly the otp screen

  // Handle OTP change
  const handleChange = (otp) => {
    setVariables((prevState) => ({
      ...prevState,
      otp: otp,
    }));
  };

  const userDetails = sessionStorage.getItem("UserData")
  ? JSON.parse(sessionStorage.getItem("UserData"))[0]
  : "";

  //api call data
  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");

  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));
  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : JSON.parse(sessionStorage.getItem("token"));

  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");
  //
  useEffect(() => {
    getPublickey();
  },[UserID,variables.phrMobile]);

  const PATIENT_ID = sessionStorage.getItem("PATIENT_ID");

  const onActiveSectionChange = (activeSection) => {
    setActiveSection(activeSection);
  };

  const ShowAlert = ({ title, message, onClose }) => {
    return (
      <div className="popup">
        <div className="popup-inner">
          <p
            style={{ fontSize: "24px", fontWeight: "600", fontFamily: "bold" }}
          >
            {title}
          </p>
          <p>{message}</p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "2rem",
              padding: "4%",
              justifyContent: "flex-end",
            }}
          >
            <button
              className="ContinueBtn"
              onClick={
                variables.type === "uploadAbhaData"
                  ? handleNavigateToDemographics
                  : onClose
              }
              style={{
                background: "#52447d",
                width: "20%",
              }}
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    );
  };

  const handleNavigateToDemographics = () => {
    navigate(`/abha/demographics?action=${skipRoute}`);
  };

  const handleAlertClose = () => {
    setVariables((prevState) => ({
      ...prevState,
      showAlert: false,
    }));
  };

  const getPublickey = async () => {
    // console.log(UserID,"jdified",token)
    Axios.get(`${Config.abhaBaseUrl}publicKey/${UserID}`, {
      headers: {
        authorization: `Bearer ${token}`,
        "x-caller": await encrypted,
      },
    })
      .then((response) => {
        // console.log("getPublickey response :", response);
        if (response.status == 200) {
          // console.log("PUBLICKEY :", response.data);
          sessionStorage.setItem("ABDMPublicKey", response.data);
          return response;
        }
      })
      .catch((err) => {
        console.log(`${err} Please reload the page and try again`);
      });
  };

  const getSessionTokenAPI = async () => {
    if (variables.abhaNo.trim() !== "") {
      setShowLoading(true);

      const dataBody = {
        healthId: variables.abhaNo,
        USER_ID: UserID,
      };
      //console.log("Databody", dataBody, encrypted)

      await Axios.post(`${Config.abhaBaseUrl}getSessionToken`, dataBody, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
          "x-caller": encrypted,
        },
      })

        .then((response) => {
          sessionStorage.setItem("abhaAddress", dataBody.healthId);
          if (response.status === 422) {
            // console.log("response:", response);
          } else if (response.status === 200) {
            sessionStorage.setItem("accessToken", response.data.accessToken);
            setShowLoading(false);
            setMobileOtpButton(true);
            setAadharOtpButton(true);
          }
        })
        .catch(async (error) => {
          console.log(error);
          setShowLoading(false);
          setSnackbarMessage(
            "An error occurred.Please try again after sometime"
          );
          setShowSnackbar(true);
        });
    } else {
      setSnackbarMessage("Please enter a valid value");
      setShowSnackbar(true);
    }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setVisible((prevState) => ({
      ...prevState,
      mobileOtp: false,
      aadhaarOtp: false,
      // MobileValidationScreen: false,
    }));

    setVariables((prevState) => ({
      abhaNo: "",
      aadhaarNumber: "",
      showAlert: false,
      alertTitle: "",
      alertMessage: "",
      type: "",
      formType: 0,
      otp: "",
      phrMobile: "",
    }));
    setVariables((prevState) => ({
      ...prevState,
      abhaNo: "",
    }));
    setMobileOtpButton(false);
    setAadharOtpButton(false);
  };

  const skipABHA = () => {
    if (skipRoute === "addPatient") {
      console.log("Skipped integration");
      navigate("/patients/add-patients?action=addpatient");
    } else {
      //console.log("Skipped integration");
    }
  };

  // aadhar and mobile buttons
  const handleButtonClick = async (component) => {
    const value = await sessionStorage.getItem("abhaAddress");
    if (component === "mobileOtp") {
      setVisible((prevState) => ({
        ...prevState,
        loading: component,
      }));
      await generateMobileOtpABHA(value);
    } else if (component === "aadhaarOtp") {
      setVisible((prevState) => ({
        ...prevState,
        loading: component,
      }));
      await generateAadhaarOtpABHA(value);
    }

    setVisible((prevState) => ({
      ...prevState,
      loading: null,
    }));
  };

  //having abha number api calls
  const generateAadhaarOtpABHA = async (abhaId) => {
    try {
      const accessToken = await sessionStorage.getItem("accessToken");
      const dataBody = {
        abhaId: abhaId,
        USER_ID: UserID,
        token: accessToken,
      };

      const response = await Axios.post(
        `${Config.abhaBaseUrl}generateAadhaarOtpABHA`,
        dataBody,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        }
      );

      // console.log(
      //   "response in generateAadhaarOtpABHA",
      //   response,
      //   "status",
      //   response.status
      // );

      if (response.status === 422) {
        setVisible((prevState) => ({
          ...prevState,
          mobileOtp: false,
          aadhaarOtp: false,
        }));
        let details = response.data.details[0];
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: response.data.code,
          alertMessage:
            details && details.length > 0
              ? details.message
              : response.data.message,
        }));
        setSelectedOption("alreadyRegistered");
        // setShowLoading(false)
      } else if (response.status === 200) {
        sessionStorage.setItem("authTransactionId", response.data.txnId);
        setVariables((prevState) => ({
          ...prevState,
          formType: 4,
        }));

        setVisible((prevState) => ({
          ...prevState,
          mobileOtp: true,
          aadhaarOtp: true,
        }));
      } else {
        setVisible((prevState) => ({
          ...prevState,
          mobileOtp: false,
          aadhaarOtp: false,
        }));
        // setShowLoading(false)
        setSelectedOption("alreadyRegistered");
        setSnackbarMessage(
          "ABHA Server down!!!",
          "The ABHA server is down at this time. Please try after sometime."
        );
        setShowSnackbar(true);
      }
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 422) {
        let details = error.response.data.details[0];
        console.log(details.message, "===", error.response.data.message);
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: error.response.data.code,
          alertMessage:
            details && details.message
              ? details.message
              : error.response.data.message,
        }));
        setVisible((prevState) => ({
          ...prevState,
          mobileOtp: false,
          aadhaarOtp: false,
        }));
      } else {
        setSnackbarMessage(
          "ABHA Server down!!!",
          "The ABHA server is down at this time. Please try after sometime."
        );
        setShowSnackbar(true);
      }
    }
  };

  const verifyAadhaarOtpABHA = async () => {
    setShowLoading(true);
    try {
      const accessToken = await sessionStorage.getItem("accessToken");
      const txnId = await sessionStorage.getItem("authTransactionId");
      const otpDataBody = {
        txnId: txnId,
        otp: variables.otp,
        USER_ID: UserID,
        token: accessToken,
      };

      //console.log("otp databody", otpDataBody);

      const response = await Axios.post(
        `${Config.abhaBaseUrl}verifyAadhaarOtpABHA`,
        otpDataBody,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        }
      );

      // console.log(
      //   "response in verifyAadhaarOtpABHA",
      //   response,
      //   "status",
      //   response.status
      // );

      if (response.status === 422) {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: response.data.code,
          alertMessage:
            response.data.details.length > 0
              ? response.data.details[0].message
              : response.data.message,
        }));
        setSelectedOption("alreadyRegistered");
      } else if (response.status === 200) {
        sessionStorage.setItem("profileData", JSON.stringify(response.data));
        uploadAbhaData(response.data);
      } else {
        setSelectedOption("alreadyRegistered");
        setSnackbarMessage(
          "ABHA Server down!!!",
          "The ABHA server is down at this time. Please try after sometime."
        );
        setShowSnackbar(true);
      }
      setShowLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 422) {
        let details = error.response.data.details[0];
        console.log(details.message, "===", error.response.data.message);
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: error.response.data.code,
          alertMessage:
            details && details.message
              ? details.message
              : error.response.data.message,
        }));
      } else {
        setSnackbarMessage(
          "ABHA Server down!!!",
          "The ABHA server is down at this time. Please try after sometime."
        );
        setShowSnackbar(true);
      }
    }
  };

  const generateMobileOtpABHA = async (abhaId) => {
    try {
      const accessToken = await sessionStorage.getItem("accessToken");
      const dataBody = {
        abhaId: abhaId,
        USER_ID: UserID,
        token: accessToken,
      };

      const response = await Axios.post(
        `${Config.abhaBaseUrl}generateMobileOtpABHA`,
        dataBody,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        }
      );

      // console.log(
      //   "response in generateMobileOtpABHA",
      //   response,
      //   "status",
      //   response.status
      // );

      if (response.status === 422) {
        setVisible((prevState) => ({
          ...prevState,
          mobileOtp: false,
          aadhaarOtp: false,
        }));
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: response.data.code,
          alertMessage:
            response.data.details.length > 0
              ? response.data.details[0].message
              : response.data.message,
        }));
        setSelectedOption("alreadyRegistered");
      } else if (response.status === 200) {
        sessionStorage.setItem("authTransactionId", response.data.txnId);

        setVariables((prevState) => ({
          ...prevState,
          formType: 5,
        }));

        setVisible((prevState) => ({
          ...prevState,
          mobileOtp: true,
          aadhaarOtp: true,
        }));
      } else {
        setVisible((prevState) => ({
          ...prevState,
          mobileOtp: false,
          aadhaarOtp: false,
        }));
        setSelectedOption("alreadyRegistered");
        setSnackbarMessage(
          "ABHA Server down!!!",
          "The ABHA server is down at this time. Please try after sometime."
        );
        setShowSnackbar(true);
      }
    } catch (error) {
      // console.log(error);
      if (error.response && error.response.status === 422) {
        let details = error.response.data.details[0];
        console.log(details.message, "===", error.response.data.message);
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: error.response.data.code,
          alertMessage:
            details && details.message
              ? details.message
              : error.response.data.message,
        }));
        setVisible((prevState) => ({
          ...prevState,
          mobileOtp: false,
          aadhaarOtp: false,
        }));
      } else {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: "ABHA Server down!!!",
          alertMessage:
            "The ABHA server is down at this time. Please try after sometime.",
        }));
      }
    }
  };

  const verifyMobileOtpABHA = async () => {
    setShowLoading(true);
    try {
      const accessToken = await sessionStorage.getItem("accessToken");
      const txnId = await sessionStorage.getItem("authTransactionId");
      const otpDataBody = {
        txnId: txnId || "",
        otp: variables.otp,
        USER_ID: UserID,
        token: accessToken,
      };

      console.log("otp databody", otpDataBody);

      const response = await Axios.post(
        `${Config.abhaBaseUrl}verifyMobileOtpABHA`,
        otpDataBody,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        }
      );

      // console.log(
      //   "response in verifyMobileOtpABHA",
      //   response,
      //   "status",
      //   response.status
      // );

      if (response.status === 422) {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: response.data.code,
          alertMessage:
            response.data.details.length > 0
              ? response.data.details[0].message
              : response.data.message,
        }));
        setSelectedOption("alreadyRegistered");
      } else if (response.status === 200) {
        sessionStorage.setItem("profileData", JSON.stringify(response.data));
        uploadAbhaData(response.data);
      } else {
        setSelectedOption("alreadyRegistered");
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: "ABHA Server down!!!",
          alertMessage:
            "The ABHA server is down at this time. Please try after sometime.",
        }));
      }
      setShowLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 422) {
        let details = error.response.data.details[0];
        console.log(details.message, "===", error.response.data.message);
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: error.response.data.code,
          alertMessage:
            details && details.message
              ? details.message
              : error.response.data.message,
        }));
      } else {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: "ABHA Server down!!!",
          alertMessage:
            "The ABHA server is down at this time. Please try after sometime.",
        }));
      }
    }
  };
  //

  //register abha api calls
  const generateAadhaarOTP = async () => {
    console.log("aad", variables.aadhaarNumber);
    if (variables.aadhaarNumber.trim() !== "") {
      setShowLoading(true);
      await getPublickey();

      const dataBody = {
        aadhaar: variables.aadhaarNumber,
        USER_ID: UserID,
      };
      console.log(dataBody, "dataBody........generateAadhaarOTP", encrypted);
      await Axios.post(`${Config.abhaBaseUrl}generateAadhaarOTP`, dataBody, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
          "x-caller": encrypted,
        },
      })

        .then((response) => {
          console.log("response in generateAadhaarOTP:", response);
          if (response.status === 422) {
            setVariables((prevState) => ({
              ...prevState,
              showAlert: true,
              alertTitle: response.data.code,
              alertMessage:
                response.data.details.length > 0
                  ? response.data.details[0].message
                  : response.data.message,
            }));
            setSelectedOption("haventRegistered");
            setShowLoading(false);
          } else if (response.status === 200) {
            setVisible((prevState) => ({
              ...prevState,
              aadharNoOtp: true,
            }));
            setVariables((prevState) => ({
              ...prevState,
              formType: 2,
            }));
            setShowLoading(false);
            sessionStorage.setItem("authTransactionId", response.data.txnId);
            sessionStorage.setItem("accessToken", response.data.accessToken);
          } else {
            setVariables((prevState) => ({
              ...prevState,
              showAlert: true,
              alertTitle: "ABHA Server down!!!",
              alertMessage:
                "The ABHA server is down at this time. Please try after sometime.",
            }));
            setShowLoading(false);
          }
        })
        .catch(async (error) => {
          console.log(error);
          if (error.response && error.response.status === 422) {
            let details = error.response.data.details[0];
            console.log(details.message, "===", error.response.data.message);
            setVariables((prevState) => ({
              ...prevState,
              showAlert: true,
              alertTitle: error.response.data.code,
              alertMessage:
                details && details.message
                  ? details.message
                  : error.response.data.message,
            }));

            setShowLoading(false);
          } else {
            setVariables((prevState) => ({
              ...prevState,
              showAlert: true,
              alertTitle: "ABHA Server down!!!",
              alertMessage:
                "The ABHA server is down at this time. Please try after sometime.",
            }));
            setShowLoading(false);
          }
        });
    } else {
      setSnackbarMessage("Please enter a valid value");
      setShowSnackbar(true);
    }
  };

  const verifyAadhaarOTP = async () => {
    setShowLoading(true);
    try {
      const accessToken = await sessionStorage.getItem("accessToken");
      const transactionId = await sessionStorage.getItem("authTransactionId");

      const otpDataBody = {
        txnId: transactionId,
        otp: variables.otp,
        USER_ID: UserID,
        token: accessToken,
      };

      const response = await Axios.post(
        `${Config.abhaBaseUrl}verifyAadhaarOTP`,
        otpDataBody,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        }
      );

      if (response.status === 422) {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: "Attention !!",
          alertMessage: "Please enter valid OTP.",
        }));
        setVisible((prevState) => ({
          ...prevState,
          aadharNoOtp: true,
        }));
      } else if (response.status === 200) {
        //console.log("cmng to if");
        sessionStorage.setItem("transactionId", response.data.txnId);
        setVariables((prevState) => ({
          ...prevState,
          showAlert: false,
          alertTitle: "",
          alertMessage: "",
        }));
        setVisible((prevState) => ({
          ...prevState,
          MobileValidationScreen: true,
        }));
      } else {
        setSelectedOption("haventRegistered");
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: "ABHA Server down!!!",
          alertMessage:
            "The ABHA server is down at this time. Please try after sometime.",
        }));
        setShowLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        let details = error.response.data.details[0];
        console.log(details.message, "===", error.response.data.message);
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: error.response.data.code,
          alertMessage:
            details && details.message
              ? details.message
              : error.response.data.message,
        }));
      } else {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: "ABHA Server down!!!",
          alertMessage:
            "The ABHA server is down at this time. Please try after sometime.",
        }));
      }
    }
  };

  //

  const uploadAbhaData = async (abhaDataResponse) => {
    let abhaData = abhaDataResponse;
    //console.log("abhadata", abhaData, JSON.stringify(abhaData));
    const body = {
      USER_ID: UserID,
      PATIENT_ID: PATIENT_ID,
      ABHA_NUMBER: abhaData?.healthIdNumber,
      ABHA_ADDRESS: abhaData?.healthId,
    };
    //console.log("++++++", body);
    const response = await Axios.post(`${Config.apiURL}/me/ABHA_DATA`, body, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
        "x-caller": encrypted,
      },
    });

    //console.log("response in uplaod abha data", response);
    if (response.status === 201) {
      setVariables((prevState) => ({
        ...prevState,
        showAlert: true,
        alertTitle: "Success!!",
        alertMessage:
          "ABHA details of the current patient have been successfully fetched. Continue with patient registation process.",
        type: "uploadAbhaData",
      }));
    } else if (response.status === 404) {
      setVariables((prevState) => ({
        ...prevState,
        showAlert: true,
        alertTitle: "Sorry!!!",
        alertMessage:
          "ABHA Data is not linked with the Profile. Please submit again.",
      }));
    } else {
      setVariables((prevState) => ({
        ...prevState,
        showAlert: true,
        alertTitle: "Error!!!",
        alertMessage:
          "An unknown error occurred.Please try again after sometime.",
      }));
    }
  };

  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };

  const handleInputChange = (e, ctrlName) => {
    const { value } = e.target;

    if (ctrlName === "aadhaarNumber") {
      // Check if the input is a number
      if (/^\d*$/.test(value)) {
        setVariables({
          ...variables,
          [ctrlName]: value,
        });
      }
    } else if (ctrlName === "abhaNo") {
      // Allow any characters for ABHA No/ABHA Address
      setVariables({
        ...variables,
        [ctrlName]: value,
      });
    }
  };

  const handleCancel = () => {
    navigate("/patients");
  };

  const handleProceed = async () => {
    setShowLoading(true);

    //("formtype in switch case", variables.formType);

    switch (variables.formType) {
      case 1:
        // await validateOTP();
        break;
      case 2:
        await verifyAadhaarOTP();
        break;
      case 4:
        await verifyAadhaarOtpABHA();
        break;
      case 5:
        await verifyMobileOtpABHA();
        break;
      case 6:
        // await validatePHROTP();
        break;
      default:
    }
    setShowLoading(false);
  };

  function getMaskedText(value) {
    // console.log("value n masked text", value)
    if (value.length === 12) {
      const newValue = value.replace(value.substring(0, 8), "********");
      // console.log("Masked Value :", newValue);
      return newValue;
    } else {
      return value;
    }
  }

  //api for create abha address using the mobile number

  const handleMobileChange = (event) => {
    const value = event.target.value;
    if (/^\d{0,10}$/.test(value)) {
      setVariables({
        ...variables,
        phrMobile: value,
      });
    }
  };

  // Encryption of mobile number  using JSEncrypt
  const mobileEncryption = async (mobile) => {
    // console.log("mobile",mobile);
    var data = mobile;
    var key = sessionStorage.getItem("PHRPublicKey");
    console.log(key,"key...........");
    var jsencrypt = new JSEncrypt();
    console.log(jsencrypt,"jsencrypt");
    jsencrypt.setPublicKey(key);
    // console.log(jsencrypt.setPublicKey(key),"jsencrypt.setPublicKey(key)");
    var encrypt = jsencrypt.encrypt(data);
    console.log("encrypted mobile :", encrypt);
    return encrypt;
  };

  const getPhrPublicKey = async () => {
    console.log(UserID,"UserID..............");
    Axios.get(`${Config.abhaBaseUrl}getPhrPublicKey/${UserID}`, {
      headers: {
        authorization: `Bearer ${token}`,
        "x-caller": encrypted,
      },
    })
      .then((response) => {
        //console.log("PHRPublicKey :", response.data);
        if (response.status === 200) {
          sessionStorage.setItem("PHRPublicKey", response.data);
          return response;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  /**************************/
  console.log(variables.phrMobile,"variables.phrMobile");
  
  // const testingLinkabhaMobile = async () => {
  //   // await getPhrPublicKey();
  //   if (variables.phrMobile.trim() !== "") {
  //     setShowLoading(true);

//       const dataBody = {
//         mobile: variables.phrMobile
//       };
// console.log(dataBody,"dataBody.......");
//       sessionStorage.setItem("mobile", variables.phrMobile);
//       console.log("generatePHRMobileOTP", dataBody);

//       await Axios.post(`${Config.abhaBaseUrl}clinally/v3/generateMobileOtp`, dataBody, {
//         headers: {
//           "Content-Type": "application/json",
//           authorization: `Bearer ${token}`,
//           "x-caller": encrypted,
//         },
//       })

//         .then((response) => {
//           console.log("response in generatePHRMobileOTP:", response);
//           setShowLoading(false);
//         })
//         .catch(async (error) => {
//           console.log(error);
//           if (
//             error.response &&
//             (error.response.status === 422 || error.response.status === 400)
//           ) {
//             let details = "error.response.data.details[0]";
//             console.log(details.message, "===", error.response.data.message);

            // if (
            //   details.message ===
            //   "Request is invalid. Please enter the correct data."
            // ) {
            //   setVariables((prevState) => ({
            //     ...prevState,
            //     showAlert: true,
            //     alertTitle: "",
            //     alertMessage:
            //       "Please re-enter your mobile number.",
            //   }));
            // } else {
            //   setVariables((prevState) => ({
            //     ...prevState,
            //     showAlert: true,
            //     alertTitle: error.response.data.code || error.response.data[0].code,
            //     alertMessage:
            //       details && details.message
            //         ? details.message
            //         : error.response.data.code || error.response.data[0].message,
            //   }));
            // }

//             setShowLoading(false);
//           } else {
//             setVariables((prevState) => ({
//               ...prevState,
//               showAlert: true,
//               alertTitle: "ABHA Server down!!!",
//               alertMessage:
//                 "The ABHA server is down at this time. Please try after sometime.",
//             }));
//             setShowLoading(false);
//           }
//         });
//     } else {
//       setSnackbarMessage("Please enter a valid value");
//       setShowSnackbar(true);
//     }
//   };
  /**************************/

  const generatePHRMobileOTP = async () => {
    await getPhrPublicKey();
    console.log(getPhrPublicKey(),"getPhrPublicKey()");
    if (variables.phrMobile.trim() !== "" )  {
      setShowLoading(true);

      const dataBody = {
        encryptedMobile: await mobileEncryption(variables.phrMobile),
        USER_ID: UserID,
      };

      sessionStorage.setItem("mobile", variables.phrMobile);
      console.log("generatePHRMobileOTP", dataBody);

      await Axios.post(`${Config.abhaBaseUrl}generatePHRMobileOTP`, dataBody, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
          "x-caller": encrypted,
        },
      })

        .then((response) => {
          console.log("response in generatePHRMobileotp:", response);
          if (response.status === 422 || response.status === 400) {
            setVariables((prevState) => ({
              ...prevState,
              showAlert: true,
              alertTitle: response.data.code,
              alertMessage:
                response.data.details.length > 0
                  ? response.data.details[0].message
                  : response.data.message,
            }));
          } else if (response.status === 200) {
            sessionStorage.setItem(
              "transactionIdPhr",
              response.data.transactionId
            );
            sessionStorage.setItem("phraccessToken", response.data.accessToken);
            setVisible((prevState) => ({
              ...prevState,
              mobileOtpValidationForMobileScreen: true,
            }));
          } else {
            setVariables((prevState) => ({
              ...prevState,
              showAlert: true,
              alertTitle: "ABHA Server down!!!",
              alertMessage:
                "The ABHA server is down at this time. Please try after sometime.",
            }));
          }
          setShowLoading(false);
        })
        .catch(async (error) => {
          console.log(error);
          if (
            error.response &&
            (error.response.status === 422 || error.response.status === 400)
          ) {
            let details = error.response.data.details[0];
            console.log(details.message, "===", error.response.data.message);

            if (
              details.message ===
              "Request is invalid. Please enter the correct data."
            ) {
              setVariables((prevState) => ({
                ...prevState,
                showAlert: true,
                alertTitle: "",
                alertMessage:
                  "Please re-enter your mobile number.",
              }));
            } else {
              setVariables((prevState) => ({
                ...prevState,
                showAlert: true,
                alertTitle: error.response.data.code,
                alertMessage:
                  details && details.message
                    ? details.message
                    : error.response.data.message,
              }));
            }

            setShowLoading(false);
          } else {
            setVariables((prevState) => ({
              ...prevState,
              showAlert: true,
              alertTitle: "ABHA Server down!!!",
              alertMessage:
                "The ABHA server is down at this time. Please try after sometime.",
            }));
            setShowLoading(false);
          }
        });
    }
    else {
      setSnackbarMessage("Please enter a valid value");
      setShowSnackbar(true);
    }
  };

  return (
    <>
      {/* <Navbar /> */}
      <div className="homePatient">
        <div className="homeBackground">
          <img src={backgroundCircle} alt="" />
        </div>
        <>
          <div className="abhadetails">
            <div
              className="homeBodyLeft"
              style={{
                display: "flex",
                gap: "1rem",
                flexDirection: "column",
                width: "50%",
              }}
            >
              <p className="ABHAHeading"> Link ABHA </p>
              <div className="customContainer1">
                {/* <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "2rem",
                        padding: "0px 100px",
                      }}
                    >
                      <p style={{ display: "flex", paddingTop: "15px" }}>
                        What is ABHA?
                      </p>
                      <img src={NHA} />
                    </div> */}
                <div className="abhaText">
                  ABHA - Ayushman Bharat Health Account
                </div>

                <p className="abhacontent">
                  ABHA (Ayushman Bharat Health Account) is a unique health ID
                  that uses a 14-digit identification number and can be
                  generated using an Aadhaar card or your mobile number. It
                  allows users to share their health records digitally with
                  hospitals, clinics, insurance providers and others. Citizens
                  can create their unique ABHA without any cost.
                </p>
              </div>
              <div className="abhaRadio">
                <div className="radioBtnTextGap">
                  <input
                    type="radio"
                    value="alreadyRegistered"
                    checked={selectedOption === "alreadyRegistered"}
                    onChange={handleOptionChange}
                    style={{ cursor: "pointer" }}
                  />
                  {/* <label>Already Registered with ABHA</label> */}
                  <label>Already Have ABHA</label>
                </div>
                <div className="radioBtnTextGap">
                  <input
                    type="radio"
                    value="haventRegistered"
                    checked={selectedOption === "haventRegistered"}
                    onChange={handleOptionChange}
                    style={{ cursor: "pointer" }}
                  />
                  {/* <label>Haven’t Registered with ABHA</label> */}
                  <label>Don't Have ABHA</label>
                </div>
                <div className="radioBtnTextGap">
                  <input
                    type="radio"
                    value="createABHA"
                    checked={selectedOption === "createABHA"}
                    onChange={handleOptionChange}
                    style={{ cursor: "pointer" }}
                  />
                  <label>Create ABHA Address with Mobile</label>
                </div>
              </div>
            </div>

            {visible.MobileValidationScreen &&
            selectedOption === "haventRegistered" ? (
              <>
                {selectedOption === "haventRegistered" && (
                  <AbhaMobileValidation />
                )}
              </>
            ) : visible.mobileOtpValidationForMobileScreen &&
              selectedOption === "createABHA" ? (
              <>
                {selectedOption === "createABHA" && (
                  <AbhaAddressSetUpPage
                    activeSectionInput={activeSection}
                    onActiveSectionChange={onActiveSectionChange}
                  />
                )}
              </>
            ) : (
              <div className="abhaContainer" style={{ width: "50%" }}>
                <>
                  <div className="abhaContainerLeft">
                    {!visible.mobileOtp &&
                      !visible.aadhaarOtp &&
                      !visible.aadharNoOtp && (
                        <p className="abhaTtile">
                          {" "}
                          Ayushman Bharat Health Account
                        </p>
                      )}

                    {selectedOption === "alreadyRegistered" &&
                      !visible.mobileOtp &&
                      !visible.aadhaarOtp && (
                        <>
                          <p className="content">
                            Have ABHA Number, Set up your profile using ABHA
                            Number
                          </p>
                          <div className="Abhanumfield">
                            {selectedOption === "aadharNumber" ? (
                              <>
                                <div>
                                  <input type="number" maxLength={12} />
                                </div>
                              </>
                            ) : (
                              <>
                                <input
                                  style={{
                                    display: "block",
                                    width: "70%",
                                  }}
                                  className="abhanoInfoInput"
                                  type="text"
                                  placeholder="Enter ABHA No/ABHA Address"
                                  value={variables.abhaNo}
                                  onChange={(e) =>
                                    handleInputChange(e, "abhaNo")
                                  }
                                />
                              </>
                            )}
                          </div>

                          {mobileOtpButton && aadhaarOtpButton ? (
                            <>
                              <div className="Adharandmobile">
                                <div
                                  className={"editButton2"}
                                  style={{
                                    width: "30%",
                                    margin: "0 auto",
                                    height: "40px",
                                  }}
                                  onClick={() =>
                                    handleButtonClick("aadhaarOtp")
                                  }
                                >
                                  {visible.loading === "aadhaarOtp" && (
                                    <CircularProgress
                                      size={24}
                                      color="inherit"
                                    />
                                  )}
                                  <p className="OtpBtn">AADHAAR OTP</p>
                                </div>
                                <div
                                  className={"editButton2"}
                                  style={{
                                    width: "30%",
                                    margin: "0 auto",
                                    height: "40px",
                                  }}
                                  onClick={() => handleButtonClick("mobileOtp")}
                                >
                                  {visible.loading === "mobileOtp" && (
                                    <CircularProgress
                                      size={24}
                                      color="inherit"
                                    />
                                  )}
                                  <p className="OtpBtn">MOBILE OTP</p>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div
                              className={"editButton2"}
                              style={{
                                width: "30%",
                                margin: "0 auto",
                                height: "40px",
                              }}
                              onClick={getSessionTokenAPI}
                            >
                              {showLoading && (
                                <CircularProgress size={24} color="inherit" />
                              )}
                              <p className="OtpBtn">INTEGRATE</p>
                            </div>
                          )}
                        </>
                      )}

                    {variables.showAlert && (
                      <ShowAlert
                        title={variables.alertTitle}
                        message={variables.alertMessage}
                        onClose={handleAlertClose}
                      />
                    )}

                    {selectedOption === "alreadyRegistered" &&
                      (visible.mobileOtp || visible.aadhaarOtp) && (
                        <>
                          <h2 style={{ textAlign: "center" }}>
                            ABHA OTP REQUEST
                          </h2>
                          <h3 style={{ textAlign: "center" }}>
                            Verify OTP to access your ABHA Account
                          </h3>
                          {variables.abhaNo && (
                            <p>
                              Enter the code sent to Mobile number which is
                              linked with this ABHA Address/Number
                              <h4
                                style={{
                                  color: "blue",
                                  display: "inline",
                                  marginLeft: "5px",
                                  marginRight: "5px",
                                }}
                              >
                                {variables.abhaNo}
                              </h4>
                            </p>
                          )}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <OtpInput
                              value={variables.otp}
                              onChange={handleChange}
                              numInputs={6}
                              renderSeparator={<span>{""}</span>}
                              renderInput={(props) => (
                                <input {...props} type="tel" />
                              )}
                              skipDefaultStyles={true}
                              inputStyle="otp-input"
                              inputType="number"
                            />
                          </div>

                          <div className="Adharandmobile">
                            <button
                              className={"editButton2"}
                              style={{
                                marginRight: "10px",
                                width: "30%",
                                margin: "0 auto",
                                height: "40px",
                                color: "white",
                              }}
                              onClick={handleCancel}
                            >
                              Cancel
                            </button>
                            <button
                              className={"editButton2"}
                              style={{
                                marginRight: "10px",
                                width: "30%",
                                margin: "0 auto",
                                height: "40px",
                                color: "white",
                              }}
                              onClick={handleProceed}
                            >
                              {showLoading && (
                                <CircularProgress size={24} color="inherit" />
                              )}
                              Proceed
                            </button>
                          </div>
                        </>
                      )}

                    {selectedOption === "haventRegistered" &&
                      !visible.aadharNoOtp && (
                        <>
                          <p className="content">
                            Don't have ABHA Number,Register your ABHA number
                            using your Aadhaar Number
                          </p>
                          <div className="Abhanumfield">
                            <input
                              style={{
                                display: "block",
                                width: "70%",
                              }}
                              className="abhanoInfoInput"
                              type="text"
                              maxLength="12"
                              placeholder="Enter Aadhaar Number"
                              value={variables.aadhaarNumber}
                              onChange={(e) =>
                                handleInputChange(e, "aadhaarNumber")
                              }
                            />
                          </div>

                          <div
                            className={"editButton2"}
                            style={{
                              width: "30%",
                              margin: "0 auto",
                              height: "40px",
                            }}
                            onClick={generateAadhaarOTP}
                          >
                            {showLoading && (
                              <CircularProgress size={24} color="inherit" />
                            )}
                            <p className="OtpBtn">Register</p>
                          </div>
                        </>
                      )}

                    {selectedOption === "haventRegistered" &&
                      visible.aadharNoOtp && (
                        <>
                          <h2 style={{ textAlign: "center" }}>
                            ABHA OTP REQUEST
                          </h2>
                          <h3 style={{ textAlign: "center" }}>
                            Verify OTP to access your ABHA Account
                          </h3>
                          {variables.aadhaarNumber && (
                            <p>
                              Enter the code sent to Mobile number linked to
                              this Aadhaar Number
                              <h4
                                style={{
                                  color: "blue",
                                  display: "inline",
                                  marginLeft: "5px",
                                  marginRight: "5px",
                                }}
                              >
                                {getMaskedText(variables.aadhaarNumber)}
                              </h4>
                            </p>
                          )}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <OtpInput
                              value={variables.otp}
                              onChange={handleChange}
                              numInputs={6}
                              renderSeparator={<span>{""}</span>}
                              renderInput={(props) => (
                                <input {...props} type="tel" />
                              )}
                              skipDefaultStyles={true}
                              inputStyle="otp-input"
                              inputType="number"
                            />
                          </div>

                          <div className="Adharandmobile">
                            <button
                              className={"editButton2"}
                              style={{
                                marginRight: "10px",
                                width: "30%",
                                margin: "0 auto",
                                height: "40px",
                                color: "white",
                              }}
                              onClick={handleCancel}
                            >
                              Cancel
                            </button>
                            <button
                              className={"editButton2"}
                              style={{
                                marginRight: "10px",
                                width: "30%",
                                margin: "0 auto",
                                height: "40px",
                                color: "white",
                              }}
                              onClick={handleProceed}
                            >
                              {showLoading && (
                                <CircularProgress size={24} color="inherit" />
                              )}
                              Proceed
                            </button>
                          </div>
                        </>
                      )}

                    {selectedOption === "createABHA" && (
                      <>
                        <p className="content">
                          Create your ABHA Address using your Mobile Number
                        </p>
                      { /* <div className="Abhanumfield">
                          <input
                            style={{
                              display: "block",
                              width: "70%",
                            }}
                            className="abhanoInfoInput"
                            type="text"
                            maxLength="10"
                            placeholder="Enter Mobile Number"
                            value={variables.phrMobile}
                            onChange={handleMobileChange}
                          />
                        </div>

                        <div
                          className={"editButton2"}
                          style={{
                            width: "30%",
                            margin: "0 auto",
                            height: "40px",
                          }}
                          onClick={generatePHRMobileOTP}
                        >
                          <p className="OtpBtn">CREATE</p>
                        </div>*/}
                        {/*Another format for Mobile number*/}
                         <div className="Abhanumfield">
                          <input
                            style={{
                              display: "block",
                              width: "70%",
                            }}
                            className="abhanoInfoInput"
                            type="text"
                            maxLength="10"
                            placeholder="Enter Mobile Number"
                            value={variables.phrMobile}
                            onChange={handleMobileChange}
                          />
                        </div>

                        <div
                          className={"editButton2"}
                          style={{
                            width: "30%",
                            margin: "0 auto",
                            height: "40px",
                          }}
                          onClick={generatePHRMobileOTP}
                        >
                          <p className="OtpBtn">CREATE</p>
                        </div>
                      </>
                    )}

                    {!visible.mobileOtp && !visible.aadhaarOtp && (
                      <>
                        <p
                          className="SkipAbhatext"
                          style={{ fontSize: "16px", fontWeight: "600" }}
                          onClick={skipABHA}
                        >
                          Skip ABHA
                        </p>

                        <div className="NHAandimage">
                          <p className="nhaText" style={{ paddingTop: "10px" }}>
                            APPROVED BY NHA
                          </p>
                          <img src={NHA} />
                        </div>
                      </>
                    )}
                  </div>
                </>
              </div>
            )}
          </div>
        </>

        <ToastContainer />

        <Snackbar
          open={showSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="warning"
            sx={{ width: "100%", bgcolor: "#FDAD00", color: "white" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default ABHAIntegration;
