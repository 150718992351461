import React, { useState, useEffect } from "react";
import axios from "axios";
import "./MISReports.css";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Config from "../../Config/config";
import { publicEncrypt, constants } from "crypto-browserify";
import dayjs from "dayjs";
import PreviewMIS from "../PreviewMIS";

const MISReports = () => {
  const [hospitalList, sethospitalList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [followupsModelList, setFollowupsModelList] = useState([]);
  const [patientVisited, setpatientVisited] = useState([]);
  const [newPatientModelList, setNewPatientModelList] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const today = dayjs();
  const [openPreview, setOpenPreview] = useState(false);

  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");

  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));
  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : JSON.parse(sessionStorage.getItem("token"));

  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");

  useEffect(() => {
    listofHospital();
  }, []);

  useEffect(() => {
    if (selectedValue) {
      console.log("sdfdsfdsf", selectedValue);
      getThisMonthPatientList();
      getPatientFollowups();
      getNewPatientList();
    }
  }, [selectedValue, setpatientVisited]);
  const listofHospital = async () => {
    const authentication = "Basic " + btoa("mpoweruser:Cl!nA11y123!@#");
    var options = {
      method: "GET",
      url: `${Config.apiURL}${Config.routes.userHospitalsAPI}/${UserID}`,
      headers: {
        authorization: authentication,
        "Content-Type": "application/json",
        "x-caller": encrypted,
      },
    };

    axios
      .request(options)
      .then(function (response) {
        console.log(response.data.data, "gettingdefaultlink");
        sethospitalList(response.data.data);
        setSelectedValue(response?.data?.data?.[0]?.HOSPITAL_NAME);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const getThisMonthPatientList = async () => {
    const authentication = "Basic " + btoa("mpoweruser:Cl!nA11y123!@#");

    // Prepare request options
    const options = {
      method: "GET",
      url: `${Config.apiURL}/patient/allpatients/patients`,
      params: {
        userid: UserID,
        hospital_name: selectedValue,
      },
      headers: {
        authorization: authentication,
        "Content-Type": "application/json",
        "x-caller": encrypted,
      },
    };

    axios
      .request(options)
      .then(function (response) {
        if (response.status === 200) {
          console.log(response, "getThisMonthPatientList");
          setpatientVisited(response.data.data);
        }
      })
      .catch(function (error) {
        console.error("Error fetching patient list:", error);
      });
  };

  const getNewPatientList = async () => {
    const authentication = "Basic " + btoa("mpoweruser:Cl!nA11y123!@#");
    const queryParameters = {
      userid: UserID,
      hospital_name: selectedValue,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: authentication,
      "x-caller": encrypted,
    };
    const options = {
      method: "GET",
      url: `${Config.apiURL}/patient/allpatients/newpatients`,
      params: queryParameters,
      headers: headers,
    };
    try {
      const response = await axios.request(options);

      if (response.status === 200) {
        console.log(response, "newpatients");
        setNewPatientModelList(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching new patient list:", error);
    }
  };

  const getPatientFollowups = async () => {
    const authentication = "Basic " + btoa("mpoweruser:Cl!nA11y123!@#");
    const options = {
      method: "GET",
      url: `${Config.apiURL}/patient/followups/thismonth`,
      params: {
        userid: UserID,
      },
      headers: {
        authorization: authentication,
        "Content-Type": "application/json",
        "x-caller": encrypted,
      },
    };

    try {
      // Make the HTTP request
      const response = await axios.request(options);

      if (response.status === 200) {
        console.log("Came to getPatient Follow ups");

        console.log(response, "getPatientFollowups");

        setFollowupsModelList(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching patient follow-ups:", error);
    }
  };

  const getToDatePatientList = async () => {
    try {
      const authentication = "Basic " + btoa("mpoweruser:Cl!nA11y123!@#");

      const headers = {
        "Content-Type": "application/json",
        Authorization: authentication,
        "x-caller": encrypted,
      };

      // Prepare query parameters
      const queryParameters = {
        userid: UserID,
        hospital_name: selectedValue,
        start_date: fromDate,
        end_date: toDate,
      };
      console.log(queryParameters, "queryParameters", fromDate, toDate);

      const response = await axios.get(
        `${Config.apiURL}/patient/allpatients/todate`,
        {
          params: queryParameters,
          headers: headers,
        }
      );

      if (response.status === 200) {
        console.log(response.data.data, "getToDatePatientList");
        setpatientVisited(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching patient list from and to date:", error);
    }
  };

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
    console.log("Selected Hospital:", event.target.value);
  };

  const handlePreviewClick = () => {
    setOpenPreview(true);
    savePrescription();
  };

  const closePopup = () => {
    setOpenPreview(false);
  };

  const savePrescription = async (inputFile, visitId, patientId) => {
    try {
      console.log("inputFile-------:", inputFile);

      const authentication = "Basic " + btoa("mpoweruser:Cl!nA11y123!@#");

      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: authentication,
        "x-caller": encrypted,
      };

      // Prepare the form data for the request
      const formData = new FormData();
      formData.append("prescription", inputFile, `prescription_${visitId}.pdf`);
      formData.append(
        "data",
        JSON.stringify({
          USER_ID: UserID,
          PATIENT_ID: patientId,
          VISIT_ID: visitId,
        })
      );

      // Make the POST request
      const response = await axios.post(
        `${Config.base_url}upload_generated_prescription`,
        formData,
        { headers }
      );

      // Handle the response
      if (response.status === 201) {
        console.log(response.data, "Prescription uploaded successfully");
      } else if (response.status === 404) {
        console.log(response.data, "Resource not found");
      } else {
        console.log(response.data, "Other response");
      }
    } catch (error) {
      console.error("Error uploading prescription:", error);
    }
  };

  return (
    <>
      <div className="profileRightContainer">
        <div className="HospitalsListMisH">
          <div className="HospitalsListMis">
            <div className="Misheading">
              <h4>MIS Report</h4>
              <button
                onClick={handlePreviewClick}
                style={{ marginRight: "2%" }}
                className="misSearchDate"
              >
                Preview
              </button>
            </div>

            <div className="misSearch">
              <div className="addp-inputField_mis">
                <select
                  className="misSelect"
                  value={selectedValue}
                  onChange={handleSelectChange}
                >
                  {hospitalList.map((hospital, index) => (
                    <option key={index} value={hospital.HOSPITAL_NAME}>
                      {hospital.HOSPITAL_NAME}
                    </option>
                  ))}
                </select>
              </div>
              <div className="misDate">
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div style={{ width: "49%" }} className="addp-inputField">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={fromDate}
                        onChange={(newDate) => setFromDate(newDate)}
                        maxDate={today} // Disable future dates
                        sx={{
                          borderRadius: "10px",
                          height: "45px",
                          justifyContent: "center",
                        }}
                        renderInput={(params) => (
                          <input
                            {...params}
                            className="addp-patientsInputField"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>

                  <div style={{ width: "49%" }} className="addp-inputField">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={toDate}
                        onChange={(newDate) => setToDate(newDate)}
                        maxDate={today} // Disable future dates
                        sx={{
                          borderRadius: "10px",
                          height: "45px",
                          justifyContent: "center",
                        }}
                        renderInput={(params) => (
                          <input
                            {...params}
                            className="addp-patientsInputField"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
              <button className="misSearchDate" onClick={getToDatePatientList}>
                Search
              </button>
            </div>
            <div className="misdiv">
              <div className="patientcountmis">
                <div className="patientvisitHeading">
                  <p style={{ color: "#52447D", fontSize: "12px" }}>
                    Patients Visited
                  </p>
                </div>
                <div>
                  <h1 className="countstyle">
                    {patientVisited ? patientVisited.length : 0}
                    {/*console.log(patientVisited.length, "patientVisitedcount")*/}
                  </h1>
                </div>
              </div>
              <div className="patientcountmis">
                <div className="patientvisitHeading">
                  <p style={{ color: "#52447D", fontSize: "12px" }}>
                    New Patients Visited
                  </p>
                </div>
                <div>
                  <h1 className="countstyle">{newPatientModelList.length}</h1>
                </div>
              </div>
              <div className="patientcountmis">
                <div className="patientvisitHeading">
                  <p style={{ color: "#52447D", fontSize: "12px" }}>
                    Follow Ups
                  </p>
                </div>
                <div>
                  <h1 className="countstyle">
                    {followupsModelList ? followupsModelList.length : 0}
                  </h1>
                </div>
              </div>
            </div>
            {openPreview &&
              newPatientModelList &&
              patientVisited &&
              followupsModelList && (
                <>
                  <div className="mispopup">
                    <div className="mispopup-inner">
                 {/*     <button
                        className="misSearchDateclose"
                        onClick={closePopup}
                      >
                        Close
                      </button>*/}
                      <PreviewMIS
                        newPatientModelList={newPatientModelList}
                        patientVisited={patientVisited}
                        followupsModelList={followupsModelList}
                        closePopup={closePopup}
                      />
                    </div>
                  </div>
                </>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MISReports;
