import React, { useEffect, useState } from "react";
import downArrow from "../../../images/downArrow.svg";
import QuestionsAnswers from "../QuestionsAnswers";
import { Button, CircularProgress } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import "./DrugHistoryTab.jsx"
import Config from "../../../Config/config";
import axios from 'axios';
import { publicEncrypt, constants } from "crypto-browserify";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Buffer } from "buffer";
import "./MedicalTab.css";


const MedicalTab = ({ activeSection, toggleSection1 }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [otherInput, setOtherInput] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");

  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));

  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : JSON.parse(sessionStorage.getItem("token"));

  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");

  const PATIENT_ID = JSON.parse(sessionStorage.getItem("PATIENT_ID"));
  // console.log("patieeeeeeeeeee", PATIENT_ID)

  const selectedPatientHistory = sessionStorage.getItem("selectedPatientHistory") !=='undefined' ? JSON.parse(sessionStorage.getItem("selectedPatientHistory")) : []
  // console.log("selectedPatientHistory----------", selectedPatientHistory)

  function formatVisitDate(visitDate) {
    // Parse the initial visit date string into a Date object
    const initialDate = new Date(visitDate);

    // Extract the date and time components
    const year = initialDate.getFullYear();
    const month = String(initialDate.getMonth() + 1).padStart(2, '0');
    const day = String(initialDate.getDate()).padStart(2, '0');
    const hours = String(initialDate.getHours()).padStart(2, '0');
    const minutes = String(initialDate.getMinutes()).padStart(2, '0');
    const seconds = String(initialDate.getSeconds()).padStart(2, '0');
    const milliseconds = String(initialDate.getMilliseconds()).padStart(5, '0');

    // Construct the formatted visit date string
    const formattedVisitDateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
    //console.log("======----formated", formattedVisitDateString)

    return formattedVisitDateString;
  }


  const VISITDATA = {
    "patientId": selectedPatientHistory.PATIENT_ID,
    "mobile": selectedPatientHistory.MOBILE,
    "nurseId": selectedPatientHistory.NURSE_USER_ID,
    "doctorId": selectedPatientHistory.DOCTOR_USER_ID,
    "visitId": selectedPatientHistory.VISIT_ID,
    "visitDate": formatVisitDate(selectedPatientHistory.VISIT_DATE),
    "medicalHistory": {
      "isDiabetes": null,
      "isHypertension": null,
      "isCKD": null,
      "isLiverFailure": null,
      "isCOPD": null,
      "isAsthma": null,
      "isHeartDisease": null,
      "isStroke": null,
      "isPVD": null,
      "isHeartAttack": null,
      "isFamilyHypertension": null,
      "isFamilyDiabetes": null,
      "isFamilyCardioVascular": null,
      "isFamilyOtherDisease": null,
      "cheifComplaint": [],
      "otherChiefComplaint": null
    },
    "drugHistory": {
      "isHypertensiveDrugs": null,
      "isDiabeticDrugs": null,
      "isLipidDrugs": null,
      "isAsprin": null,
      "isInsulin": null,
      "currentHTDrugs": [],
      "currentDBDrugs": [],
      "currentInsulin": [],
      "currentAsprin": [],
      "currentLipid": []
    },
    "personalHistory": {
      "isPhysicalActivity": null,
      "isSmoking": null,
      "isDrinking": null,
      "drinkingFrequency": null,
      "isFamilyHistoryOfMI": null,
      "isPolyuria": null,
      "isPolydypsia": null,
      "isWeightloss": null,
      "isOnDialysis": null
    },
    "examinationHistory": {
      "sbp": null,
      "dbp": null,
      "heartRate": null,
      "sbp2": null,
      "dbp2": null,
      "sbp3": null,
      "dbp3": null,
      "averageSBP": null,
      "averageDBP": null,
      "fbs": null,
      "ppbs": null,
      "rbs": null,
      "hba1c": null,
      "waist": null,
      "height": null,
      "weight": null,
      "bmi": null,
      "ldl": null,
      "creatinine": null,
      "cholestrol": null,
      "isEdema": null,
      "isBreathlessness": null,
      "isChestPain": null,
      "isDeficit": null,
      "isAlteredConciousness": null,
      "isFundus": null,
      "isGeneralizedSeizures": null,
      "gfr": null
    },
    "htDiagnosis": {
      "diagnosis": null,
      "control": null,
      "recommendation": null,
      "notes": null,
      "followup": null,
      "doctorAcceptance": null,
      "doctorNotes": null,
      "doctorId": null,
      "systemDecision": null
    },
    "dbDiagnosis": {
      "diagnosis": null,
      "control": null,
      "recommendation": null,
      "notes": null,
      "followup": null,
      "doctorAcceptance": null,
      "doctorNotes": null,
      "doctorId": null,
      "systemDecision": null
    },
    "copdDiagnosis": [],
    "tbDiagnosis": [],
    "copdHistory": {
      "isCopd": null,
      "isProgressiveDyspnoea": null,
      "isChronicCough": null,
      "isChronicSputum": null,
      "isChestPain": null,
      "isFever": null,
      "isWeightLoss": null,
      "isOrthopnoea": null,
      "isParoxysmalNocturnal": null,
      "isHaemoptysis": null,
      "isSpirometeryTest": null,
      "spirometeryValue": null,
      "isSmoking": null,
      "isBiomassFuel": null,
      "isPollution": null,
      "mmrcGrade": null,
      "isDifficultInBreathing": null,
      "isExacerbation": null,
      "isAdmitHospitalWithExacerbation": null,
      "severeBreathingDifficulty": null,
      "exacerbationSymptoms": [],
      "severeExacerbationSymptoms": [],
      "drugOptions": []
    },

    "tbHistory": {
      "confirmedTBType": [],
      "presumptiveTBType": [],
      "suspectedTBSymptoms": [],
      "suspectedComorbidities": [],
      "suspectedOtherTBDrugs": [],
      "tbPreviousDrugList": [],
      "tbCurrentDrugList": [],
      "tbAdditionalDrugList": []
    },
    "osteoArthritisHistory": {
      "oaAComorbidList": [],
      "oaPharmaList": [],
      "oaLifestyleList": [],
      "oaNonPharmaList": []
    },
    "orderTest": {
      "clinicalPathology": [],
      "radiology": [],
      "bioChemistry": [],
      "stoolAnalysis": [],
      "serology": [],
      "urineAnalysis": [],
      "cardiology": []
    }
  };

  const [medicalHistory, setmedicalHistory] = useState(VISITDATA.medicalHistory);
  const [visitData, setVisitData] = useState();
  const [filteredRecord, setFilteredRecord] = useState();

  const VISIT_ID = sessionStorage.getItem("VISIT_ID")

  useEffect(() => {
    const fetchDataAndSetMedicalHistory = async () => {
      setIsLoading(true);
      try {
        let data = JSON.stringify({
          userId: UserID,
          id: PATIENT_ID,
        });

        //console.log("data in visits", data)

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${Config.apiURL}${Config.routes.getPatientVisitsAPI}`,
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "x-caller": encrypted,
          },
          data: data,
        };

        const response = await axios.request(config);
        // console.log("Response from API:", response, response.data.data);

        const sessionVisitID = parseInt(VISIT_ID, 10);

        const filteredRecords = response.data.data.filter(record => {
          return record.VISIT_ID === sessionVisitID;
        });
        //console.log("Filtered Records:", filteredRecords, typeof VISIT_ID);
        setFilteredRecord(filteredRecords)


        // if (response.data.data === null || response.data.data.length === 0 || response.data.data[0].VISIT_DATA === null) {
        //   // console.log("cmng to if")
        //   setVisitData(VISITDATA);
        // }

        if (filteredRecords[0].VISIT_DATA === null) {
          //console.log("=in useeffect if=")
          setVisitData(VISITDATA);
        }


        else {
          // let res = await response.data.data[0].VISIT_DATA
          let res = await filteredRecords[0].VISIT_DATA
          //console.log("---res----", res)

          let MedicalHistoryData = res ? JSON.parse(res).medicalHistory : null
          // console.log("data===", data)

          let parsedRes = !!res ? JSON.parse(res) : null
          //S console.log("---parsedRes----", parsedRes)
          setVisitData(parsedRes)

          if (MedicalHistoryData !== null) {
            const parsedMedicalData = JSON.parse(MedicalHistoryData)

            setmedicalHistory({
              ...medicalHistory,
              isDiabetes: parsedMedicalData.isDiabetes,
              isHypertension: parsedMedicalData.isHypertension,
              isCKD: parsedMedicalData.isCKD,
              isLiverFailure: parsedMedicalData.isLiverFailure,
              isCOPD: parsedMedicalData.isCOPD,
              isAsthma: parsedMedicalData.isAsthma,
              isHeartDisease: parsedMedicalData.isHeartDisease,
              isStroke: parsedMedicalData.isStroke,
              isPVD: parsedMedicalData.isPVD,
              isHeartAttack: parsedMedicalData.isHeartAttack,
              isFamilyHypertension: parsedMedicalData.isFamilyHypertension,
              isFamilyDiabetes: parsedMedicalData.isFamilyDiabetes,
              isFamilyCardioVascular: parsedMedicalData.isFamilyCardioVascular,
              isFamilyOtherDisease: parsedMedicalData.isFamilyOtherDisease,
              cheifComplaint: parsedMedicalData.cheifComplaint,
              otherChiefComplaint: parsedMedicalData.otherChiefComplaint,
            });
              
            setSelectedOptions(parsedMedicalData.cheifComplaint);
            if (parsedMedicalData.otherChiefComplaint) {
              setShowOtherInput(true); // Assuming setShowOtherInput is a boolean state
              setOtherInput(parsedMedicalData.otherChiefComplaint);
            }
          }
        }
      } catch (err) {
        console.log(err, "err");
        setIsLoading(false);
      }
      setIsLoading(false);
    };

    fetchDataAndSetMedicalHistory();
  }, [VISIT_ID]);

  const handleOptionSelection = (option, questionState) => {
    setmedicalHistory((prevMedicalHistory) => ({
      ...prevMedicalHistory,
      [questionState]: option,
    }));
   
  };

  const handleOtherInputChange = (e) => {
    setmedicalHistory((prevState) => ({
      ...prevState,
      otherChiefComplaint: e.target.value,
    }));
  };

  const handleDropdownClick = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleOptionSelect = (event) => {
    const value = event.target.value;
    // console.log("Value------", value)
    const isChecked = event.target.checked;

    if (!selectedOptions) {
      setSelectedOptions([]);
    }
    if (isChecked) {
      // setSelectedOptions([...selectedOptions, value]);
      setSelectedOptions(prevSelectedOptions => [...prevSelectedOptions, value]);
      // setmedicalHistory({
      //   ...medicalHistory,
      //   cheifComplaint: [...selectedOptions, value],
      // });
      setmedicalHistory((prevMedicalHistory) => ({
        ...prevMedicalHistory,
        cheifComplaint: isChecked
          ? [...(selectedOptions || []), value] // Use selectedOptions if it exists, otherwise use an empty array
          : (prevMedicalHistory.cheifComplaint || []).filter(option => option !== value), // Filter out the value if it exists
      }));
    } else {
      // setSelectedOptions(selectedOptions.filter((option) => option !== value));
      setSelectedOptions(prevSelectedOptions => prevSelectedOptions.filter(option => option !== value));
    }

    if (value === "Other") {
      setShowOtherInput(isChecked);
      if (!isChecked) {
        setOtherInput("");
      }
    }
  };

  const handleOk = () => {
    setmedicalHistory((prevMedicalHistory) => ({
      ...prevMedicalHistory,
      cheifComplaint: selectedOptions,
    }));
    setIsModalOpen(false);
  };

  function replacer(key, value) {
    try {
      if (key.trim() == '')
        return value;

      if (key.trim() === "drugHistory") {
        let modifiedJsonString = value.replace(/"(\d+\.\d+)"/g, "$1"); //(/\\"(\d+\.\d+)\\"/g, "$1");
        //console.log("===drug=====", modifiedJsonString)
        return modifiedJsonString;
      }

      if (key.trim() === "examinationHistory") {
        //console.log("===111111111110=====", value);
        let modifiedJsonString = value.replace(/"(\d+(\.\d+)?)"/g, "$1");
        //console.log("===examiantion=====", modifiedJsonString);
        return modifiedJsonString;
      }

      if (value && value !== null && typeof value === "object") {
        // console.log("value inside if", value)
        return value.replace(/"/g, '\\"'); // Replace " with \"  }   return value; }
      }
      return value;
    }
    catch (err) {
       //console.log("======key===", key, "===========value==", typeof value, "=======", value)
       console.log(err);
      if (Array.isArray(value) && value.length === 0)
        return value;
      else
        if (key.trim() === "tbHistory" || key.trim() === "osteoArthritisHistory") {
          let jsonString = JSON.stringify(value).replace(/"/g, '\\"');;
          // console.log(jsonString, '1#####');
          jsonString = `\"${jsonString}\"`;
          // console.log(jsonString, '2#####');
          return jsonString;
        }
        else
          return JSON.stringify(value);
    }
  }

  const saveRecord = async () => {
    try {
      // console.log("vist========", visitData)
      if (visitData) {
        visitData.medicalHistory = JSON.stringify(medicalHistory);
        // let DATA = await stringifyInnerJSON(visitData);
        let VisitData = JSON.stringify(visitData, replacer)
        //console.log("stringifiedVisitData---", VisitData)
        sessionStorage.setItem("visitData", VisitData)
        

        const response = await axios.post(`${Config.base_url}PATIENT_VISIT_DATA`, {
          'USER_ID': UserID,
          'VISIT_ID': visitData?.visitId?.toString(),
          'VISIT_DATA': VisitData,
          'STATUS': 2,
          'PRESCRIPTION_DATA': null,
          'CREATED_BY': visitData?.nurseId?.toString(),
        }, {
          headers: {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'x-caller': encrypted,
          }
        });

        if (response.status === 201) {
          setIsLoading(false);
          // await fetchData();
          return true;
        }
        else {
          console.error('Error: Data not saved');
          return false;
        }
      }
      else { return false; }
    } catch (error) {
      console.error('Error:', error);
      return false;
    }
  };

  // const handleNextClick1 = async () => {
  //   const recordSaved = await saveRecord();
 
  //   if (recordSaved) {
  //     // toggleSection1();
  //     if (selectedOptions && selectedOptions.length > 0) {
       
  //       toggleSection1();
  //     }
  //     else {
  //       setError('Required');
  //     }
  //   }
  //   else {
  //     console.log('err');
  //   }
  // };

  const handleNextClick = async () => {
    // console.log("handleNextClick");
  
    // Check if "Other" is selected
    const isOtherSelected = selectedOptions.includes('Other');
  
    // Validate otherChiefComplaint only if "Other" is selected
    if (isOtherSelected && (!medicalHistory.otherChiefComplaint || medicalHistory.otherChiefComplaint.trim() === "")) {
      // setError('Fill Enter other option');
      setError('Enter other option is required');
      return;
    }
  
    if (filteredRecord.length > 0 && filteredRecord[0].STATUS === 6) {
      console.log("before if (filteredRecord.length > 0 && filteredRecord[0].STATUS === 6)");
      toggleSection1();
      console.log("if (filteredRecord.length > 0 && filteredRecord[0].STATUS === 6)");
    } else if (selectedOptions && selectedOptions.length > 0) {
      // setIsLoading(true);
      const recordSaved = await saveRecord();
      if (recordSaved) {
        toggleSection1();
      } else {
        console.log('Error saving record');
      }
    } else {
      setError('Required');
    }
  };
  


  return (
    isLoading?(
      <div className="spinningLoader">
             <CircularProgress size={48} color="inherit" />
      </div>
       ):
        (<>
          <div
            style={{
              display: activeSection === "Medical" ? "block" : "none",
              backgroundColor: "white",
              // borderRadius: "1.25rem",
            }}
            
          >
            <p className="initalAssessmentSubHeading"
            
            >
              Has the patient been diagnosed with any of the following conditions?
            </p>
            <div
              style={{
                padding: "0.7rem 2rem",
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                
                // gap: "1rem",
              }}
            >
              <QuestionsAnswers
                question="Diabetes"
                backendData={medicalHistory && medicalHistory.isDiabetes}
                questionState="isDiabetes"
                setmedicalHistory={setmedicalHistory}
                onClickButton={(option, questionState) =>
                  handleOptionSelection(option, questionState)
                }
                historyData={medicalHistory["isDiabetes"]}
                isRequired={true}
              />
              <QuestionsAnswers
                question="Hypertension"
                questionState="isHypertension"
                setmedicalHistory={setmedicalHistory}
                backendData={medicalHistory && medicalHistory.isHypertension}
                onClickButton={(option, questionState) =>
                  handleOptionSelection(option, questionState)
                }
                historyData={medicalHistory["isHypertension"]}
                isRequired={true}
              />
              <QuestionsAnswers
                question="Chronic Kidney Disease"
                questionState="isCKD"
                setmedicalHistory={setmedicalHistory}
                backendData={medicalHistory && medicalHistory.isCKD}
                onClickButton={(option, questionState) =>
                  handleOptionSelection(option, questionState)
                }
                historyData={medicalHistory["isCKD"]}
                isRequired={true}
              />
              <QuestionsAnswers
                question="Liver Failure"
                questionState="isLiverFailure"
                setmedicalHistory={setmedicalHistory}
                backendData={medicalHistory && medicalHistory.isLiverFailure}
                onClickButton={(option, questionState) =>
                  handleOptionSelection(option, questionState)
                }
                historyData={medicalHistory["isLiverFailure"]}
                isRequired={true}
              />
              <QuestionsAnswers
                question="Chronic Obstructive Pulmonary Disease (COPD)"
                questionState="isCOPD"
                setmedicalHistory={setmedicalHistory}
                backendData={medicalHistory && medicalHistory.isCOPD}
                onClickButton={(option, questionState) =>
                  handleOptionSelection(option, questionState)
                }
                historyData={medicalHistory["isCOPD"]}
                isRequired={true}
              />
              <QuestionsAnswers
                question="Asthma"
                questionState="isAsthma"
                setmedicalHistory={setmedicalHistory}
                backendData={medicalHistory && medicalHistory.isAsthma}
                onClickButton={(option, questionState) =>
                  handleOptionSelection(option, questionState)
                }
                historyData={medicalHistory["isAsthma"]}
                isRequired={true}
              />
              <QuestionsAnswers
                question="Heart Disease"
                questionState="isHeartDisease"
                setmedicalHistory={setmedicalHistory}
                backendData={medicalHistory && medicalHistory.isHeartDisease}
                onClickButton={(option, questionState) =>
                  handleOptionSelection(option, questionState)
                }
                historyData={medicalHistory["isHeartDisease"]}
                isRequired={true}
              />
              <QuestionsAnswers
                question="Peripheral Vascular Disease"
                questionState="isPVD"
                setmedicalHistory={setmedicalHistory}
                backendData={medicalHistory && medicalHistory.isPVD}
                onClickButton={(option, questionState) =>
                  handleOptionSelection(option, questionState)
                }
                historyData={medicalHistory["isPVD"]}
                isRequired={true}
              />
              <QuestionsAnswers
                question="Stroke or Paralytic Attack"
                questionState="isStroke"
                setmedicalHistory={setmedicalHistory}
                backendData={medicalHistory && medicalHistory.isStroke}
                onClickButton={(option, questionState) =>
                  handleOptionSelection(option, questionState)
                }
                historyData={medicalHistory["isStroke"]}
                isRequired={true}
              />
              <QuestionsAnswers
                question="Heart Attack"
                questionState="isHeartAttack"
                setmedicalHistory={setmedicalHistory}
                backendData={medicalHistory && medicalHistory.isHeartAttack}
                onClickButton={(option, questionState) =>
                  handleOptionSelection(option, questionState)
                }
                historyData={medicalHistory["isHeartAttack"]}
                isRequired={true}
              />
            </div>
            <ToastContainer />
          </div>
          <div
            style={{
              display: activeSection === "Medical" ? "block" : "none",
              backgroundColor: "white",
              // borderRadius: "1.25rem",
            }}
          >
            <p
              className="initalAssessmentSubHeading"
            >
              Does anyone in your family has the following conditions?
            </p>
            <div
              style={{
                padding: "0.7rem 2rem",
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                // gap: "1rem",
              }}
            >
              <QuestionsAnswers
                question="Hypertension or High Blood Pressure?"
                questionState="isFamilyHypertension"
                setmedicalHistory={setmedicalHistory}
                backendData={medicalHistory && medicalHistory.isFamilyHypertension}
                onClickButton={(option, questionState) =>
                  handleOptionSelection(option, questionState)
                }
                historyData={medicalHistory["isFamilyHypertension"]}
                isRequired={false}
              />
              <QuestionsAnswers
                question="Diabetes Mellitus or Raised Blood Sugar Level?"
                questionState="isFamilyDiabetes"
                setmedicalHistory={setmedicalHistory}
                backendData={medicalHistory && medicalHistory.isFamilyDiabetes}
                onClickButton={(option, questionState) =>
                  handleOptionSelection(option, questionState)
                }
                historyData={medicalHistory["isFamilyDiabetes"]}
                isRequired={false}
              />
              <QuestionsAnswers
                question="Cardiovascular or Heart Diseases?"
                questionState="isFamilyCardioVascular"
                setmedicalHistory={setmedicalHistory}
                backendData={
                  medicalHistory && medicalHistory.isFamilyCardioVascular
                }
                onClickButton={(option, questionState) =>
                  handleOptionSelection(option, questionState)
                }
                historyData={medicalHistory["isFamilyCardioVascular"]}
                isRequired={false}
              />
              <QuestionsAnswers
                question="Any other Disease?"
                questionState="isFamilyOtherDisease"
                setmedicalHistory={setmedicalHistory}
                backendData={medicalHistory && medicalHistory.isFamilyOtherDisease}
                onClickButton={(option, questionState) =>
                  handleOptionSelection(option, questionState)
                }
                historyData={medicalHistory["isFamilyOtherDisease"]}
                isRequired={false}
              />
            </div>
          </div>
          <div
            style={{
              display: activeSection === "Medical" ? "block" : "none",
              backgroundColor: "white",
              // borderRadius: "1.25rem",
            }}
          >
            <p
              className="initalAssessmentSubHeading"
            >
              Chief Complaint?
            </p>
            <div
              style={{
                backgroundColor: "white",
                // borderRadius: "1.25rem",
                margin: "1rem 0.7rem",
              }}
            >
              <div
                style={{
                  padding: "0.7rem 2rem",
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  gap: "1rem",
                  backgroundColor: "#efeafc",
                  borderRadius: "1rem",
                  alignItems:"center",
                }}
                onClick={handleDropdownClick}
              >
                <p>Select Multiple Symptoms</p>
                <img src={downArrow} style={{ cursor: "pointer" ,width:"15px", height:"15px" }} />
              </div>
              {/* {error && <p style={{ color: 'red' }}>{error}</p>} */}


              {/* {selectedOptions && selectedOptions.filter((option) => option !== "Other").length > 0 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "12px",
                    gap: "1rem",
                    height: "fit-content",
                  }}
                >
                  {selectedOptions
                    .filter((option) => option)
                    .map((option, index) => (
                      <p
                        key={index}
                        style={{
                          background: "#E3EBFF",
                          marginRight: "5px",
                          padding: "7px 1rem",
                          borderRadius: "7px",
                          gap: "10rem",
                        }}
                      >
                        {option}
                      </p>
                    ))}
                </div>
              )} */}

              {selectedOptions && Array.isArray(selectedOptions) && selectedOptions.filter((option) => option !== "Other").length > 0 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "12px",
                    gap: "1rem",
                    height: "fit-content",
                  }}
                >
                  {selectedOptions
                    .filter((option) => option)
                    .map((option, index) => (
                      <p
                        key={index}
                        style={{
                          background: "#E3EBFF",
                          marginRight: "5px",
                          padding: "7px 1rem",
                          borderRadius: "7px",
                          gap: "10rem",
                        }}
                      >
                        {option}
                      </p>
                    ))}
                </div>
              )}


              {showOtherInput && (
                <div style={{ paddingTop: "14px" }}>
                  <input
                    style={{
                      padding: "10px",
                      background: "#E3EBFF",
                      border: "none",
                      outline: "none",
                      height: "29px",
                      borderRadius: "7px",
                    }}
                    className="personalInfoValue"
                    type="text"
                    placeholder="Enter other option"
                    value={medicalHistory.otherChiefComplaint}
                    onChange={handleOtherInputChange}
                  />
                  <p style={{color:"red"}}>{error}</p>
                </div>
              )}

              <Dialog 
                open={isModalOpen}
                style={{ width: "100%", alignItems: "center" }}
              >
                <DialogTitle className="multisymptoms">Select multiple symptoms</DialogTitle>
                <DialogContent
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "1.04% 68% 0.63% 3%",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleOptionSelect}
                        value="Diabetes check up"
                        checked={selectedOptions && selectedOptions.includes("Diabetes check up")}
                      />
                    }
                    label="Diabetes check up"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleOptionSelect}
                        value="Hypertension check up"
                        checked={selectedOptions && selectedOptions.includes("Hypertension check up")}
                      />
                    }
                    label="Hypertension check up"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleOptionSelect}
                        value="Fever check up"
                        checked={selectedOptions && selectedOptions.includes("Fever check up")}
                      />
                    }
                    label="Fever check up"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleOptionSelect}
                        value="Other"
                        checked={selectedOptions && selectedOptions.includes("Other")}
                      />
                    }
                    label="Other"
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleOk}
                  // variant="contained"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleOk}
                  // variant="contained"
                  >
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
           
          </div>
          <div
            
            style={{
              display: activeSection === "Medical" ? "Flex" : "none",
              justifyContent: "flex-end",
              alignItems: "center",
              width: "100%",
              padding: "0 3%",
              marginTop:"10px"
            }}
            
          // onClick={toggleSection1}
          >
            <button
             
              style={{
                background: "#5d4a87",
                color: "white",
                border: "none",
                padding: "0.8rem 2rem ",
                borderRadius: "28px",
                height: "40px",
                cursor: "pointer"
              }}
              onClick={handleNextClick}
            >
              Next
              {isLoading && (
                <CircularProgress size={24} color="inherit" />
              )}
            </button>
          </div>
          {/* {!isDrugHistoryVisible &&
            <DrugHistory
              isVisible={true}
              activeSection={'Drug'}
              DrugHistory={DrugHistory}
              MedicalHistory={medicalHistory}
            />
          } */}
        </>
      )

  );
};

export default MedicalTab;