import React, { useEffect, useState } from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import "./PreviewMIS.css";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const PreviewMIS = ({
  newPatientModelList,
  patientVisited,
  followupsModelList,
  closePopup 
}) => {
  const userDetails = JSON.parse(sessionStorage.getItem("UserData"))[0];
  console.log("User------", userDetails);
  console.log(newPatientModelList, "newPatientModelList");

  const handleClick = () => {
    const docDefinition = {
      content: [
        // Doctor's Name and Contact Information
        {
          text: `Mr ${userDetails.FIRST_NAME} ${userDetails.LAST_NAME}`,
          color: "darkviolet",
          bold: true,
          fontSize: 20,
          margin: [0, 0, 0, 10],
        },
        {
          text: `Email: ${userDetails.EMAIL}  Phone: ${userDetails.MOBILE}`,
          color: "darkviolet",
          fontSize: 10,
          margin: [0, 0, 0, 10],
        },
        {
          canvas: [
            { type: "line", x1: 0, y1: 10, x2: 500, y2: 10, lineWidth: 1 },
          ],
        },

        // Patients Visited Section
        {
          text: `Patients Visited: ${
            patientVisited ? patientVisited.length : 0
          }`,
          fontSize: 16,
          bold: true,
          color: "darkviolet",
          margin: [0, 20, 0, 10],
        },
        patientVisited &&
          patientVisited.length > 0 && {
            stack: [
              {
                columns: [
                  { text: "PATIENT ID", color: "darkviolet", bold: true },
                  { text: "FIRST NAME", color: "darkviolet", bold: true },
                  { text: "GENDER", color: "darkviolet", bold: true },
                  { text: "HOSPITAL NAME", color: "darkviolet", bold: true },
                ],
                margin: [0, 0, 0, 5],
              },
              ...patientVisited.map((patient) => [
                {
                  columns: [
                    { text: patient.USER_ID },
                    { text: patient.FIRST_NAME },
                    { text: patient.GENDER },
                    { text: patient.HOSPITAL_NAME },
                  ],
                  margin: [0, 0, 0, 5],
                },
                {
                  canvas: [
                    {
                      type: "line",
                      x1: 0,
                      y1: 0,
                      x2: 500,
                      y2: 0,
                      lineWidth: 1,
                      lineColor: "#D6E5FF",
                    },
                  ],
                },
              ]),
            ],
          },

        // New Patients Visited Section
        {
          text: `New Patients Visited: ${
            newPatientModelList ? newPatientModelList.length : 0
          }`,
          fontSize: 16,
          bold: true,
          color: "darkviolet",
          margin: [0, 20, 0, 10],
        },
        newPatientModelList &&
          newPatientModelList.length > 0 && {
            stack: [
              {
                columns: [
                  { text: "PATIENT ID", color: "darkviolet", bold: true },
                  { text: "FIRST NAME", color: "darkviolet", bold: true },
                  { text: "GENDER", color: "darkviolet", bold: true },
                  { text: "HOSPITAL NAME", color: "darkviolet", bold: true },
                ],
                margin: [0, 0, 0, 5],
              },
              ...newPatientModelList.map((patient) => [
                {
                  columns: [
                    { text: patient.USER_ID },
                    { text: patient.FIRST_NAME },
                    { text: patient.GENDER },
                    { text: patient.HOSPITAL_NAME },
                  ],
                  margin: [0, 0, 0, 5],
                },
                {
                  canvas: [
                    {
                      type: "line",
                      x1: 0,
                      y1: 0,
                      x2: 500,
                      y2: 0,
                      lineWidth: 1,
                      lineColor: "#D6E5FF",
                    },
                  ],
                },
              ]),
            ],
          },

        // Follow-Ups Section
        {
          text: `No of FollowUps: ${
            followupsModelList ? followupsModelList.length : 0
          }`,
          fontSize: 16,
          bold: true,
          color: "darkviolet",
          margin: [0, 20, 0, 10],
        },
        followupsModelList &&
          followupsModelList.length > 0 && {
            stack: [
              {
                columns: [
                  { text: "PATIENT ID", color: "darkviolet", bold: true },
                  { text: "FIRST NAME", color: "darkviolet", bold: true },
                  { text: "GENDER", color: "darkviolet", bold: true },
                  { text: "HOSPITAL NAME", color: "darkviolet", bold: true },
                ],
                margin: [0, 0, 0, 5],
              },
              ...followupsModelList.map((patient) => [
                {
                  columns: [
                    { text: patient.USER_ID },
                    { text: patient.FIRST_NAME },
                    { text: patient.GENDER },
                    { text: patient.HOSPITAL_NAME },
                  ],
                  margin: [0, 0, 0, 5],
                },
                {
                  canvas: [
                    {
                      type: "line",
                      x1: 0,
                      y1: 0,
                      x2: 500,
                      y2: 0,
                      lineWidth: 1,
                      lineColor: "#D6E5FF",
                    },
                  ],
                },
              ]),
            ],
          },
      ],
    };

    pdfMake.createPdf(docDefinition).download("MISReport.pdf");
  };

  return (
    <>
      <div className="misentireA4sheet">
        <div className="page-container">
          <div className="page-content">
            <div className="misReport">
              <div className="mispatientReport">
                <p id="misDoctorName">
                  Mr {userDetails.FIRST_NAME} {userDetails.LAST_NAME}
                </p>
                <div style={{display:"flex", justifyContent:"space-between"}}>
                <p id="misDoctorPh">
                  Email: {userDetails.EMAIL} 
                </p>
                <p>Phone: {userDetails.MOBILE}</p>
                </div>
                
                <hr />
                <div>
                  <p id="misName">
                    Patients Visited:{" "}
                    {patientVisited ? patientVisited.length : 0}
                  </p>
                  {patientVisited && patientVisited.length > 0 && (
                    <div id="MISreport" className="table-container">
                      <div className="table-header">
                        <div>PATIENT ID</div>
                        <div>FIRST NAME</div>
                        <div>GENDER</div>
                        <div>HOSPITAL NAME</div>
                      </div>
                      <div className="table-body">
                        {patientVisited.map((patient) => (
                          <div className="table-row" key={patient.USER_ID}>
                            <div>{patient.USER_ID}</div>
                            <div>{patient.FIRST_NAME}</div>
                            <div>{patient.GENDER}</div>
                            <div>{patient.HOSPITAL_NAME}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-container">
          <div className="page-content">
            <div className="misReport">
              <div className="patientReport">
                <p id="misName">
                  New Patients Visited:{" "}
                  {newPatientModelList ? newPatientModelList.length : 0}
                </p>
                {newPatientModelList && newPatientModelList.length > 0 && (
                  <div id="MISreport" className="table-container">
                    <div className="table-header">
                      <div>PATIENT ID</div>
                      <div>FIRST NAME</div>
                      <div>GENDER</div>
                      <div>HOSPITAL NAME</div>
                    </div>
                    <div className="table-body">
                      {newPatientModelList.map((patient) => (
                        <div className="table-row" key={patient.USER_ID}>
                          <div>{patient.USER_ID}</div>
                          <div>{patient.FIRST_NAME}</div>
                          <div>{patient.GENDER}</div>
                          <div>{patient.HOSPITAL_NAME}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                <hr />
              </div>
            </div>
          </div>
        </div>

        <div className="page-container">
          <div className="page-content">
            <div className="misReport">
              <div className="patientReport">
                <p id="misName">
                  No of FollowUps:{" "}
                  {followupsModelList ? followupsModelList.length : 0}
                </p>
                {followupsModelList && followupsModelList.length > 0 && (
                  <div id="MISreport" className="table-container">
                    <div className="table-header">
                      <div>PATIENT ID</div>
                      <div>FIRST NAME</div>
                      <div>GENDER</div>
                      <div>HOSPITAL NAME</div>
                    </div>
                    <div className="table-body">
                      {followupsModelList.map((patient) => (
                        <div className="table-row" key={patient.USER_ID}>
                          <div>{patient.USER_ID}</div>
                          <div>{patient.FIRST_NAME}</div>
                          <div>{patient.GENDER}</div>
                          <div>{patient.HOSPITAL_NAME}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                <hr />
              </div>
            </div>
          </div>
        </div>

        <div className="page-container">
          <div className="page-contentmis">
            {/*<div className="misdownloadButtonmain">
              <button className="misdownloadButton" onClick={handleClick}>
                Download
              </button>
            </div>*/}
            <div className="misdownloadButtonmain">
              <button className="misSearchDateclose" onClick={closePopup} >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreviewMIS;
