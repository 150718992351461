import React, { useState, useEffect } from "react";
import Axios from "axios";
import {CircularProgress} from "@mui/material";
import { publicEncrypt, constants } from "crypto-browserify";
import { Buffer } from "buffer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Verified from "../../images/verified 2.svg";
import Config from "../../Config/config.jsx";
import DeleteIcon from "../../images/delete 8.svg";
import profileIcon from "../../images/profile-round-1345 1.svg";
import calendarIcon from "../../images/calendar-minimalistic 1.svg";
import locationIcon from "../../images/location-pin 1.svg";
import hospitalIcon from "../../images/hospital 2.svg";
import Select from "react-select";
import config from "../../Config/config.jsx";

const UpdateExperience = () => {
  const [listOfExperiences, setListOfExperiences] = useState(JSON.parse(sessionStorage.getItem("listOfExperiences")) || []
  );
  const [activeSection, setActiveSection] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [formData, setFormData] = useState({
    role: "",
    hospitalName: "",
    city: "",
    startYear: "",
    startingMonth: "",
    endingYear: "",
    endingMonth: "",
  });

  const [endYears, setEndYears] = useState([]);

  useEffect(() => {
    getDetails();
  }, []);

    useEffect(() => {
    // getDetails();
  }, [listOfExperiences]);
  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");

  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));
  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : JSON.parse(sessionStorage.getItem("token"));

  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");

  const getDetails = async () => {
    try {
      const response = await Axios.get(
        `${Config.base_url}/v2/GETUSEREXPERIENCE/${UserID}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "x-caller": await encrypted,
          },
        }
      );

      // console.log(response)

      if (response.status === 200) {
        //console.log("++++++++++", response.data.recordset);
        setListOfExperiences(response.data.recordset);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const calculateExperience = (experience) => {
    const startingYear = parseInt(experience.STARTING_YEAR);
    const startingMonth = parseInt(experience.STARTING_MONTH);
    const endingYear = parseInt(experience.ENDING_YEAR);
    const endingMonth = parseInt(experience.ENDING_MONTH);

    const a = new Date(endingYear, endingMonth - 1);
    const b = new Date(startingYear, startingMonth - 1);
    const totalDays = Math.floor((a - b) / (1000 * 60 * 60 * 24));
    const years = Math.floor(totalDays / 365);
    const months = Math.floor((totalDays - years * 365) / 30);

    return `<span>${years}</span> Years, <span>${months}</span> Months`;
  };


  const toggleSection = (sectionName) => {
    setActiveSection(sectionName);
  };

  const DropdownIndicator = (props) => {
    return (
      <svg
        width="17px"
        height="20px"
        viewBox="0 0 24 24"
        cursor="pointer"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"
          fill="black" // Set your desired arrow color here
        />
      </svg>
    );
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "lightblue" : provided.backgroundColor,
      color: "black",
      "&:hover": {
        backgroundColor: "lightblue", // Ensures the hover background is fully visible
        color: "black",
      },
      boxSizing: "border-box", // Ensures the width includes padding and border
      padding: "8px 12px", // Adjust padding to ensure full width coverage
      width: "100%", // Ensure the option takes the full width of the dropdown
      margin: "0", // Remove any margin that might cause the cutting
    }),
    menu: (provided) => ({
      ...provided,
      width: 'auto', // Auto width based on the content
      minWidth: '100%', // Ensure menu is at least as wide as the select component
    }),
  };
  

  const handleInputChange = (event, field) => {
    const value = event.target.value;
    const lettersAndSpaceValue = value.replace(/[^a-zA-Z\s]/g, '');
    setFormData((prevVariables) => ({
      ...prevVariables,
      [field]: lettersAndSpaceValue,
    }));
  };

  const getYears = (startYear) => {
    const currentYear = new Date().getFullYear();
    const yearsTilPresent = [];

    while (startYear <= currentYear) {
      yearsTilPresent.push({
        label: startYear.toString(),
        value: startYear.toString(),
      });
      startYear++;
    }
    return yearsTilPresent;
  };
  const years = getYears(1900);

  const handleStartYearChange = (year) => {
    setFormData({ ...formData, startYear: year });
    const validEndYears = getYears(year);
    // console.log("valida", validEndYears);
    setEndYears(validEndYears);
  };

  const handleEndYearChange = (year) => {
    if (year > formData.startYear) {
      setFormData({ ...formData, endingYear: year });
    } else {
      toast.warning(
        "The end year must be greater than or equal to the start year."
      );
      setFormData({ ...formData, endingYear: "" });
    }
  };

  const months = [
    { value: "1", label: "January" },
    { value: "2", label: "February" },
    { value: "3", label: "March" },
    { value: "4", label: "April" },
    { value: "5", label: "May" },
    { value: "6", label: "June" },
    { value: "7", label: "July" },
    { value: "8", label: "August" },
    { value: "9", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  const handleStartMonthChange = (selectedOption) => {
    setFormData({ ...formData, startingMonth: selectedOption });
  };

  const handleEndMonthChange = (selectedOption) => {
    setFormData({ ...formData, endingMonth: selectedOption });
  };

  const removeExperience = async (value) => {
    //console.log("value", value);
    const updatedExperiences = listOfExperiences.filter(
      (_, item) => item !== value
    );
    setListOfExperiences(updatedExperiences);
    console.log("IDENTICALVALUE",  listOfExperiences[value ?? 0]);
    const IDENTICALVALUE = listOfExperiences[value ?? 0]["ID"]?.toString();
    await uploadUserExperience(updatedExperiences, IDENTICALVALUE);
  };


  const uploadUserExperience = async (updatedExperiences, IDENTICALVALUE) => {
    console.log("IDENTICALVALUE", IDENTICALVALUE);
  
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
  
    // Check if mandatory fields are filled
    const mandatoryFieldsFilled = IDENTICALVALUE || (
      formData.role &&
      formData.hospitalName &&
      formData.city &&
      formData.startYear &&
      formData.startingMonth.value &&
      (formData.endingYear.value || formData.endingYear.value === currentYear) &&
      formData.endingMonth.value
    );
  
    if (!mandatoryFieldsFilled) {
      toast.warning("Please fill the mandatory fields");
      return; // Exit the function early if mandatory fields are missing
    }

  
    const payload = IDENTICALVALUE
      ? {
          'USER_ID': UserID,
          'experience': updatedExperiences,
          'specialization': '',
          'hospitalName': '',
          'city': '',
          'startingYear': '',
          'endingYear': '',
          'startingMonth': '',
          'endingMonth': '',
          'IDENTICALVALUE': IDENTICALVALUE,
        }
      : {
          'USER_ID': UserID ?? '',
          'experience': listOfExperiences ?? '',
          'specialization': formData.role ?? '',
          'hospitalName': formData.hospitalName ?? '',
          'city': formData.city ?? '',
          'startingYear': formData.startYear ?? '',
          'endingYear': formData.endingYear.value ?? currentYear,
          'startingMonth': formData.startingMonth.value ?? '',
          'endingMonth': formData.endingMonth.value ?? currentMonth,
        };
  
    //console.log("payload ", payload);
    
    try {
      const response = await Axios.post(
        Config.base_url + "v2/USER_EXPERIENCE",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        }
      );
  
      if (response.status === 201 && !IDENTICALVALUE) {
        //console.log("Success");

  
        let newExperience = {
          STATUS:0,
          SPECIALIZATION: formData.role,
          HOSPITAL_NAME: formData.hospitalName,
          CITY: formData.city,
          STARTING_YEAR: formData.startYear,
          ENDING_YEAR: formData.endingYear.value,
          STARTING_MONTH: formData.startingMonth.value,
          ENDING_MONTH: formData.endingMonth.value,
        };
        //console.log("newExperience---------", newExperience);
        setListOfExperiences([...listOfExperiences, newExperience]);
        sessionStorage.setItem(
          "listOfExperiences",
          JSON.stringify([...listOfExperiences, newExperience])
        );
        toast.success("Saved Successfully.");
        setFormData({
          role: "",
          hospitalName: "",
          city: "",
          startYear: "",
          startingMonth: "",
          endingYear: "",
          endingMonth: "",
        });
        
      } else if (response.status === 404) {
        //console.log(response.data);
        // Display error dialog for experience not linked
      } else {
        //console.log(response.data);
        // Display unknown error dialog
      }
    } catch (error) {
      console.error(error);
      // Display error dialog for network or server error
    }
    toggleSection("");
  };
  

  //console.log("list of experinces", listOfExperiences)

  return (
    <div
      className="profileRightContainer"
      style={{ display: "flex", gap: "1rem" }}
    >
      <div className="leftContainer" style={{ width: "70%",flex:2 }}>
        {/* <div className="topContainer">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              alignItems: "center",
            }}
          >
            <label
              className="headerEdu">
              Experiences
            </label>
          </div>
        </div>
        <div style={{ background: "#AEAEAE", width: "100%", height: "2px" }}>
          <p style={{ height: "2px" }}></p>
        </div> */}

        <div className="educationd-bottomContainer"  >
          {/* <div
            style={{
              width: "37%",
              height: "45px",
              background: "white",
            }}
            className={"editButton"}
            onClick={() => toggleSection("AddExperince")}
          >
            <p className="Addeducation">
              + ADD ANOTHER EXPERIENCE
            </p>
          </div> */}
            {isLoading?(
                <div className="spinningLoader">
                      <CircularProgress size={48} color="inherit" />
                </div>
              ):
          (<div className="cardsCols">
            {listOfExperiences.map((experience, index) => (
              experience.STATUS === 0 ? (
                <div 
                  key={index}
                  className="educationd-cardContainer experianced-cardContainer"
                >
                  <div>
                  <div
                        className="bg-deleteicon"
                        onClick={() => removeExperience(index)}
                      >
                        <img src={DeleteIcon} alt="Delete" style={{cursor:"pointer"}}/>
                      </div>
                    <div className="row1">
                    
                      <div className="exp-logo">
                        <img class = "exp-logo-img" src={Verified} alt="Verified" />
                      </div>
                      <div className="years">
                        <p dangerouslySetInnerHTML={{ __html: calculateExperience(experience) }}></p>
                      </div>
                      
                      
                    </div>

                    <div className="row2">
                      {experience.SPECIALIZATION}
                      <div className="Nameandimg detailsContainer">
                        {/* <img src={hospitalIcon} /> */}
                        <p className="hospital-row">Hospital:</p>
                        <p className="details">{experience.HOSPITAL_NAME}</p>
                      </div>
                      <div className="Nameandimg">
                        {/* <img src={calendarIcon} /> */}
                        <p className="hospital-row">From:</p>
                        <p className="details">
                          {`${experience.STARTING_MONTH}/${experience.STARTING_YEAR}`}
                        </p> 
                        </div>
                        <div className="Nameandimg">
                        <p className="hospital-row">To:</p>
                        <p className="details">
                          {`${experience.ENDING_MONTH}/${experience.ENDING_YEAR}`}
                        </p>
                      </div>
                      <div className="Nameandimg" >
                        {/* <img src={locationIcon} /> */}
                        <p className="hospital-row">Location:</p>
                        <p className="details">{experience.CITY}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null
            ))}
            <div
            className="educationd-cardContainer educationd-addContainer experianced-cardContainer"
            onClick={() => toggleSection("AddExperince")}
            >
              <p className="educationd-add">
                + 
              </p>
          </div>

          </div>)
              }
          <ToastContainer />
        </div>
      </div>
      {
        activeSection === "AddExperince" ? (
          <div className="hospitalpopup">
            <div className="Boxshadow" >
            <p className="hsptlsetup" >
                <span className="plus-icon">+</span>
                <span className="header-text">Add Experience Qualification</span>
                <span className="close" onClick={() => toggleSection("")}>&times;</span>
              </p>
              <div className="Addexpbtn">
                <div
                  className="Boxwidth"
                >
                  <div
                    className="personalInfoContainer"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      marginBottom:"0px",
                      color: '#696969',
                      justifyContent:"center"

                    }}
                  >
                    <div className="fieldsize" >
                      <input
                        style={{
                          padding: "10px",
                          display: "block",
                          backgroundColor: "#ffffff",
                          border: "1px solid #DFDFDF",
                          // borderRadius: "6px",
                          width: "100%",
                          color: '#696969'
                        }}
                        className="experinceInput"
                        type="text"
                        placeholder="Enter Your Roles Title*"
                        value={formData.role}
                        onChange={(e) => handleInputChange(e, "role")}
                      />
                    </div>

                    <div
                      className="fieldsize"
                    >
                      <input
                        style={{
                          padding: "10px",
                          display: "block",
                          backgroundColor: "#ffffff",
                          border: "1px solid #DFDFDF",
                          // borderRadius: "6px",
                          width: "100%",
                          color: 'rgb(105, 105, 105)'
                        }}
                        className="experinceInput"
                        type="text"
                        placeholder="Enter Hospital/Clinic Name*"
                        value={formData.hospitalName}
                        onChange={(e) => handleInputChange(e, "hospitalName")}
                      />
                    </div>

                    <div
                      className="fieldsize"
                    >
                      <input
                        style={{
                          padding: "10px",
                          display: "block",
                          backgroundColor: "#ffffff",
                          border: "1px solid #DFDFDF",
                          // borderRadius: "6px",
                          width: "100%",
                        }}
                        className="experinceInput"
                        type="text"
                        placeholder="City*"
                        value={formData.city}
                        onChange={(e) => handleInputChange(e, "city")}
                      />
                    </div>
                    <div className="yrmnthfields" >
                      <Select
                        id="stateDropdown"
                        value={years.find(
                          (year) => year.value === formData.startYear
                        )}
                        onChange={(selectedOption) =>
                          handleStartYearChange(selectedOption.value)
                        }
                        options={years}
                        placeholder="Starting Year*"
                        isSearchable
                        // isClearable
                        styles={customStyles}
                        components={DropdownIndicator}
                      />
                    </div>
                    <div className="yrmnthfields" >
                      <Select
                        id="stateDropdown"
                        value={formData.startingMonth}
                        onChange={handleStartMonthChange}
                        options={months}
                        placeholder="Month*"
                        styles={customStyles}
                        components={DropdownIndicator}
                      />
                    </div>
                    <div className="yrmnthfields">
                      <Select
                        id="stateDropdown"
                        value={formData.endingYear}
                        onChange={handleEndYearChange}
                        options={endYears}
                        placeholder="Till Year*"
                        isSearchable
                        // isClearable
                        styles={customStyles}
                        components={DropdownIndicator}
                      />
                    </div>

                    <div
                      className="yrmnthfields"
                    >
                      <Select
                        id="stateDropdown"
                        value={formData.endingMonth}
                        onChange={handleEndMonthChange}
                        options={months}
                        placeholder="Month*"
                        isSearchable
                        // isClearable
                        styles={customStyles}
                        components={DropdownIndicator}
                      />
                    </div>
                  </div>
                </div>
                <p className="horizontal-line"></p>
                <div className="educationd-buttonContainer">
                  <div
                    className={"educationd-Btn educationd-close-Btn"}
                    onClick={() => toggleSection("")}
                  >
                    
                    <p>Close</p>
                  </div>
                  <div
                    className={"educationd-Btn educationd-save-Btn"}
                    onClick={uploadUserExperience}
                  >
                    
                    <p>Save</p>
                  </div>
                </div>
                {/* <div
                  // style={{
                  //   width: "90%",
                  //   height: "45px",
                  // }}
                  className={"Btn"}
                  onClick={uploadUserExperience}
                >
                  <p>ADD EXPERIENCE</p>
                </div> */}
              </div>
              <ToastContainer position="top-right" />
            </div>
          </div>
        ) : (
          ""
        )
      }
    </div >
  );
};

export default UpdateExperience;

