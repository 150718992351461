import React, { useEffect, useState } from "react";
// import downArrow from "../../images/downArrow.svg";
import downArrow from "../../../images/downArrow.svg";
import QuestionsAnswers from "../QuestionsAnswers";
// import { Modal, Paper, Typography, Button } from "@mui/material";
import Popup from "../Popup";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Select from "react-select";
import Axios from "axios";
import Config from "../../../Config/config";
import { publicEncrypt, constants } from "crypto-browserify";
import { Buffer } from "buffer";
import crossButton from "../../../images/Group 411.svg";
// import Slider from "rc-slider";
import DeleteIcon from "../../../images/delete 8.svg";
// import "rc-slider/assets/index.css";
import { Snackbar, CircularProgress, Alert } from "@mui/material";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import "./DrugHistoryTab.css";

const DrugHistory = ({
  activeSection,
  DrugHistory,
  MedicalHistory,
  toggleSection2,
  toggleSection0,
}) => {
  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");

  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));
  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : JSON.parse(sessionStorage.getItem("token"));

  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");

  const PATIENT_ID = JSON.parse(sessionStorage.getItem("PATIENT_ID"));
  // console.log("patieeeeeeeeeee", PATIENT_ID)

  const selectedPatientHistory =
    sessionStorage.getItem("selectedPatientHistory") !== "undefined"
      ? JSON.parse(sessionStorage.getItem("selectedPatientHistory"))
      : [];
  // console.log(
  //   "selectedPatientHistory----------",
  //   selectedPatientHistory.VISIT_DATE
  // );

  const VISIT_ID = sessionStorage.getItem("VISIT_ID");

  function formatVisitDate(visitDate) {
    // Parse the initial visit date string into a Date object
    const initialDate = new Date(visitDate);

    // Extract the date and time components
    const year = initialDate.getFullYear();
    const month = String(initialDate.getMonth() + 1).padStart(2, "0");
    const day = String(initialDate.getDate()).padStart(2, "0");
    const hours = String(initialDate.getHours()).padStart(2, "0");
    const minutes = String(initialDate.getMinutes()).padStart(2, "0");
    const seconds = String(initialDate.getSeconds()).padStart(2, "0");
    const milliseconds = String(initialDate.getMilliseconds()).padStart(5, "0");

    // Construct the formatted visit date string
    const formattedVisitDateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
    //  console.log("======----formated", formattedVisitDateString);

    return formattedVisitDateString;
  }

  const VISITDATA = {
    patientId: selectedPatientHistory.PATIENT_ID,
    mobile: selectedPatientHistory.MOBILE,
    nurseId: selectedPatientHistory.NURSE_USER_ID,
    doctorId: selectedPatientHistory.DOCTOR_USER_ID,
    visitId: selectedPatientHistory.VISIT_ID,
    visitDate: formatVisitDate(selectedPatientHistory.VISIT_DATE),
    medicalHistory: {
      isDiabetes: null,
      isHypertension: null,
      isCKD: null,
      isLiverFailure: null,
      isCOPD: null,
      isAsthma: null,
      isHeartDisease: null,
      isStroke: null,
      isPVD: null,
      isHeartAttack: null,
      isFamilyHypertension: null,
      isFamilyDiabetes: null,
      isFamilyCardioVascular: null,
      isFamilyOtherDisease: null,
      cheifComplaint: [],
      otherChiefComplaint: null,
    },
    drugHistory: {
      isHypertensiveDrugs: null,
      isDiabeticDrugs: null,
      isLipidDrugs: null,
      isAsprin: null,
      isInsulin: null,
      currentHTDrugs: [],
      currentDBDrugs: [],
      currentInsulin: [],
      currentAsprin: [],
      currentLipid: [],
    },
    personalHistory: {
      isPhysicalActivity: null,
      isSmoking: null,
      isDrinking: null,
      drinkingFrequency: null,
      isFamilyHistoryOfMI: null,
      isPolyuria: null,
      isPolydypsia: null,
      isWeightloss: null,
      isOnDialysis: null,
    },
    examinationHistory: {
      sbp: null,
      dbp: null,
      heartRate: null,
      sbp2: null,
      dbp2: null,
      sbp3: null,
      dbp3: null,
      averageSBP: null,
      averageDBP: null,
      fbs: null,
      ppbs: null,
      rbs: null,
      hba1c: null,
      waist: null,
      height: null,
      weight: null,
      bmi: null,
      ldl: null,
      creatinine: null,
      cholestrol: null,
      isEdema: null,
      isBreathlessness: null,
      isChestPain: null,
      isDeficit: null,
      isAlteredConciousness: null,
      isFundus: null,
      isGeneralizedSeizures: null,
      gfr: null,
    },
    htDiagnosis: {
      diagnosis: null,
      control: null,
      recommendation: null,
      notes: null,
      followup: null,
      doctorAcceptance: null,
      doctorNotes: null,
      doctorId: null,
      systemDecision: null,
    },
    dbDiagnosis: {
      diagnosis: null,
      control: null,
      recommendation: null,
      notes: null,
      followup: null,
      doctorAcceptance: null,
      doctorNotes: null,
      doctorId: null,
      systemDecision: null,
    },
    copdDiagnosis: [],
    tbDiagnosis: [],
    copdHistory: {
      isCopd: null,
      isProgressiveDyspnoea: null,
      isChronicCough: null,
      isChronicSputum: null,
      isChestPain: null,
      isFever: null,
      isWeightLoss: null,
      isOrthopnoea: null,
      isParoxysmalNocturnal: null,
      isHaemoptysis: null,
      isSpirometeryTest: null,
      spirometeryValue: null,
      isSmoking: null,
      isBiomassFuel: null,
      isPollution: null,
      mmrcGrade: null,
      isDifficultInBreathing: null,
      isExacerbation: null,
      isAdmitHospitalWithExacerbation: null,
      severeBreathingDifficulty: null,
      exacerbationSymptoms: [],
      severeExacerbationSymptoms: [],
      drugOptions: [],
    },

    tbHistory: {
      confirmedTBType: [],
      presumptiveTBType: [],
      suspectedTBSymptoms: [],
      suspectedComorbidities: [],
      suspectedOtherTBDrugs: [],
      tbPreviousDrugList: [],
      tbCurrentDrugList: [],
      tbAdditionalDrugList: [],
    },
    osteoArthritisHistory: {
      oaAComorbidList: [],
      oaPharmaList: [],
      oaLifestyleList: [],
      oaNonPharmaList: [],
    },
    orderTest: {
      clinicalPathology: [],
      radiology: [],
      bioChemistry: [],
      stoolAnalysis: [],
      serology: [],
      urineAnalysis: [],
      cardiology: [],
    },
  };
  const [visitData, setVisitData] = useState();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [drugHistory, setDrugHistory] = useState(VISITDATA.drugHistory);
  const [isLoading, setIsLoading] = useState(false);

  //   isLipidDrugs: "",
  //   isAsprin: "",
  //   isHypertensiveDrugs: "",
  //   isInsulin: "",
  //   isDiabeticDrugs: "",
  //   currentAsprin: [],
  //   currentDBDrugs: [],
  //   currentInsulin: [],
  //   currentLipid: [],
  //   currentHTDrugs: [],
  // });

  const [Data, setData] = useState({
    drugList: [],
    antiDiabeticDrugList: [],
    antiHypertensiveDrugList: [],
    insulinDrugList: [],
    aspirinDrugList: [],
    lipidLoweringDrugList: [],
    isHypertension: "", //medical history data
    isDiabetes: "", //medical history data
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDrugType, setSelectedDrugType] = useState(null);

  const [options, setOptions] = useState([]);

  const [isNewDrugDialogOpen, setIsNewDrugDialogOpen] = useState(false);
  const [drugName, setDrugName] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [insulinStrength, setInsulinStrength] = useState("");
  const [medicalHistoryData, setMedicalHistoryData] = useState();

  const [drugOptions, setDrugOptions] = useState({
    selectedDrugName: "",
    className: "",
    frequencyOptions: [],
    strengthOptions: [],
    selectedStrength: null,
    selectedFrequency: null,
    currentDose: 0,
    strength: 0,
    currentDrugs: [],
  });
  const [filteredRecord, setFilteredRecord] = useState();
  const [drugList, setdrugList] = useState([]);
  const [showOtherStrength, setShowOtherStrength] = useState(false);
  // const [isRecordClosed, setIsRecordClosed] = useState(false);

  useEffect(() => {
    const fetchDataAndSetDrugHistory = async () => {
      setIsLoading(true);
      try {
        let data = JSON.stringify({
          userId: UserID,
          id: PATIENT_ID,
        });

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${Config.apiURL}${Config.routes.getPatientVisitsAPI}`,
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "x-caller": encrypted,
          },
          data: data,
        };

        const response = await axios.request(config);
        console.log("Response from ..:", response, response.data.data);

        const sessionVisitID = parseInt(VISIT_ID, 10);

        const filteredRecords = response.data.data.filter((record) => {
          return record.VISIT_ID === sessionVisitID;
        });
        setFilteredRecord(filteredRecords);
        console.log("Filtered Records:", filteredRecords, typeof VISIT_ID);

        // if (response.data.data === null || response.data.data.length === 0 || response.data.data[0].VISIT_DATA === null) {
        //   setVisitData(VISITDATA);
        // }
        // setIsRecordClosed(filteredRecords[0].STATUS === 6 ? true:false);
        if (filteredRecords[0].VISIT_DATA === null) {
          console.log("=in useeffect if=");
          setVisitData(VISITDATA);
        } else {
          let res = await filteredRecords[0].VISIT_DATA;
          console.log("---res----", res);

          let DrugHistoryData = res ? JSON.parse(res).drugHistory : null;
          let MedicalHistoryData = res ? JSON.parse(res).medicalHistory : null;
          setMedicalHistoryData(MedicalHistoryData);
          console.log("data===", MedicalHistoryData);

          let parsedRes = !!res ? JSON.parse(res) : null;
          setVisitData(parsedRes);

          if (DrugHistoryData !== null) {
            const parsedDrugData = JSON.parse(DrugHistoryData);
            const parsedMedicalData = JSON.parse(MedicalHistoryData);
            // console.log(
            //   "parseddrugdata",
            //   parsedDrugData,
            //   "parsedMedicalData",
            //   parsedMedicalData.isDiabetes
            // );

            setDrugHistory({
              ...drugHistory,
              isLipidDrugs: parsedDrugData.isLipidDrugs,
              isAsprin: parsedDrugData.isAsprin,
              isHypertensiveDrugs: parsedDrugData.isHypertensiveDrugs,
              isDiabeticDrugs: parsedDrugData.isDiabeticDrugs,
              isInsulin: parsedDrugData.isInsulin,
              currentHTDrugs: modifyDrugArray(parsedDrugData.currentHTDrugs),
              currentDBDrugs: modifyDrugArray(parsedDrugData.currentDBDrugs),
              currentInsulin: modifyDrugArray(parsedDrugData.currentInsulin),
              currentAsprin: modifyDrugArray(parsedDrugData.currentAsprin),
              currentLipid: modifyDrugArray(parsedDrugData.currentLipid),
            });
            console.log("parsedMD***");
            console.log(parsedMedicalData);
            // setData((prevData) => ({
            //   ...prevData,
            //   isHypertension: parsedMedicalData?.isHypertension || false,
            //   isDiabetes: parsedMedicalData?.isDiabetes || false,
            // }));
            Data.isHypertension = parsedMedicalData?.isHypertension;
            Data.isDiabetes = parsedMedicalData?.isDiabetes;
            console.log("data***");
            console.log(Data);
          }
        }
      } catch (err) {
        console.log(err, "err");
        setIsLoading(false);
      }
      setIsLoading(false);
    };

    fetchDataAndSetDrugHistory();
  }, [VISIT_ID]);

  // Function to modify drug arrays
  const modifyDrugArray = (drugArray) => {
    return drugArray.map((drug) => {
      if (Number.isInteger(drug.doseCeiling)) {
        drug.doseCeiling = `${drug.doseCeiling}.0`;
      } else if (Number.isFinite(drug.doseCeiling)) {
        drug.doseCeiling = `${drug.doseCeiling}`;
      }

      // if (!/\./.test(drug.doseUnit)) {
      //   drug.doseUnit += ".0";
      // }
      if (drug.doseUnit !== null && !/\./.test(drug.doseUnit)) {
        drug.doseUnit += ".0";
      }

      if (Number.isInteger(drug.currentDose)) {
        drug.currentDose = `${drug.currentDose}.0`;
      } else if (Number.isFinite(drug.currentDose)) {
        drug.currentDose = `${drug.currentDose}`;
      }

      return drug;
    });
  };

  const fetchData = async () => {
    const drugList = await getDrugList();

    //console.log("Drug List:", drugList);
    const convertedItems = drugList.map((element) => ({
      id: element.id,
      className: element.className,
      drugName: element.name,
      strengthButtons: [
        (element.doseUnit || 0) / 2 + " " + (element.unit || ""),
        (element.doseUnit || 0) + " " + (element.unit || ""),
        (element.doseUnit || 0) * 1.5 + " " + (element.unit || ""),
        (element.doseUnit || 0) * 2 + " " + (element.unit || ""),
        "other",
      ],
      frequencyButtons: ["Once", "Twice", "Thrice", "Four"],
      specialInstructionsButtons: ["Before food", "After food"],
      recommendedDose: element.recommendedDose,
      doseCeiling: element.doseCeiling,
      unit: element.unit,
      drugProperties: element,
      diseaseType: element.diseaseType,
    }));

    setData((prevData) => ({
      ...prevData,
      // isHypertension: medicalData?.isHypertension || false,
      // isDiabetes: medicalData?.isDiabetes || false,
      drugList: drugList,
      antiDiabeticDrugList: convertedItems.filter(
        (i) => i.diseaseType === "Diabetes" && i.drugName !== "Aspirin"
      ),
      antiHypertensiveDrugList: convertedItems.filter(
        (i) =>
          i.diseaseType === "Hypertension" &&
          i.drugName !== "Aspirin" &&
          i.className !== "Statin"
      ),
      insulinDrugList: convertedItems.filter(
        (i) => i.diseaseType !== "Hypertension" && i.diseaseType !== "Diabetes"
      ),
      aspirinDrugList: convertedItems.filter((i) => i.drugName === "Aspirin"),
      lipidLoweringDrugList: convertedItems.filter(
        (i) => i.className === "Statin"
      ),
    }));
  };

  useEffect(() => {
    getCurrentDose();
  }, [drugOptions.selectedFrequency, drugOptions.selectedStrength, inputValue]);

  const getDrugList = async () => {
    try {
      // const username = "tiatech-tele";
      // const password = "cds$Tia2221";
      // const basicAuth = "Basic " + btoa(`${username}:${password}`);
      const response = await Axios.get(
        `${Config.abhaBaseUrl}getDrugsData/${UserID}`, ///${basicAuth}
        {
          headers: {
            authorization: `Bearer ${token}`,
            "x-caller": await encrypted,
          },
        }
      );
      // console.log("hhhhhhhhhhh", response.data);
      setdrugList(response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching drug data:", error.message);
      throw error;
    }
  };
  useEffect(() => {
    getDrugList();

    fetchData();
  }, []);
  const handleOptionSelection = (option, questionState) => {
    setDrugHistory((prevMedicalHistory) => ({
      ...prevMedicalHistory,
      [questionState]: option,
    }));
  };

  //to set the options based on the click on ADD DRUGS
  const handleDrugTypeChange = (type) => {
    if (
      filteredRecord &&
      filteredRecord.length > 0 &&
      filteredRecord[0].STATUS === 6
    ) {
      return;
    }
    setIsModalOpen(true);
    setSelectedDrugType(type);
    setShowOtherStrength(false);
    setDrugOptions((prevOptions) => ({
      ...prevOptions,
      className: "",
      selectedStrength: null,
    }));
    console.log("type", type);
    // Set options based on selected drug type
    switch (type) {
      case "Diabetic Drugs":
        setOptions(Data.antiDiabeticDrugList);
        break;
      case "Hypertension Drugs":
        setOptions(Data.antiHypertensiveDrugList);
        break;
      case "Insulin Medication":
        setOptions(Data.insulinDrugList);
        break;
      case "Asprin":
        setOptions(Data.aspirinDrugList);
        break;
      case "Lipid Drugs":
        setOptions(Data.lipidLoweringDrugList);
        break;
      default:
        setOptions([]);
        break;
    }
  };

  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };

  const handleClose = () => {
    setShowOtherStrength(false);
    setIsModalOpen(false);
  };

  const DropdownIndicator = (props) => {
    return (
      <svg
        width="40px"
        height="30px"
        viewBox="0 0 24 24"
        cursor="pointer"
        fill="#8873C9"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"
          fill="#8873C9" // Set your desired arrow color here
        />
      </svg>
    );
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#B4B4B4" : provided.borderColor, // Set border color when focused
      boxShadow: state.isFocused ? "0 0 0 1px #B4B4B4" : "none", // Set box shadow when focused
      "&:hover": {
        borderColor: "#B4B4B4", // Maintain border color on hover
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#D6E5FF" // Light blue when selected
        : state.isFocused && "White", // White when not selected or hovered
      color: "black",
      "&:hover": {
        backgroundColor: "#D6E5FF", // Ensure consistent hover behavior
        color: "black",
      },
      boxShadow: "none", // Remove box shadow on focus within options
      boxSizing: "border-box",
      padding: "8px 12px",
      width: "100%",
      margin: "0",
    }),
    menu: (provided) => ({
      ...provided,
      width: "auto",
      minWidth: "100%", // Ensures the full width is considered
    }),
  };

  const handleDrugChange = (selectedOption) => {
    console.log("selectedOption", selectedOption);
    if (selectedOption === null) {
      setDrugOptions({
        className: "",
        frequencyOptions: [],
        strengthOptions: [],
        selectedDrugName: "", // Reset selected drug name
      });
      return;
    }

    const selectedDrugName = selectedOption?.label || "";
    const selectedDrug = options.find(
      (drug) => drug.drugName === selectedDrugName
    );

    if (selectedDrug) {
      const newStrengthOptions = selectedDrug.strengthButtons
        ? selectedDrug.strengthButtons.map((strength) => ({
            value: strength,
            label: strength,
          }))
        : [];

      const newFrequencyOptions = selectedDrug.frequencyButtons
        ? Object.values(selectedDrug.frequencyButtons).map((frequency) => ({
            value: frequency,
            label: frequency,
          }))
        : [];

      setDrugOptions((prevOptions) => ({
        ...prevOptions,
        selectedDrugName: selectedOption?.label,
        className: selectedDrug.className || "",
        frequencyOptions: newFrequencyOptions,
        strengthOptions: newStrengthOptions,
      }));
    } else {
      // Handle the case when selectedDrug is undefined or null
      console.log("Selected drug is not found.");
    }
  };

  const frequencyMapping = {
    Once: 1,
    Twice: 2,
    Thrice: 3,
    Four: 4,
  };

  const handleStrengthOtherValue = (e) => {
    setDrugOptions({
      ...drugOptions,
      selectedStrength: e.target.value,
    });
  };

  const handleStrengthChange = (selectedOption) => {
    console.log("selectedOption", selectedOption);
    if (selectedOption.value === "other") {
      setShowOtherStrength(true);
    } else {
      setShowOtherStrength(false);
    }
    setDrugOptions({
      ...drugOptions,
      selectedStrength: selectedOption ? selectedOption.value : null,
    });
  };

  const handleFrequencyChange = (selectedOption) => {
    console.log("selectedoption", selectedOption);
    setDrugOptions({
      ...drugOptions,
      selectedFrequency: selectedOption ? selectedOption.value : null,
    });
  };

  function getCurrentDose() {
    // console.log(
    //   drugOptions.selectedStrength && drugOptions.selectedFrequency,
    //   "))))))))"
    // );
    if (drugOptions.selectedStrength && drugOptions.selectedFrequency) {
      const strengthMatch = drugOptions.selectedStrength.match(/\d+(\.\d+)?/);

      if (strengthMatch) {
        const strengthValue =
          drugOptions.selectedStrength !== "other"
            ? parseFloat(strengthMatch[0])
            : "";

        console.log("strengthValue", strengthValue);
        const frequencyMultiplier =
          frequencyMapping[drugOptions.selectedFrequency] || 1;
        console.log("frequencyMultiplier", frequencyMultiplier);

        if (!isNaN(strengthValue) && !isNaN(frequencyMultiplier)) {
          let result = strengthValue * frequencyMultiplier;

          // Check if inputValue is a valid number and add it to the result
          const insulinValue = parseFloat(inputValue);
          if (!isNaN(insulinValue)) {
            result += insulinValue;
          }

          console.log("Final result:", result);
          setDrugOptions({ ...drugOptions, currentDose: result });
        } else {
          console.log("Invalid strength or frequency value");
        }
      }
    } else {
      console.log("Either strength or frequency is null");
    }
  }

  function formatAsDouble(value) {
    if (value === "" || isNaN(value)) {
      return 0;
    }
    return value.toFixed(1);
  }

  const addToList = () => {
    //setShowOtherStrength(false);
    // If a drug is selected from the dropdown
    if (drugOptions.selectedDrugName && selectedDrugType) {
      // Check if any drug is already selected from the same class
      const isDbSelected = drugHistory.currentDBDrugs.some(
        (drug) => drug.className === drugOptions.className
      );
      const isHtSelected = drugHistory.currentHTDrugs.some(
        (drug) => drug.className === drugOptions.className
      );
      const isAsprinSelected = drugHistory.currentAsprin.some(
        (drug) => drug.className === drugOptions.className
      );
      const isInsulinSelected = drugHistory.currentInsulin.some(
        (drug) => drug.className === drugOptions.className
      );
      const isLipidSelected = drugHistory.currentLipid.some(
        (drug) => drug.className === drugOptions.className
      );

      // If a drug from the same class is already selected, show a message and return
      if (
        isDbSelected ||
        isHtSelected ||
        isAsprinSelected ||
        isInsulinSelected ||
        isLipidSelected
      ) {
        setShowLoading(false);
        setSnackbarMessage(
          "Only one item per class can be selected. Please remove the previous item if you want to change the drug."
        );
        setShowSnackbar(true);
        return;
      }

      const filteredRecords = Data.drugList.filter(
        (record) =>
          record.className === drugOptions.className &&
          record.name === drugOptions.selectedDrugName
      );

      // console.log(filteredRecords,"filteredRecords");
      if (filteredRecords.length > 0) {
        // console.log(filteredRecords.length,"filteredRecords.length");
        const matchedRecord = filteredRecords[0];

        console.log(
          drugOptions.selectedStrength,
          "drugOptions.selectedStrength"
        );
        const numericalValue = parseFloat(
          drugOptions.selectedStrength?.split(" ")?.[0]
        );
        const currentDose = formatAsDouble(drugOptions.currentDose);
        const doseCeiling = formatAsDouble(matchedRecord.doseCeiling);
        const doseUnit = formatAsDouble(numericalValue);

        // console.log("========", filteredRecords, typeof matchedRecord.doseCeiling)
        const newDrug = {
          id: matchedRecord.id,
          classId: matchedRecord.classId,
          className: matchedRecord.className,
          name: matchedRecord.name,
          diseaseType: matchedRecord.diseaseType,
          currentDose: currentDose,
          recommendedDose: null,
          doseCeiling: doseCeiling,
          doseUnit: doseUnit,
          doseIndex: null,
          drugPriority: matchedRecord.drugPriority,
          drugClassPriority: null,
          currentDrug: matchedRecord.currentDrug,
          phcAllowed: matchedRecord.phcAllowed,
          chcAllowed: matchedRecord.chcAllowed,
          dhAllowed: matchedRecord.dhAllowed,
          heartContra: matchedRecord.heartContra,
          ckdContra: matchedRecord.ckdContra,
          unit: matchedRecord.unit,
        };

        console.log("===newDrug===", newDrug);
        switch (selectedDrugType) {
          case "Diabetic Drugs":
            setDrugHistory((prevState) => ({
              ...prevState,
              currentDBDrugs: [...prevState.currentDBDrugs, newDrug],
            }));
            break;
          case "Lipid Drugs":
            setDrugHistory((prevState) => ({
              ...prevState,
              currentLipid: [...prevState.currentLipid, newDrug],
            }));
            console.log(newDrug);
            break;
          case "Asprin":
            setDrugHistory((prevState) => ({
              ...prevState,
              currentAsprin: [...prevState.currentAsprin, newDrug],
            }));
            break;
          case "Hypertension Drugs":
            setDrugHistory((prevState) => ({
              ...prevState,
              currentHTDrugs: [...prevState.currentHTDrugs, newDrug],
            }));

            break;
          case "Insulin Medication":
            setDrugHistory((prevState) => ({
              ...prevState,
              currentInsulin: [...prevState.currentInsulin, newDrug],
            }));
            break;
          default:
            break;
        }
      } else {
        console.log("No matching records found.");
      }
    }
    // If a drug is manually entered
    else if (drugName && inputValue) {
      console.log("input value", typeof inputValue);
      let currentDose;

      if (inputValue !== null && inputValue !== undefined) {
        currentDose = inputValue + ".0";
      } else {
        console.log("inputValue is null or undefined");
      }

      const newDrug = {
        name: drugName,
        currentDose: currentDose,
        id: 0,
        classId: null,
        className: null,
        diseaseType: null,
        recommendedDose: null,
        doseCeiling: null,
        doseUnit: null,
        doseIndex: null,
        drugPriority: null,
        drugClassPriority: null,
        currentDrug: null,
        phcAllowed: null,
        chcAllowed: null,
        dhAllowed: null,
        heartContra: null,
        ckdContra: null,
        unit: null,
      };

      // Depending on the selectedDrugType, update drugHistory accordingly
      switch (selectedDrugType) {
        case "Diabetic Drugs":
          setDrugHistory((prevState) => ({
            ...prevState,
            currentDBDrugs: [...prevState.currentDBDrugs, newDrug],
          }));
          break;
        case "Lipid Drugs":
          setDrugHistory((prevState) => ({
            ...prevState,
            currentLipid: [...prevState.currentLipid, newDrug],
          }));
          break;
        case "Asprin":
          setDrugHistory((prevState) => ({
            ...prevState,
            currentAsprin: [...prevState.currentAsprin, newDrug],
          }));
          break;
        case "Hypertension Drugs":
          setDrugHistory((prevState) => ({
            ...prevState,
            currentHTDrugs: [...prevState.currentHTDrugs, newDrug],
          }));
          break;
        case "Insulin Medication":
          setDrugHistory((prevState) => ({
            ...prevState,
            currentInsulin: [...prevState.currentInsulin, newDrug],
          }));
          break;
        default:
          break;
      }

      // Reset input fields after adding drug
      setDrugName("");
      setInputValue("");
      setInsulinStrength("");
      handleCloseAddOthers();
    }
    // If neither drug from dropdown nor manual entry, log an error
    else {
      console.log("No drug selected or entered.");
      toast.warning("Please select the drug");
    }

    // Reset drugOptions to its initial state
    setDrugOptions({
      selectedDrugName: null,
      className: null,
      currentDose: "",
    });
  };

  const deleteDrug = (name, index) => {
    const updatedDrugHistory = { ...drugHistory };

    // Determine the drug type based on selectedDrugType
    switch (selectedDrugType) {
      case "Diabetic Drugs":
        updatedDrugHistory.currentDBDrugs.splice(index, 1);
        break;
      case "Lipid Drugs":
        updatedDrugHistory.currentLipid.splice(index, 1);
        break;
      case "Asprin":
        updatedDrugHistory.currentAsprin.splice(index, 1);
        break;
      case "Hypertension Drugs":
        updatedDrugHistory.currentHTDrugs.splice(index, 1);
        break;
      case "Insulin Medication":
        updatedDrugHistory.currentInsulin.splice(index, 1);
        break;
      default:
        break;
    }
    setDrugHistory(updatedDrugHistory);
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, "").slice(0, 5);
    setInputValue(numericValue);
    const insulinValue = parseFloat(numericValue) || 0;
    setDrugOptions({
      ...drugOptions,
      currentDose: insulinValue,
      selectedStrength: `${insulinValue} mg`,
    });
  };

  // const handleChangeInsulinStrength = (e) => {
  //   const inputValue = e.target.value;
  //   const numericValue = inputValue.replace(/\D/g, "").slice(0, 5);
  //   setInputValue(numericValue);
  //   setInsulinStrength(numericValue);
  //   const insulinValue = parseFloat(numericValue) || 0;
  //   setDrugOptions({
  //     ...drugOptions,
  //     currentDose: insulinValue,
  //     selectedStrength: `${insulinValue} mg`,
  //   });
  // };

  const handleChangeInsulinStrength = (e) => {
    const inputValue = e.target.value;

    // Allow only digits and a single decimal point
    const numericValue = inputValue.replace(/[^0-9.]/g, "");

    // Ensure only one decimal point is present
    // const validValue = numericValue.includes(".")
    //     ? numericValue.split(".").slice(0, 2).join(".")
    //     : numericValue;

    // Limit the input to a certain length, e.g., 5 characters, including the decimal point
    // const truncatedValue = validValue.slice(0, 5);
    const truncatedValue = numericValue;
    setInputValue(truncatedValue);
    setInsulinStrength(truncatedValue);

    // Convert to a float for calculations, default to 0 if invalid
    const insulinValue = parseFloat(truncatedValue) || 0;
    setDrugOptions({
      ...drugOptions,
      currentDose: insulinValue,
      selectedStrength: `${insulinValue} mg`,
    });
  };

  const handleNextClick = async () => {
    if (filteredRecord.length > 0 && filteredRecord[0].STATUS === 6) {
      console.log("hello ....1");
      toggleSection2();
    } else {
      console.log("hello ....2");
      const recordSaved = await saveRecord();
      if (recordSaved) {
        toggleSection2();
      } else {
        console.log("record not saved");
      }
    }
  };

  function replacer(key, value) {
    try {
      if (key.trim() === "") return value;

      if (key.trim() === "drugHistory") {
        let modifiedJsonString = value.replace(/"(\d+\.\d+)"/g, "$1"); //(/\\"(\d+\.\d+)\\"/g, "$1");
        console.log("===drug=====", modifiedJsonString);
        return modifiedJsonString;
      }

      if (key.trim() === "examinationHistory") {
        console.log("===111111111110=====", value);
        let modifiedJsonString = value.replace(/"(\d+(\.\d+)?)"/g, "$1");
        console.log("===examiantion=====", modifiedJsonString);
        return modifiedJsonString;
      }

      if (value && value !== null && typeof value === "object") {
        console.log("value inside if", value);
        return value.replace(/"/g, '\\"'); // Replace " with \"  }   return value; }
      }
      return value;
    } catch (err) {
      console.log(
        "======key===",
        key,
        "===========value==",
        typeof value,
        "=======",
        value
      );
      // console.log(err);
      if (Array.isArray(value) && value.length === 0) return value;
      else if (
        key.trim() === "tbHistory" ||
        key.trim() === "osteoArthritisHistory"
      ) {
        let jsonString = JSON.stringify(value).replace(/"/g, '\\"');
        console.log(jsonString, "1#####");
        jsonString = `"${jsonString}"`;
        console.log(jsonString, "2#####");
        return jsonString;
      } else {
        return JSON.stringify(value);
      }
    }
  }

  const saveRecord = async () => {
    try {
      console.log("vist========", visitData);
      if (visitData) {
        visitData.drugHistory = JSON.stringify(drugHistory);
        console.log("=before ===drughistoy=====", drugHistory);

        let VisitData = JSON.stringify(visitData, replacer);
        console.log("Stringified VisitData---", VisitData);

        sessionStorage.setItem("visitData", VisitData);

        const response = await axios.post(
          `${Config.base_url}PATIENT_VISIT_DATA`,
          {
            USER_ID: UserID,
            VISIT_ID: visitData?.visitId?.toString(),
            VISIT_DATA: VisitData,
            STATUS: 2,
            PRESCRIPTION_DATA: null,
            CREATED_BY: visitData?.nurseId?.toString(),
          },
          {
            headers: {
              authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "x-caller": encrypted,
            },
          }
        );

        if (response.status === 201) {
          // setIsLoading(false);
          // await fetchData();
          return true;
        } else {
          console.error("Error: Data not saved");
          return false;
        }
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  };

  // for add others dialogue box

  const handleOpenAddOthers = () => {
    setInputValue("");
    setIsNewDrugDialogOpen(true);
  };

  const handleCloseAddOthers = () => {
    setIsNewDrugDialogOpen(false);
  };

  // console.log("==data==", Data);

  return isLoading ? (
    <div className="spinningLoader">
      <CircularProgress size={48} color="inherit" />
    </div>
  ) : (
    <>
      <div
        style={{
          display: activeSection === "Drug" ? "block" : "none",
          backgroundColor: "white",
          // borderRadius:"1.25rem",
        }}
      >
        <p className="initalAssessmentSubHeading">
          Please provide current drugs used by the patient
        </p>
        <div
          style={{
            padding: "0.7rem 2rem",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: "1rem",
            flexDirection: "column",
          }}
        >
          {Data.isHypertension === 0 || Data.isHypertension === false ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <QuestionsAnswers
                  question="Is patient taking any antihypertensive medicines?"
                  backendData={drugHistory && drugHistory.isHypertensiveDrugs}
                  questionState="isHypertensiveDrugs"
                  setDrugHistory={setDrugHistory}
                  onClickButton={(option, questionState) =>
                    handleOptionSelection(option, questionState)
                  }
                  historyData={drugHistory["isHypertensiveDrugs"]}
                  isRequired={false}
                />
              </div>
              {drugHistory.isHypertensiveDrugs === 0 && (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      // width: "100%",
                      padding: "2rem 0px 0px 0px",
                      // cursor: "pointer",
                    }}
                    // onClick={handleClickOpen}
                  >
                    <button
                      style={{
                        background: "#52447d",
                        color: "white",
                        border: "none",
                        padding: "0.8rem 2rem ",
                        borderRadius: "6px",
                        height: "40px",
                        whiteSpace: "nowrap",
                        cursor: "pointer",
                      }}
                      onClick={() => handleDrugTypeChange("Hypertension Drugs")}
                    >
                      ADD DRUG
                    </button>
                  </div>
                </>
              )}
            </div>
          ) : (
            ""
          )}
          {drugHistory.currentHTDrugs !== null &&
            drugHistory.currentHTDrugs.length > 0 &&
            drugHistory.isHypertensiveDrugs === 0 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "50%",
                  padding: "2px 0px 0px 0px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    background: "#F8F8F8",
                    padding: "10px",
                  }}
                >
                  <p>Name</p>
                  <p>Class</p>
                  <p>Dose</p>
                </div>
                {drugHistory.currentHTDrugs.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      background: index % 2 === 0 ? "#FFF2E6" : "white",
                      padding: "10px",
                      alignItems: "center",
                    }}
                  >
                    <p>{item.name}</p>
                    <p>{item.className}</p>
                    <p>{item.currentDose}</p>
                  </div>
                ))}
              </div>
            )}

          {Data.isDiabetes === 0 || Data.isDiabetes === false ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <QuestionsAnswers
                question="Is the patient taking any antidiabetic medicines?"
                backendData={drugHistory && drugHistory.isDiabeticDrugs}
                questionState="isDiabeticDrugs"
                setDrugHistory={setDrugHistory}
                onClickButton={(option, questionState) =>
                  handleOptionSelection(option, questionState)
                }
                historyData={drugHistory["isDiabeticDrugs"]}
                isRequired={false}
              />

              {drugHistory.isDiabeticDrugs === 0 && (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      // width: "100%",
                      padding: "2rem 0px 0px 0px",
                      // cursor: "pointer",
                    }}
                    // onClick={handleClickOpen}
                  >
                    <button
                      style={{
                        background: "#52447d",
                        color: "white",
                        border: "none",
                        padding: "0.8rem 2rem ",
                        borderRadius: "6px",
                        height: "40px",
                        whiteSpace: "nowrap",
                        cursor: "pointer",
                      }}
                      onClick={() => handleDrugTypeChange("Diabetic Drugs")}
                    >
                      ADD DRUG
                    </button>
                  </div>
                </>
              )}
            </div>
          ) : (
            ""
          )}
          {drugHistory.currentDBDrugs !== null &&
          drugHistory.currentDBDrugs.length > 0 &&
          drugHistory.isDiabeticDrugs === 0 ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "50%",
                  padding: "2px 0px 0px 0px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    background: "#F8F8F8",
                    padding: "10px",
                  }}
                >
                  <p>Name</p>
                  <p>Class</p>
                  <p>Dose</p>
                </div>
                {drugHistory.currentDBDrugs.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      background: index % 2 === 0 ? "#FFF2E6" : "white",
                      padding: "10px",
                    }}
                  >
                    <p>{item.name}</p>
                    <p>{item.className}</p>
                    <p>{item.currentDose}</p>
                  </div>
                ))}
              </div>
            </>
          ) : (
            ""
          )}
          {drugHistory.isDiabeticDrugs === 0 ? (
            <>
              {" "}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                {/* <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                > */}
                <QuestionsAnswers
                  question="Is the patient taking any Insulin?"
                  backendData={drugHistory && drugHistory.isInsulin}
                  questionState="isInsulin"
                  setDrugHistory={setDrugHistory}
                  onClickButton={(option, questionState) =>
                    handleOptionSelection(option, questionState)
                  }
                  historyData={drugHistory["isInsulin"]}
                  isRequired={false}
                />
                {/* <div
                  >{selectedInsulinDrugs && renderInsulinData(selectedInsulinDrugs, false)}
                  </div> */}
                {/* </div> */}
                {drugHistory.isInsulin === 0 && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        // width: "100%",
                        padding: "2rem 0px 0px 0px",
                        // cursor: "pointer",
                      }}
                      // onClick={handleClickOpen}
                    >
                      <button
                        style={{
                          background: "#52447d",
                          color: "white",
                          border: "none",
                          padding: "0.8rem 2rem ",
                          borderRadius: "6px",
                          height: "40px",
                          whiteSpace: "nowrap",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleDrugTypeChange("Insulin Medication")
                        }
                      >
                        ADD DRUG
                      </button>
                    </div>
                  </>
                )}
              </div>
              {drugHistory.currentInsulin !== null &&
              drugHistory.currentInsulin.length > 0 &&
              drugHistory.isInsulin === 0 ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "50%",
                      padding: "2px 0px 0px 0px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        // gap: "2rem",
                        background: "#F8F8F8",
                        padding: "10px",
                      }}
                    >
                      <p>Name</p>
                      <p>Class</p>
                      <p>Dose</p>
                    </div>
                    {drugHistory.currentInsulin.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          background: index % 2 === 0 ? "#FFF2E6" : "white", // Alternating background colors
                          padding: "10px",
                        }}
                      >
                        <p>{item.name}</p>
                        <p>{item.className}</p>
                        <p>
                          {item.currentDose} {item.unit}
                        </p>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}

          {/* LIPIDS QSTION  */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <QuestionsAnswers
                question="Is the patient taking any lipid lowering drugs?"
                backendData={drugHistory && drugHistory.isLipidDrugs}
                questionState="isLipidDrugs"
                setDrugHistory={setDrugHistory}
                onClickButton={(option, questionState) =>
                  handleOptionSelection(option, questionState)
                }
                historyData={drugHistory["isLipidDrugs"]}
                isRequired={false}
              />
              {/* <div
              >{selectedLipidDrugs && renderLipidDrugData(selectedLipidDrugs, false)}
              </div> */}
            </div>
            {drugHistory.isLipidDrugs === 0 && (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    // width: "100%",
                    padding: "2rem 0px 0px 0px",
                    // cursor: "pointer",
                  }}
                  // onClick={handleClickOpen}
                >
                  <button
                    style={{
                      background: "#52447d",
                      color: "white",
                      border: "none",
                      padding: "0.8rem 2rem ",
                      borderRadius: "6px",
                      height: "40px",
                      whiteSpace: "nowrap",
                      cursor: "pointer",
                    }}
                    // onClick={() => handleAddLipidDrug()}
                    // onClick={() => handleDrugTypeChange("Lipid Drugs")}
                    onClick={() => handleDrugTypeChange("Lipid Drugs")}
                  >
                    ADD DRUG
                  </button>
                </div>
              </>
            )}
          </div>

          {drugHistory.currentLipid !== null &&
          drugHistory.currentLipid.length > 0 &&
          drugHistory.isLipidDrugs === 0 ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "50%",
                  padding: "2px 0px 0px 0px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    // gap: "2rem",
                    background: "#F8F8F8",
                    padding: "10px",
                  }}
                >
                  <p>Name</p>
                  <p>Class</p>
                  <p>Dose</p>
                </div>

                {drugHistory.currentLipid.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      background: index % 2 === 0 ? "#FFF2E6" : "white", // Alternating background colors
                      padding: "10px",
                    }}
                  >
                    <p>{item.name}</p>
                    <p>{item.className}</p>
                    <p>
                      {item.currentDose} {item.unit}
                    </p>
                  </div>
                ))}
              </div>
            </>
          ) : (
            ""
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <QuestionsAnswers
              question="Is the patient taking any aspirin?"
              backendData={drugHistory && drugHistory.isAsprin}
              questionState="isAsprin"
              setDrugHistory={setDrugHistory}
              onClickButton={(option, questionState) =>
                handleOptionSelection(option, questionState)
              }
              historyData={drugHistory["isAsprin"]}
              isRequired={false}
            />

            {/* <div
              >{selectedAspirinDrugs && renderAspirinDrugData(selectedAspirinDrugs, false)}
              </div> */}

            {drugHistory.isAsprin === 0 && (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "50%",
                    padding: "2rem 0 0 0",
                    // cursor: "pointer",
                  }}
                >
                  <button
                    style={{
                      background: "#52447d",
                      color: "white",
                      border: "none",
                      padding: "0.8rem 2rem ",
                      borderRadius: "6px",
                      height: "40px",
                      whiteSpace: "nowrap",
                      cursor: "pointer",
                    }}
                    onClick={() => handleDrugTypeChange("Asprin")}
                  >
                    ADD DRUG
                  </button>
                </div>
              </>
            )}
          </div>
          {drugHistory.currentAsprin !== null &&
          drugHistory.currentAsprin.length > 0 &&
          drugHistory.isAsprin === 0 ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  //   gap: "1rem",
                  width: "50%",
                  padding: "2px 0px 0px 0px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    // gap: "2rem",
                    background: "#F8F8F8",
                    padding: "10px",
                  }}
                >
                  <p>Name</p>
                  <p>Class</p>
                  <p>Dose</p>
                </div>

                {drugHistory.currentAsprin.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      background: index % 2 === 0 ? "#FFF2E6" : "white", // Alternating background colors
                      padding: "10px",
                    }}
                  >
                    <p>{item.name}</p>
                    <p>{item.className}</p>
                    <p>
                      {item.currentDose} {item.unit}
                    </p>
                  </div>
                ))}
              </div>
            </>
          ) : (
            ""
          )}
        </div>

        {/* DIALOG BOX */}
        <Dialog open={isModalOpen}>
          <div
            style={{
              height: "620px",
              display: "flex",
              flexDirection: "column",
              borderRadius: "24px",
              gap: "2rem",
            }}
          >
            <DialogTitle className="drugHeader">
              {selectedDrugType || "Select Drug Type"}
              {/* <img src={crossButton} onClick={handleClose} style={{ cursor: "pointer", height: "20px" }} /> */}
            </DialogTitle>

            <DialogContent>
              <div
                className="personalInfoContainer"
                style={{ display: "flex", gap: "1.7rem", flexWrap: "wrap" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Select
                    id="stateDropdown"
                    placeholder="Drug Name"
                    isSearchable
                    isClearable
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                    options={options.map((drug) => ({
                      value: drug.id,
                      label: drug.drugName,
                    }))}
                    onChange={handleDrugChange}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Select
                    id="stateDropdown"
                    options={[
                      {
                        value: drugOptions.id,
                        label: drugOptions.className,
                      },
                    ]}
                    value={
                      drugOptions.className
                        ? {
                            value: drugOptions.id,
                            label: drugOptions.className,
                          }
                        : undefined
                    }
                    placeholder="Drug Class"
                    isSearchable
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                  />
                </div>

                {/* Render input field only for Insulin Medication, otherwise render dropdowns */}
                {selectedDrugType === "Insulin Medication" ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <input
                      type="number"
                      placeholder="Strength (units)"
                      options={drugOptions.doseOptions}
                      onChange={handleChangeInsulinStrength}
                      value={insulinStrength}
                      style={{
                        borderColor: "rgba(82, 68, 125, 1)",
                        borderRadius: "6px",
                        borderWidth: "2px",
                        height: "50px",
                        boxShadow: "none",
                        backgroundColor: "white",
                        border: "1px solid  #8873C9",
                        paddingLeft: "10px",
                      }}
                    />
                    {/* <p>Current Dose: {drugOptions.currentDose}</p> */}
                  </div>
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <Select
                        id="stateDropdown"
                        options={drugOptions.strengthOptions}
                        placeholder="Strength"
                        isSearchable
                        styles={customStyles}
                        components={{ DropdownIndicator }}
                        onChange={handleStrengthChange}
                      />
                    </div>
                    {showOtherStrength ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          borderRadius: "10px",
                        }}
                      >
                        <input
                          placeholder="Other Dosage"
                          className="dht-strength-input-text"
                          type="text"
                          style={{
                            height: "50px",
                            paddingLeft: "10px",
                            border: "1px solid #8873C9",
                            borderRadius: "6px",
                          }}
                          value={
                            drugOptions.selectedStrength === "other"
                              ? undefined
                              : drugOptions.selectedStrength
                          }
                          onChange={handleStrengthOtherValue}
                        />
                      </div>
                    ) : (
                      <></>
                    )}

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        borderRadius: "10px",
                      }}
                    >
                      <Select
                        id="stateDropdown"
                        placeholder="Frequency"
                        isSearchable
                        styles={customStyles}
                        components={{ DropdownIndicator }}
                        options={drugOptions.frequencyOptions}
                        onChange={handleFrequencyChange}
                      />
                    </div>
                  </>
                )}
              </div>

              <div>
                <div
                  style={{
                    padding: "2%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Current Dose: {drugOptions.currentDose}
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: "1rem 0px 0px 0px",
                  }}
                >
                  <button
                    style={{
                      background: "#52447d",
                      color: "white",
                      border: "none",
                      padding: "0.8rem 2rem",
                      borderRadius: "6px",
                      height: "40px",
                      whiteSpace: "nowrap",
                      cursor: "pointer",
                    }}
                    onClick={addToList}
                  >
                    ADD
                  </button>

                  <button
                    style={{
                      background: "#52447d",
                      color: "white",
                      border: "none",
                      padding: "0.8rem 2rem",
                      borderRadius: "6px",
                      height: "40px",
                      whiteSpace: "nowrap",
                      cursor: "pointer",
                    }}
                    onClick={handleOpenAddOthers}
                  >
                    ADD OTHERS
                  </button>
                  <button
                    style={{
                      background: "#52447d",
                      color: "white",
                      border: "none",
                      padding: "0.8rem 2rem",
                      borderRadius: "6px",
                      height: "40px",
                      whiteSpace: "nowrap",
                      cursor: "pointer",
                    }}
                    onClick={handleClose}
                  >
                    CLOSE
                  </button>
                </div>
              </div>

              {/* Render selected drug based on selectedDrugType */}

              <div
                style={{
                  paddingTop: "20px",
                }}
              >
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <thead>
                    <tr
                      style={{
                        background: "#F8F8F8",
                        padding: "10px",
                      }}
                    >
                      <th style={{ padding: "10px", textAlign: "center" }}>
                        Name
                      </th>
                      <th style={{ padding: "10px", textAlign: "center" }}>
                        Class
                      </th>
                      <th style={{ padding: "10px", textAlign: "center" }}>
                        Dose
                      </th>
                      <th style={{ padding: "10px", textAlign: "center" }}>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Rendering Lipid Drugs */}
                    {selectedDrugType === "Lipid Drugs" &&
                      drugHistory.currentLipid !== null &&
                      drugHistory.currentLipid.length > 0 &&
                      drugHistory.isLipidDrugs === 0 &&
                      drugHistory.currentLipid.map((item, index) => (
                        <tr
                          key={index}
                          style={{
                            background: index % 2 === 0 ? "#FFF2E6" : "white",
                          }}
                        >
                          <td style={{ padding: "10px", textAlign: "center" }}>
                            {item.name}
                          </td>
                          <td style={{ padding: "10px", textAlign: "center" }}>
                            {item.className}
                          </td>
                          <td style={{ padding: "10px", textAlign: "center" }}>
                            {item.currentDose}
                          </td>
                          <td
                            style={{ padding: "10px", cursor: "pointer" }}
                            className="deleteicon"
                           
                          >
                            <img src={DeleteIcon} alt="Delete"  onClick={() => deleteDrug("Lipid Drugs", index)}/>
                          </td>
                        </tr>
                      ))}

                    {/* Rendering Hypertension */}
                    {selectedDrugType === "Hypertension Drugs" &&
                      drugHistory.currentHTDrugs !== null &&
                      drugHistory.currentHTDrugs.length > 0 &&
                      drugHistory.isHypertensiveDrugs === 0 &&
                      drugHistory.currentHTDrugs.map((item, index) => (
                        <tr
                          key={index}
                          style={{
                            background: index % 2 === 0 ? "#FFF2E6" : "white",
                          }}
                        >
                          <td style={{ padding: "10px", textAlign: "center" }}>
                            {item.name}
                          </td>
                          <td style={{ padding: "10px", textAlign: "center" }}>
                            {item.className}
                          </td>
                          <td style={{ padding: "10px", textAlign: "center" }}>
                            {item.currentDose}
                          </td>
                          <td
                            style={{ padding: "10px", cursor: "pointer" }}
                            className="deleteicon"
                            
                          >
                            <img src={DeleteIcon} alt="Delete" onClick={() =>
                              deleteDrug("Hypertension Drugs", index)
                            }/>
                          </td>
                        </tr>
                      ))}

                    {/* Rendering Diabetic */}
                    {selectedDrugType === "Diabetic Drugs" &&
                      drugHistory.currentDBDrugs !== null &&
                      drugHistory.currentDBDrugs.length > 0 &&
                      drugHistory.isDiabeticDrugs === 0 &&
                      drugHistory.currentDBDrugs.map((item, index) => (
                        <tr
                          key={index}
                          style={{
                            background: index % 2 === 0 ? "#FFF2E6" : "white",
                          }}
                        >
                          <td style={{ padding: "10px", textAlign: "center" }}>
                            {item.name}
                          </td>
                          <td style={{ padding: "10px", textAlign: "center" }}>
                            {item.className}
                          </td>
                          <td style={{ padding: "10px", textAlign: "center" }}>
                            {item.currentDose}
                          </td>
                          <td
                            style={{ padding: "10px", cursor: "pointer" }}
                            className="deleteicon"
                          >
                            <img src={DeleteIcon} alt="Delete"    onClick={() => deleteDrug("Diabetic Drugs", index)}
                         />
                          </td>
                        </tr>
                      ))}

                    {/* Rendering Insulin */}
                    {selectedDrugType === "Insulin Medication" &&
                      drugHistory.currentInsulin !== null &&
                      drugHistory.currentInsulin.length > 0 &&
                      drugHistory.isInsulin === 0 &&
                      drugHistory.currentInsulin.map((item, index) => (
                        <tr
                          key={index}
                          style={{
                            background: index % 2 === 0 ? "#FFF2E6" : "white",
                          }}
                        >
                          <td style={{ padding: "10px", textAlign: "center" }}>
                            {item.name}
                          </td>
                          <td style={{ padding: "10px", textAlign: "center" }}>
                            {item.className}
                          </td>
                          <td style={{ padding: "10px", textAlign: "center" }}>
                            {item.currentDose}
                          </td>
                          <td
                            style={{ padding: "10px", cursor: "pointer" }}
                            className="deleteicon"
                          >
                            <img
                              src={DeleteIcon}
                              alt="Delete"
                              onClick={() =>
                                deleteDrug("Insulin Medication", index)
                              }
                            />
                          </td>
                        </tr>
                      ))}

                    {/* Rendering Asprin */}
                    {selectedDrugType === "Asprin" &&
                      drugHistory.currentAsprin !== null &&
                      drugHistory.currentAsprin.length > 0 &&
                      drugHistory.isAsprin === 0 &&
                      drugHistory.currentAsprin.map((item, index) => (
                        <tr
                          key={index}
                          style={{
                            background: index % 2 === 0 ? "#FFF2E6" : "white",
                          }}
                        >
                          <td style={{ padding: "10px", textAlign: "center" }}>
                            {item.name}
                          </td>
                          <td style={{ padding: "10px", textAlign: "center" }}>
                            {item.className}
                          </td>
                          <td style={{ padding: "10px", textAlign: "center" }}>
                            {item.currentDose}
                          </td>
                          <td
                            style={{ padding: "10px", cursor: "pointer" }}
                            className="deleteicon"
                       
                          >
                            <img src={DeleteIcon} alt="Delete"      onClick={() => deleteDrug("Asprin", index)}/>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </DialogContent>
          </div>
        </Dialog>
      </div>
      <Dialog open={isNewDrugDialogOpen}>
        <div
          style={{
            backgroundColor: "white",
            // padding: "15px",
            borderRadius: "8px",
            // border: "2px solid black",
            width: "100%",
            height: "50%",
          }}
        >
          <div
            style={{
              backgroundColor: "rgba(214, 229, 255)",
              padding: "1%",
              display: "flex",
              justifyContent: "center",
              color: "white",
            }}
          >
            {" "}
            <h2 style={{ margin: "2% 0%" }}>Add Other Drug</h2>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <input
              type="text"
              style={{
                padding: "10px",
                marginBottom: "20px", // Add space between fields
                border: "none", // Remove default border
                borderBottom: "1px solid black", // Add bottom border
                width: "90%",
                outline: "none", // Remove outline on focus
                paddingTop: "35px",
              }}
              placeholder="Drug Name"
              value={drugName}
              maxBodyLength={20}
              // onChange={handleDrugNameChange} // Update the drugName state variable when input changes
              onChange={(e) => setDrugName(e.target.value)}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <input
              type="text"
              style={{
                padding: "10px",
                marginBottom: "10px", // Add space between fields
                border: "none", // Remove default border
                borderBottom: "1px solid black", // Add bottom border
                width: "90%",
                outline: "none", // Remove outline on focus
              }}
              placeholder="Drug Dose"
              value={inputValue}
              onChange={handleInputChange}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
              marginBottom: "20px",
              marginLeft: "40px",
            }}
          >
            <button
              style={{
                marginRight: "45px",
                border: "none",
                color: "white",
                backgroundColor: "rgb(82, 68, 125)",
                padding: "2% 4%",
                borderRadius: "5px",
              }}
              onClick={addToList}
            >
              Add
            </button>
            <button
              style={{
                marginRight: "45px",
                border: "none",
                color: "white",
                backgroundColor: "rgb(82, 68, 125)",
                padding: "2% 4%",
                borderRadius: "5px",
              }}
              onClick={handleCloseAddOthers}
            >
              Close
            </button>
          </div>
        </div>
      </Dialog>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="warning"
          sx={{ width: "65%", bgcolor: "#52447d", color: "white" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          // justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        <div
          style={{
            display: activeSection === "Drug" ? "Flex" : "none",
            justifyContent: "flex-start",
            padding: "0 3%",
            // width: "100%",
          }}
          // onClick={saveRecord}
        >
          <button
            style={{
              background: "#5d4a87",
              color: "white",
              border: "none",
              padding: "0.8rem 2rem ",
              borderRadius: "28px",
              height: "40px",
            }}
            onClick={toggleSection0}
          >
            Back
          </button>
        </div>
        <div
          style={{
            display: activeSection === "Drug" ? "Flex" : "none",
            justifyContent: "flex-end",
            width: "100%",
            padding: "0 3%",
          }}
          // onClick={saveRecord}
          // onClick={toggleSection2}
        >
          <button
            style={{
              background: "#5d4a87",
              color: "white",
              border: "none",
              padding: "0.8rem 2rem ",
              borderRadius: "28px",
              height: "40px",
            }}
            onClick={handleNextClick}
          >
            Next
          </button>
        </div>
      </div>
      {/* <ToastContainer /> */}
    </>
  );
};

export default DrugHistory;
