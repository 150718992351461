// CustomButton.js
import React from 'react';
import PropTypes from 'prop-types';
import './CustomButton.css'; // Assuming you have some styles for this button

const CustomButton = ({ buttonText, onClick }) => {
    return (
        <button className="Custom-create-button" onClick={onClick}>
            {buttonText}
        </button>
    );
};

CustomButton.propTypes = {
    buttonText: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default CustomButton;

