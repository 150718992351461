// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { auth } from "../firebase/firebase.js";
// import Axios from "axios";
// import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import Config from "../../Config/config.jsx";
// import { publicEncrypt, constants } from "crypto-browserify";
// import { Buffer } from "buffer";

// import Button from "@mui/material/Button";
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogTitle from "@mui/material/DialogTitle";
// import DialogContentText from "@mui/material/DialogContent";

// const RegisterForm = () => {
//   const [displayToast, setDisplayToast] = useState(false)
//   const [users, setUsers] = useState({
//     MOBILE: "",
//     MOBILE_PIN: "",
//     PASSWORD: "",
//   });
//   const [passwordMatch, setPasswordMatch] = useState({
//     value: "",
//     match: false,
//     lengthValid: false,
//     lowercaseValid: false,
//     uppercaseValid: false,
//     numericValid: false,
//     specialCharValid: false,
//   });
//   const [activeSection, setActiveSection] = useState("MobileNumberWithSendOTP");
//   const [otp, setOtp] = useState("");
//   const navigate = useNavigate();

//   const authentication = "Basic " + btoa("mpoweruser:Cl!nA11y123!@#");

//   const generateRecaptcha = () => {
//     window.recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha", {
//       size: "invisible",
//       callback: (response) => {
//         // reCAPTCHA solved, allow signInWithPhoneNumber.
//         // ...
//       },
//     });
//   };

//   const checkMobile = async (doctormobile) => {
//     console.log('mobiledata:', doctormobile);
//     try {
//       const response = await fetch(Config.base_url + 'USERSCOUNT/' + doctormobile, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
//           'authorization': authentication
//         }
//       });
//       if (response.status === 200) {
//         const data = await response.json();
//         const userCount = data.recordset[0].USER_COUNT;
//         console.log('userCount:', userCount);
//         return userCount;
//       } else if (response.status === 404) {
//         return 0;
//       } else {
//         return 0;
//       }
//     } catch (error) {
//       console.error(error);
//       return 0;
//     }
//   };

//   // const handleSendOtp = (event) => {
//   //   event.preventDefault();
//   //   generateRecaptcha();
//   //   const phone = "+91" + users.MOBILE;
//   //   console.log(phone);
//   //   let appVerifier = window.recaptchaVerifier;
//   //   signInWithPhoneNumber(auth, phone, appVerifier)
//   //     .then((confirmationResult) => {
//   //       // SMS sent. Prompt user to type the code from the message, then sign the
//   //       // user in with confirmationResult.confirm(code).
//   //       window.confirmationResult = confirmationResult;
//   //       console.log(confirmationResult);
//   //     })
//   //     .catch((error) => {
//   //       // Error; SMS not sent
//   //       console.log(error);
//   //     });
//   //   setActiveSection("EnterOTPWithVerifyOTP");
//   // };

//   const handleSendOtp = async (event) => {
//     event.preventDefault();
//     generateRecaptcha();
//     const phone = "+91" + users.MOBILE;
//     console.log(phone);
//     let appVerifier = window.recaptchaVerifier;

//     try {
//       const userCount = await checkMobile(users.MOBILE);

//       // console.log("user count in send otp", userCount)

//       if (userCount === 0) {
//         signInWithPhoneNumber(auth, phone, appVerifier)
//           .then((confirmationResult) => {
//             // SMS sent. Prompt user to type the code from the message, then sign the
//             // user in with confirmationResult.confirm(code).
//             window.confirmationResult = confirmationResult;
//             console.log(confirmationResult);
//           })
//           .catch((error) => {
//             // Error; SMS not sent
//             console.log(error);
//           });
//         setActiveSection("EnterOTPWithVerifyOTP");
//       }
//       else {
//         setDisplayToast(true)
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const handleResend = (event) => {
//     event.preventDefault();
//     // generateRecaptcha();
//     const phone = "+91" + users.MOBILE;
//     let appVerifier = window.recaptchaVerifier;
//     signInWithPhoneNumber(auth, phone, appVerifier)
//       .then((confirmationResult) => {
//         // SMS sent. Prompt user to type the code from the message, then sign the
//         // user in with confirmationResult.confirm(code).
//         window.confirmationResult = confirmationResult;
//         console.log(confirmationResult);
//       })
//       .catch((error) => {
//         // Error; SMS not sent
//         console.log(error);
//       });
//   };

//   const verifyOtp = (e) => {
//     e.preventDefault();
//     if (otp.length === 6) {
//       // verify otp
//       let confirmationResult = window.confirmationResult;
//       confirmationResult
//         .confirm(otp)
//         .then((result) => {
//           // User signed in successfully.
//           let user = result.user;
//           console.log(user);
//           setActiveSection("SubmitPassword");
//           // alert("User signed in successfully");
//           // ...
//         })
//         .catch((error) => {
//           console.log(error);
//           toast.error("User couldn't sign in (bad verification code?)");
//         });
//     }
//   };

//   const handleRegister = () => {
//     console.log("hiiii coming");

//     let payload = {
//       MOBILE: users.MOBILE,
//       PASSWORD: users.PASSWORD,
//       MOBILE_PIN: users.MOBILE_PIN,
//       FCM_TOKEN: "",
//     };
//     sessionStorage.setItem("RegMobileNum", payload.MOBILE);

//     console.log(
//       "payload",
//       JSON.stringify(payload),
//       Config.routes.apiAuthentication,
//       Config.base_url + "signup"
//     );

//     Axios.post(Config.base_url + "signup", payload, {
//       headers: {
//         authorization: authentication,
//         "content-type": "application/json",
//       },
//     })
//       .then((response) => {
//         console.log("response", response);

//         if (response.status === 201) {
//           console.log("coming to if");

//           console.log(JSON.stringify(response));
//           sessionStorage.setItem("RegpublicKey", response.data.publicKey);
//           sessionStorage.setItem("Regtoken", response.data.token);
//           sessionStorage.setItem("RegUserId", response.data.data.userId);
//           // sessionStorage.setItem(
//           //   "RegUserId",
//           //   JSON.stringify(response.data.data.userId)
//           // );
//           navigate("/profile?action=register");
//           alert("User Registered Successfully");
//         } else {
//           console.log("coming to else");
//         }
//       })
//       .catch((error) => {
//         console.error(error);
//       });
//   };

//   const handlePasswordChange = (e) => {
//     const newPassword = e.target.value;
//     console.log("newPassword", newPassword);
//     setUsers({
//       ...users,
//       PASSWORD: newPassword,
//     });
//     setPasswordMatch({
//       ...passwordMatch,
//       lengthValid: newPassword.length >= 8,
//       lowercaseValid: /[a-z]/.test(newPassword),
//       uppercaseValid: /[A-Z]/.test(newPassword),
//       numericValid: /\d/.test(newPassword),
//       specialCharValid: /[!@#$%^&*]/.test(newPassword),
//     });
//   };

//   const handleRePasswordChange = (e) => {
//     const newRePassword = e.target.value;
//     console.log("newRePassword", newRePassword);
//     setPasswordMatch({
//       ...passwordMatch,
//       value: newRePassword,
//       match: newRePassword === users.PASSWORD,
//     });
//   };

//   const handleSubmitPassword = (e) => {
//     e.preventDefault();
//     if (users.PASSWORD !== passwordMatch.value) {
//       toast.warning(
//         "Passwords do not match. Please re-enter and ensure they match."
//       );
//       return;
//     }

//     if (
//       !passwordMatch.lengthValid ||
//       !passwordMatch.lowercaseValid ||
//       !passwordMatch.uppercaseValid ||
//       !passwordMatch.numericValid ||
//       !passwordMatch.specialCharValid ||
//       !passwordMatch.match
//     ) {
//       // Passwords do not meet requirements or do not match
//       toast.warning(
//         "Please ensure that passwords meet the requirements and match."
//       );
//       return;
//     }
//     setActiveSection("PinSetup");
//   };

//   const handleClose = () => {
//     setDisplayToast(false);
//     // navigate('/login?activeSection=Loginform')
//     // navigate("/login")
//     // window.location.reload("/login")
//   };

//   const handleOpen = () => {
//     setDisplayToast(true);
//   };

//   return (
//     <div>
//       <form>
//         {/*Mobile Number With Send OTP*/}
//         {activeSection === "MobileNumberWithSendOTP" && (
//           <>
//             <input
//               type="text"
//               id="phone"
//               className="input"
//               value={users.MOBILE}
//               onChange={(e) =>
//                 setUsers((prev) => {
//                   return {
//                     ...prev,
//                     MOBILE: e.target.value,
//                   };
//                 })
//               }
//               placeholder="Enter Mobile Number"
//               required
//             />
//             <div
//               className="send-otp"
//               // onClick={handleRegister}

//               onClick={handleSendOtp}
//               id="sendotp"
//             >
//               <p
//                 style={{
//                   display: "flex",
//                   justifyContent: "center",
//                   alignItems: "center",
//                   height: "100%",
//                 }}
//               >
//                 Send OTP
//               </p>
//             </div>
//           </>
//         )}
//         {/*Enter OTP with verify otp*/}
//         {activeSection === "EnterOTPWithVerifyOTP" && (
//           <>
//             <input
//               type="text"
//               id="otp"
//               className="input"
//               value={otp}
//               onChange={(e) => setOtp(e.target.value)}
//               placeholder="Enter OTP"
//               required
//             />
//             <button className="resend-otp" onClick={handleResend} id="recover">
//               Resend OTP
//             </button>
//             <button className="send-otp" onClick={verifyOtp} id="verify">
//               Verify OTP
//             </button>
//           </>
//         )}
//         {/*Set New Password and Confirm Password*/}
//         {activeSection === "SubmitPassword" && (
//           <>
//             <input
//               type="text"
//               id="password"
//               className="input"
//               value={users.PASSWORD}
//               onChange={handlePasswordChange}
//               placeholder="Password"
//               required
//             />
//             <p
//               style={{
//                 color: passwordMatch.lengthValid ? "#52447D" : "#8D8D8D",
//               }}
//             >
//               At least 8 character
//             </p>
//             <p
//               style={{
//                 color: passwordMatch.lowercaseValid ? "#52447D" : "#8D8D8D",
//               }}
//             >
//               1 Letter
//             </p>
//             <p
//               style={{
//                 color: passwordMatch.uppercaseValid ? "#52447D" : "#8D8D8D",
//               }}
//             >
//               1 Uppercase letter
//             </p>
//             <p
//               style={{
//                 color: passwordMatch.numericValid ? "#52447D" : "#8D8D8D",
//               }}
//             >
//               1 Numeric character
//             </p>
//             <p
//               style={{
//                 color: passwordMatch.specialCharValid ? "#52447D" : "#8D8D8D",
//               }}
//             >
//               1 Special character
//             </p>
//             <input
//               type="text"
//               id="password"
//               className="input"
//               value={passwordMatch.value}
//               onChange={handleRePasswordChange}
//               placeholder="Password"
//               required
//             />
//             <button
//               className="send-otp"
//               type="button"
//               onClick={handleSubmitPassword}
//               id="send"
//             >
//               Submit Password
//             </button>
//           </>
//         )}
//         <ToastContainer position="top-right" />
//         {/*Pin Setup*/}
//         {activeSection === "PinSetup" && (
//           <>
//             <input
//               type="text"
//               className="input"
//               value={users.pin}
//               onChange={(e) => {
//                 setUsers((prev) => {
//                   return {
//                     ...prev,
//                     MOBILE_PIN: e.target.value,
//                   };
//                 });
//               }}
//               placeholder="Pin"
//             />
//             <p style={{ textAlign: "center", color: "#7F7F7F" }}>
//               This is the pin you will use to log in to the app every time
//               instead of password
//             </p>
//             <button className="send-otp" onClick={handleRegister} id="register">
//               SET PIN
//             </button>
//           </>
//         )}
//       </form>
//       <div id="recaptcha"></div>

//       <Dialog open={displayToast} onClose={handleClose}>
//         <DialogTitle>User Already Exists!!</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             User Already exists with this mobile.Please login.
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose} color="primary">
//             Cancel
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   );
// };

// export default RegisterForm;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase/firebase.js";
import Axios from "axios";
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Config from "../../Config/config.jsx";
import { publicEncrypt, constants } from "crypto-browserify";
import { Buffer } from "buffer";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContent";
import { FiEye, FiEyeOff } from "react-icons/fi";

const RegisterForm = () => {
  const [displayToast, setDisplayToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [users, setUsers] = useState({
    MOBILE: "",
    MOBILE_PIN: "",
    PASSWORD: "",
  });
  const [passwordMatch, setPasswordMatch] = useState({
    value: "",
    match: false,
    lengthValid: false,
    lowercaseValid: false,
    uppercaseValid: false,
    numericValid: false,
    specialCharValid: false,
  });
  const passwordStyle = {
    display: 'flex',
    backgroundColor: 'rgb(243, 238, 255)',
    borderRadius: '7px',
    width: '100%',
    border: 'none',
    alignItems: 'center'
  };
  
  const [activeSection, setActiveSection] = useState("MobileNumberWithSendOTP");
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [password, setPassword] = useState("");

  const INTEGER_SIX_DIGITS_REGEX = /^\d{0,6}$/;


  const authentication = "Basic " + btoa("mpoweruser:Cl!nA11y123!@#");

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha", {
      size: "invisible",
      callback: (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // ...
      },
    });
  };

  const checkMobile = async (doctormobile) => {
    //console.log("mobiledata:", doctormobile);
    try {
      const response = await fetch(
        Config.base_url + "USERSCOUNT/" + doctormobile,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            authorization: authentication,
          },
        }
      );
      if (response.status === 200) {
        const data = await response.json();
        const userCount = data.recordset[0].USER_COUNT;
        //console.log("userCount:", userCount);
        return userCount;
      } else if (response.status === 404) {
        return 0;
      } else {
        return 0;
      }
    } catch (error) {
      console.error(error);
      return 0;
    }
  };

  // const handleSendOtp = (event) => {
  //   event.preventDefault();
  //   generateRecaptcha();
  //   const phone = "+91" + users.MOBILE;
  //   console.log(phone);
  //   let appVerifier = window.recaptchaVerifier;
  //   signInWithPhoneNumber(auth, phone, appVerifier)
  //     .then((confirmationResult) => {
  //       // SMS sent. Prompt user to type the code from the message, then sign the
  //       // user in with confirmationResult.confirm(code).
  //       window.confirmationResult = confirmationResult;
  //       console.log(confirmationResult);
  //     })
  //     .catch((error) => {
  //       // Error; SMS not sent
  //       console.log(error);
  //     });
  //   setActiveSection("EnterOTPWithVerifyOTP");
  // };

  const handleSendOtp = async (event) => {
    event.preventDefault();
    generateRecaptcha();
    const phone = "+91" + users.MOBILE;
    //console.log(phone);
    let appVerifier = window.recaptchaVerifier;

    try {
      const userCount = await checkMobile(users.MOBILE);

      // console.log("user count in send otp", userCount)

      if (userCount === 0) {
        signInWithPhoneNumber(auth, phone, appVerifier)
          .then((confirmationResult) => {
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            window.confirmationResult = confirmationResult;
            //console.log(confirmationResult);
          })
          .catch((error) => {
            // Error; SMS not sent
            console.log(error);
          });
        setActiveSection("EnterOTPWithVerifyOTP");
      } else {
        setDisplayToast(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleResend = (event) => {
    event.preventDefault();
    // generateRecaptcha();
    const phone = "+91" + users.MOBILE;
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, phone, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        //console.log(confirmationResult);
      })
      .catch((error) => {
        // Error; SMS not sent
        console.log(error);
      });
  };

  const verifyOtp = (e) => {
    e.preventDefault();
    if (otp.length !== 6) {
      toast.error("Enter a valid OTP");
      return;
    }
    let confirmationResult = window.confirmationResult;
    if (!confirmationResult) {
      toast.error("Enter valid OTP send to your mobile.");
      return;
    }
    confirmationResult
      .confirm(otp)
      .then((result) => {
        // User signed in successfully.
        let user = result.user;
        setActiveSection("SubmitPassword");
      })
      .catch((error) => {
        console.log(error);
        toast.error("User couldn't sign in (bad verification code?)");
      });
  };

  const handleRegister = (e) => {
    e.preventDefault();
    //console.log("hiiii coming");

    let payload = {
      MOBILE: users.MOBILE,
      PASSWORD: users.PASSWORD,
      MOBILE_PIN: users.MOBILE_PIN,
      FCM_TOKEN: "",
    };
    sessionStorage.setItem("RegMobileNum", payload.MOBILE);

    // console.log(
    //   "payload",
    //   JSON.stringify(payload),
    //   Config.routes.apiAuthentication,
    //   Config.base_url + "signup"
    // );

    Axios.post(Config.base_url + "signup", payload, {
      headers: {
        authorization: authentication,
        "content-type": "application/json",
      },
    })
      .then((response) => {
        //console.log("response", response, "status", response.status);

        if (response.status === 201) {
          //console.log("coming to if");
          navigate("/profile");
          //console.log(JSON.stringify(response));
          sessionStorage.setItem("RegpublicKey", response.data.publicKey);
          sessionStorage.setItem("Regtoken", response.data.token);
          sessionStorage.setItem("RegUserId", response.data.data.userId);
          sessionStorage.setItem("action", "register");
          navigate("/profile");
        } else {
          //console.log("coming to else");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    //console.log("newPassword", newPassword);
    setUsers({
      ...users,
      PASSWORD: newPassword,
    });
    setPasswordMatch({
      ...passwordMatch,
      lengthValid: newPassword.length >= 8,
      lowercaseValid: /[a-z]/.test(newPassword),
      uppercaseValid: /[A-Z]/.test(newPassword),
      numericValid: /\d/.test(newPassword),
      specialCharValid: /[!@#$%^&*]/.test(newPassword),
    });
  };

  const handleRePasswordChange = (e) => {
    const newRePassword = e.target.value;
    //console.log("newRePassword", newRePassword);
    setPasswordMatch({
      ...passwordMatch,
      value: newRePassword,
      match: newRePassword === users.PASSWORD,
    });
  };

  const handleSubmitPassword = (e) => {
    e.preventDefault();

    if (users.PASSWORD !== passwordMatch.value) {
      toast.warning(
        "Passwords do not match. Please re-enter and ensure they match."
      );
      return;
    }

    if (
      !passwordMatch.lengthValid ||
      !passwordMatch.lowercaseValid ||
      !passwordMatch.uppercaseValid ||
      !passwordMatch.numericValid ||
      !passwordMatch.specialCharValid ||
      !passwordMatch.match
    ) {
      // Passwords do not meet requirements or do not match
      toast.warning(
        "Please ensure that passwords meet the requirements and match."
      );
      return;
    }
    setActiveSection("PinSetup");
  };

  const handleClose = () => {
    setDisplayToast(false);
    navigate('/login?activeSection=Loginform')
    navigate("/login")
    window.location.reload("/login")
  };

  const handleOpen = () => {
    setDisplayToast(true);
  };

  const handleToggle = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div>
      <form>
        {/*Mobile Number With Send OTP*/}
        {activeSection === "MobileNumberWithSendOTP" && (
          <>
            <input
              type="text"
              id="phone"
              className="input"
              value={users.MOBILE}
              onChange={(e) => {
                const onlyNumbers = e.target.value.replace(/[^0-9]/g, "");
                setUsers((prev) => {
                  return {
                    ...prev,
                    // MOBILE: e.target.value,
                    MOBILE: onlyNumbers,
                  };
                });
              }}
              maxLength={10}
              placeholder="Enter Mobile Number"
              pattern="[0-9]*"
              required
            />
            <span style={{ color: "red" }}>{errorMessage} </span>
            <div
              className="send-otp"
              // onClick={handleRegister}

              onClick={(e) => {
                if (users.MOBILE.length === 10) {
                  handleSendOtp(e);
                } else {
                  setErrorMessage("Please enter a valid mobile number.");
                }
              }}
              id="sendotp"
            >
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                Send OTP
              </p>
            </div>
          </>
        )}
        {/*Enter OTP with verify otp*/}
        {activeSection === "EnterOTPWithVerifyOTP" && (
          <>
            <input
              type="text"
              id="otp"
              className="input"
              value={otp}
              onChange={(e) => {
                if( !INTEGER_SIX_DIGITS_REGEX.test(e.target.value) ){
                  return;
                }
                setOtp(e.target.value);
              }}
              placeholder="Enter OTP"
              required
            />
            <button className="resend-otp" onClick={handleResend} id="recover">
              Resend OTP
            </button>
            <button className="send-otp" onClick={verifyOtp} id="verify">
              Verify OTP
            </button>
          </>
        )}
        {/*Set New Password and Confirm Password*/}
        {activeSection === "SubmitPassword" && (
          <>
            <div style={passwordStyle}>
              <input style={{margin:'0'}}
                type={passwordVisible ? "text" : "password"}
                id="password"
                className="input"
                value={users.PASSWORD}
                onChange={handlePasswordChange}
                placeholder="Password"
                required
              />

              <span style={{padding:"3%"}}
                className="flex justify-around items-center cursor-pointer"
                onClick={handleToggle}
              >
                {passwordVisible ? (
                  <FiEyeOff className="absolute mr-10" size={20} />
                ) : (
                  <FiEye className="absolute mr-10" size={20} />
                )}
              </span>
            </div>
            <p
              style={{
                color: passwordMatch.lengthValid ? "#52447D" : "#8D8D8D",
              }}
            >
              At least 8 character
            </p>
            <p
              style={{
                color: passwordMatch.lowercaseValid ? "#52447D" : "#8D8D8D",
              }}
            >
              1 Letter
            </p>
            <p
              style={{
                color: passwordMatch.uppercaseValid ? "#52447D" : "#8D8D8D",
              }}
            >
              1 Uppercase letter
            </p>
            <p
              style={{
                color: passwordMatch.numericValid ? "#52447D" : "#8D8D8D",
              }}
            >
              1 Numeric character
            </p>
            <p
              style={{
                color: passwordMatch.specialCharValid ? "#52447D" : "#8D8D8D",
              }}
            >
              1 Special character
            </p>
            <div style={passwordStyle}>
            <input style={{margin:'0'}}
                type={passwordVisible ? "text" : "password"}
                id="password"
                className="input"
                value={passwordMatch.value}
                onChange={handleRePasswordChange}
                placeholder="Password"
                required
              />

              <span style={{padding:"3%"}}
                className="flex justify-around items-center cursor-pointer"
                onClick={handleToggle}
              >
                {passwordVisible ? (
                  <FiEyeOff className="absolute mr-10" size={20} />
                ) : (
                  <FiEye className="absolute mr-10" size={20} />
                )}
              </span>
            </div>
            <button
              className="send-otp"
              type="button"
              onClick={handleSubmitPassword}
              id="send"
            >
              Submit Password
            </button>
          </>
        )}
        <ToastContainer position="top-right" />
        {/*Pin Setup*/}
        {activeSection === "PinSetup" && (
          <>
            <input
              type="number"
              className="input"
              value={users.pin}
              onChange={(e) => {
                if( !INTEGER_SIX_DIGITS_REGEX.test(e.target.value) ){
                  return;
                }
                setUsers((prev) => {
                  return {
                    ...prev,
                    MOBILE_PIN: e.target.value,
                  };
                });
              }}
              placeholder="Pin"
            />
            
            <p style={{ textAlign: "center", color: "#7F7F7F" }}>
              This is the pin you will use to log in to the app every time
              instead of password
            </p>
            <button className="send-otp" onClick={handleRegister} id="register">
              SET PIN
            </button>
          </>
        )}
      </form>
      <div id="recaptcha"></div>

      <Dialog open={displayToast} onClose={handleClose}>
        <DialogTitle>User Already Exists!!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            User Already exists with this mobile. Please login.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RegisterForm;
