import React, { useEffect, useState } from "react";
import Axios from "axios";
import { publicEncrypt, constants } from "crypto-browserify";
import { Buffer } from "buffer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import downArrow from "../../images/Group 102.svg";
import Config from "../../Config/config.jsx";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import DegreeImg from "../../images/education-graduation-learning-school-study 3.svg";
import DeleteIcon from "../../images/delete 8.svg";

const RegMedical = ({ toggleSectionTab }) => {
  const [medicalRegistration, setMedicalRegistration] = useState({
    registrationCouncil: "",
    registrationYear: "",
    registrationNumber: "",
  });
  var councilItems = [
    "Andhra Pradesh Medical Council",
    "Arunachal Pradesh Medical Council",
    "Assam Medical Council",
    "Bihar Medical Council",
    "Chhattisgarh Medical Council",
    "Delhi Medical Council",
    "Goa Medical Council",
    "Gujarat Medical Council",
    "Haryana Medical Council",
    "Himachal Pradesh Medical Council",
    "Jammu and Kashmir Medical Council",
    "Jharkhand Medical Council",
    "Karnataka Medical Council",
    "Kerala Medical Council",
    "Madhya Pradesh Medical Council",
    "Maharashtra Medical Council",
    "Manipur Medical Council",
    "Meghalaya Medical Council",
    "Mizoram Medical Council",
    "Nagaland Medical Council",
    "Odisha Medical Council",
    "Punjab Medical Council",
    "Rajasthan Medical Council",
    "Sikkim Medical Council",
    "Tamil Nadu Medical Council",
    "Telangana State Medical Council",
    "Tripura State Medical Council",
    "Uttar Pradesh Medical Council",
    "Uttarakhand Medical Council",
    "West Bengal Medical Council",
    "Others",
  ];

  const formattedCouncilItems = councilItems.map((item) => ({
    label: item,
    value: item,
  }));

  const handleRegistrationNumber = (number) => {
    const selectedNumber = number.target.value.replace(/\D/g, "");
    setMedicalRegistration({
      ...medicalRegistration,
      registrationNumber: selectedNumber,
    });
  };

  const handleCouncilItems = (council) => {
    // const selectedCouncil = counc
    // Update state with the selected council
    setMedicalRegistration({
      ...medicalRegistration,
      registrationCouncil: council,
    });
  };

  const handleCouncilYear = (year) => {
    let selectedYear = year;
    setMedicalRegistration({
      ...medicalRegistration,
      registrationYear: selectedYear,
    });
  };

  const getYears = (startYear) => {
    const currentYear = new Date().getFullYear();
    const yearsTilPresent = [];

    while (startYear <= currentYear) {
      yearsTilPresent.push({
        label: startYear.toString(),
        value: startYear.toString(),
      });
      startYear++;
    }

    return yearsTilPresent;
  };

  const years = getYears(1994);
  const DropdownIndicator = (props) => {
    return (
      <svg
        width="40px"
        height="30px"
        viewBox="0 0 24 24"
        cursor="pointer"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"
          fill="black" // Set your desired arrow color here
        />
      </svg>
    );
  };

  const customStyles = {
    border:"1px soild black",
    control: (provided) => ({
      ...provided,
      borderColor: "rgba(82, 68, 125, 1)", // Set your desired border color here
      borderRadius: "10px",
      borderWidth: "2px", // Set` your desired border thickness here
      // width: "350px", // Set your desired width here, e.g., "200px"
      height: "50px",
      boxShadow: "none",
      "&:hover": {
        borderColor: "black", // option hover color here
        color: "rgba(82, 68, 125, 1)", // option text color for hover
      },
      backgroundColor: "#FFF",
      border: "1px solid #DFDFDF",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      //  color: "black", // Set your desired arrow color here
      color: "transparent",
    }),
    //   indicatorsContainer: (provided) => ({
    //     ...provided,
    //     width: "50px", // Set your desired arrow width here
    //     height: "50px", // Set your desired arrow height here
    //   }),
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "white", // option hover color here
        color: "black", // option text color for hover
      },
      backgroundColor: state.isSelected
        ? "blue" // Set your desired selected option color here
        : "white",
      color: "Black",
    }),
  };

  const handleSubmit = async () => {
    const UpdatdListOfQualifications = JSON.parse(
      sessionStorage.getItem("UpdatdListOfQualifications")
    );
    const RegMobileNum = sessionStorage.getItem("RegMobileNum");
    const UserID = JSON.parse(sessionStorage.getItem("RegUserId"));
    const publicKey = sessionStorage.getItem("RegpublicKey");
    const token = sessionStorage.getItem("Regtoken");
    const padding = constants.RSA_PKCS1_PADDING;
    const data = Buffer.from(UserID.toString(), "utf-8");
    const encrypted = publicEncrypt(
      { key: publicKey, padding: padding },
      data
    ).toString("base64");
    try {
      // console.log(
      //   medicalRegistration.registrationCouncil,
      //   medicalRegistration.registrationYear
      // );
      if (
        medicalRegistration.registrationCouncil === "" ||
        medicalRegistration.registrationYear === ""
        // ||
        // medicalRegistration.registrationYear === ""
      ) {
        toast.warning("Please Fill Mandatory Details");
        return;
      }
      const apiData = {
        EDUCATION: UpdatdListOfQualifications,
        REGISTRATION_NUMBER: medicalRegistration.registrationNumber,
        REGISTRATION_COUNCIL: medicalRegistration.registrationCouncil.value,
        REGISTRATION_YEAR: medicalRegistration.registrationYear.value,
        mode: "add",
        userid: UserID,
        mobile: RegMobileNum,
      };

      // console.log("apiData", apiData);
      Axios.post(Config.apiURL + Config.routes.addEducationAPI, apiData, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
          "x-caller": encrypted,
        },
      })
        .then((response) => {
          //console.log("response", response.data.message);
          toast.success("Data is submitted.");
          toggleSectionTab("Experience");
        })
        .catch(() => {
          return "Not Saved.";
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div
      className="profileRightContainer"
      style={{ display: "flex", gap: "1rem" }}
    >
      <div className="leftContainer" style={{ width: "70%" }}>
        <div className="topContainer">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              alignItems: "center",
            }}
          >
            <label
              className="headerEdu"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "0.75rem",
              }}
            >
              Medical Registration
            </label>
          </div>
        </div>
        <div style={{ background: "#AEAEAE", width: "100%", height: "2px" }}>
          <p style={{ height: "2px" }}></p>
        </div>

        <div className="regm-bottomContainer">
          <>
            <div
              className="personalInfoContainer"
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <div className="headingContainer" style={{ width: "100%" }}>
                <p className="InfoHeading">
                  Please enter your Medical Registration Details
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "60%",
                }}
              >
                <input
                  style={{
                    width: "100%",
                  }}
                  className="otherInput"
                  type="text"
                  value={medicalRegistration.registrationNumber}
                  onChange={handleRegistrationNumber}
                  placeholder="Registration Number"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "60%",
                }}
              >
                <CreatableSelect
                  id="stateDropdown"
                  value={medicalRegistration.registrationCouncil}
                  onChange={handleCouncilItems}
                  options={formattedCouncilItems}
                  placeholder="Registration Council*"
                  isSearchable
                  isClearable
                  styles={customStyles}
                  components={{ DropdownIndicator }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "60%",
                }}
              >
                <CreatableSelect
                  id="stateDropdown"
                  value={medicalRegistration.registrationYear}
                  onChange={handleCouncilYear}
                  options={years}
                  placeholder="Registration Year*"
                  isSearchable
                  // isClearable
                  styles={customStyles}
                  components={{ DropdownIndicator }}
                />
              </div>

              {/* {variables.showOtherInput && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "60%",
                        }}
                      >
                        <input
                          style={{
                            padding: "0.7rem 2rem",
                            display: "block",
                            width: "100%",
                          }}
                          className="otherInput"
                          type="text"
                          value={variables.otherDegree}
                          onChange={handleOtherDegreeInputChange}
                          placeholder="Enter Other Degree"
                        />
                      </div>
                    </>
                  )}

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "60%",
                    }}
                  >
                    <Select
                      id="stateDropdown"
                      value={variables.selectedYear}
                      onChange={handleYearChange}
                      options={years}
                      placeholder="Year of Completion*"
                      isSearchable
                      // isClearable
                      styles={customStyles}
                      components={{ DropdownIndicator }}
                    />
                  </div>

                  {variables.showOtherInput ? (
                    <>
                      <input
                        type="text"
                        value={variables.otherSpecality}
                        onChange={(e) =>
                          setSpeciality({
                            ...speciality,
                            otherSpecality: e.target.value,
                          })
                        }
                        placeholder="Enter Specialty"
                        style={{
                          padding: "0.7rem 2rem",
                          display: "block",
                          width: "60%",
                        }}
                        className="otherInput"
                      />
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "60%",
                        }}
                      >
                        {degree.selectedDegree !== "" &&
                          variables.selectedYear !== "" && (
                            <Select
                              id="specialityDropdown"
                              value={speciality.selectedSpeciality}
                              onChange={handleSpecalityChange}
                              options={variables.filteredCategories.map(
                                (item) => ({
                                  label: item.CATEGORY,
                                  value: item.CATEGORY,
                                })
                              )}
                              placeholder="Choose the Speciality*"
                              isSearchable
                              styles={customStyles}
                              components={{ DropdownIndicator }}
                            />
                          )}
                      </div>
                    </>
                  )}
                  <div
                    style={{
                      background: "#AEAEAE",
                      width: "100%",
                      height: "1px",
                    }}
                  ></div> */}

              {/* {listOfQualification.map((education, index) => (
                  <>
                    <div
                      key={index}
                      style={{ height: "9vh" }}
                      className="educationCard"
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "6px",
                        }}
                      >
                        <div className="logo">
                          <img src={DegreeImg} />
                        </div>
                        <div className="degreeName">
                          <p>
                            {education.speciality?.selectedSpeciality?.value ||
                              education.speciality?.otherSpecality ||
                              ""}
                          </p>
                          <div className="degreeName">
                            <p>
                              {(education.degree?.selectedDegree?.value ||
                                education.degree?.otherDegree ||
                                "") +
                                (education.year?.value
                                  ? ` (${education.year.value})`
                                  : "")}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="deleteicon"
                        onClick={() => removeEducation(index)}
                      >
                        <img src={DeleteIcon} />
                      </div>
                    </div>
                  </>
                ))} */}

              {/* {listOfQualification.every(
                    (education) =>
                      education.speciality &&
                      (education.speciality.selectedSpeciality ||
                        education.speciality.otherSpecality) &&
                      education.degree &&
                      (education.degree.selectedDegree ||
                        education.degree.otherDegree) &&
                      education.year &&
                      education.year.value
                  ) &&
                    listOfQualification.map((education, index) => (
                      <div
                        key={index}
                        style={{ height: "9vh" }}
                        className="educationCard"
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "6px",
                          }}
                        >
                          <div className="logo">
                            <img src={DegreeImg} />
                          </div>
                          <div className="degreeName">
                            <p>
                              {education.speciality?.selectedSpeciality
                                ?.value ||
                                education.speciality?.otherSpecality ||
                                ""}
                            </p>
                            <div className="degreeName">
                              <p>
                                {((education.degree?.selectedDegree?.value ||
                                  education.degree?.otherDegree) &&
                                  (education.year?.value
                                    ? ` (${education.year.value})`
                                    : "")) ||
                                  null}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          className="deleteicon"
                          onClick={() => removeEducation(index)}
                        >
                          <img src={DeleteIcon} />
                        </div>
                      </div>
                    ))} */}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "1rem",
              }}
            >
              {/* <div
                style={{
                  width: "30%",
                  height: "45px",
                  background: "white",
                }}
                className={"editButton2"}
                // onClick={addEducation}
              >
                <p
                  style={{
                    color: "#52447d",
                    fontSize: "14px",
                    fontFamily: "Verdana",
                    fontWeight: "400",
                    lineHeight: "18.38px",
                    letterSpacing: "15%",
                  }}
                >
                  ADD
                </p>
              </div> */}
              <div
                className={"editButton2"}
                style={{ width: "30%", padding: "1%" }}
                onClick={handleSubmit}
              >
                <p style={{ color: "white" }}>Submit</p>
              </div>
            </div>
          </>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default RegMedical;
